define('huyang-common/models/account-role', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        user: _emberData.default.belongsTo('user', { async: true }),
        account: _emberData.default.belongsTo('account', { async: true }),
        role: _emberData.default.belongsTo('role', { async: true }),
        building: _emberData.default.belongsTo('building', { async: true })
    });
});