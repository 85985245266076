define('huyang-common/components/format-date', ['exports', 'huyang-common/templates/components/format-date'], function (exports, _formatDate) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  var FormatDate = Ember.Component.extend({
    layout: _formatDate.default,
    tagName: 'span',
    classNames: 'format-date',

    session: Ember.inject.service(),

    // custom moment format string
    format: null,
    // true: default format includes timestamp
    absolute: false,
    // true: show close dates as "yesterday", etc
    relative: false,
    // true: show full month name as default format or extend relative dates to include last/next week
    extended: false,
    // true: lowercase relative string excluding day/month names
    lowercase: false,

    dateMoment: Ember.computed('date', function () {
      // Parse possible Date objects to moments and set timezone
      var date = this.get('date');

      if (!date) {
        return null;
      }

      if (!date.toDate) {
        date = moment(date);
      }

      var timezone = this.get('session.currentUser.timezone');

      if (timezone) {
        date.tz(timezone);
      }

      return date;
    }),

    formattedDate: Ember.computed('dateMoment', function () {
      var date = this.get('dateMoment');

      if (!date) {
        return '';
      }

      if (this.get('relative')) {
        return this.formatRelative(moment(), date);
      }

      var fmt = this.get('format') ? this.get('format') : this.get('defaultFormat');

      return date.format(fmt);
    }),

    defaultFormat: Ember.computed(function () {
      if (this.get('absolute')) {
        return 'ddd M/D/YYYY h:mm a';
      }

      if (this.get('extended')) {
        return 'ddd, MMM D, YYYY [at] h:mm a';
      }

      return 'ddd M/D/YY';
    }),

    formatRelative: function formatRelative(referenceMoment, mmt) {
      // The formatted string sometimes needs to follow "... filed the request",
      // so let Today, Tomorrow, etc be lowercase-able without affecting rest of string
      var relative = ['Last', 'Yesterday', 'Today', 'Tomorrow', 'Next'];

      if (this.get('lowercase')) {
        relative = relative.map(function (time) {
          return time.toLowerCase();
        });
      }

      var _relative = relative,
          _relative2 = _slicedToArray(_relative, 5),
          last = _relative2[0],
          yesterday = _relative2[1],
          today = _relative2[2],
          tomorrow = _relative2[3],
          next = _relative2[4];

      // Can default .calendar to comparing against now by passing in null instead of
      // a reference moment, but being explicit here makes it much easier to test
      if (this.get('extended')) {
        return mmt.calendar(referenceMoment, {
          lastWeek: '[' + last + '] ddd [at] h:mm a',
          lastDay: '[' + yesterday + ' at] h:mm a',
          sameDay: '[' + today + ' at] h:mm a',
          nextDay: '[' + tomorrow + ' at] h:mm a',
          nextWeek: '[' + next + '] ddd [at] h:mm a',
          sameElse: this.get('defaultFormat')
        });
      }

      return mmt.calendar(referenceMoment, {
        lastWeek: this.get('defaultFormat'),
        lastDay: '[' + yesterday + '], h:mm a',
        sameDay: '[' + today + '], h:mm a',
        nextDay: '[' + tomorrow + '], h:mm a',
        nextWeek: this.get('defaultFormat'),
        sameElse: this.get('defaultFormat')
      });
    },


    diff: Ember.computed('dateMoment', 'diffUnit', function () {
      var date = this.get('dateMoment');

      if (!date || !date.isValid()) {
        return null;
      }

      return Math.round(date.diff(moment(), this.get('diffUnit'), true));
    }),

    diffBucket: Ember.computed('diff', function () {
      var buckets = this.get('sortedBuckets');

      if (buckets.length < 1) {
        return '';
      }

      var diff = this.get('diff');
      var bucket = this.get('sortedBuckets').find(function (b) {
        return diff >= b.from;
      });

      if (!bucket) {
        bucket = buckets[buckets.length - 1];
      }

      return bucket.label;
    }),

    sortedBuckets: function () {
      var buckets = this.get('diffBuckets') || [];

      // Sort by from (descending)
      return buckets.sort(function (a, b) {
        return b.from - a.from;
      });
    }.property('diffBuckets')

  });

  FormatDate.reopenClass({
    positionalParams: ['date']
  });

  exports.default = FormatDate;
});