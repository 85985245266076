define('huyang-common/components/dropdown-button', ['exports', 'huyang-common/templates/components/dropdown-button'], function (exports, _dropdownButton) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        layout: _dropdownButton.default,
        classNames: ['btn-group'],

        setup: function () {
            var _this = this;

            // if id and onHide
            var id = this.get('id');
            if (id && this.get('onHide')) {
                Ember.$('#' + id).on('hide.bs.dropdown', function () {
                    _this.sendAction('onHide');
                });
            }
        }.on('didInsertElement'),

        actions: {
            chooseItem: function chooseItem(item) {
                this.sendAction('chooseItem', item);
            }
        }
    });
});