define('huyang-common/components/select2-select', ['exports', 'huyang-common/templates/components/select2-select'], function (exports, _select2Select) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        layout: _select2Select.default,
        config: {},
        classNames: ['select2-component'],

        setup: function () {
            var _this = this;

            var selectId = '#' + this.get('selectId');
            var $select = Ember.$(selectId);
            this.buildSelect2();
            $select.on('select2:select', function () {
                _this.sendAction('action', $select.val(), 'select');
            });
            $select.on('select2:unselect', function () {
                _this.sendAction('action', $select.val(), 'unselect');
            });
        }.on('didInsertElement'),

        formatSelection: function formatSelection(item) {
            var label = Ember.$(item.element).attr('data-label');
            return label && !item.loading ? label : item.text;
        },

        formatResult: function formatResult(item) {
            // hide already selected items
            if (item.element && item.element.selected) {
                return null;
            }
            return item.loading || !item.element ? item.text : item.element.innerHTML;
        },

        buildSelect2: function () {
            var selectId = '#' + this.get('selectId');
            var config = {
                theme: 'bootstrap',
                templateSelection: this.formatSelection,
                templateResult: this.formatResult,
                escapeMarkup: function escapeMarkup(m) {
                    return m;
                } // don't escape html
            };
            if (this.get('config')) {
                config = _.extend(config, this.get('config'));
            }
            this.set('select2', Ember.$(selectId).select2(config));
            Ember.$(selectId).addClass('select2-select');

            if (this.get('dropdownCssClass')) {
                Ember.$(selectId).data('select2').$dropdown.addClass(this.get('dropdownCssClass'));
            }

            this.sendAction('build');
        }.on('didInsertElement'),

        teardown: function () {
            var select2 = this.get('select2');
            if (select2.data('select2')) {
                select2.select2('destroy');
            }
        }.on('willDestroyElement')
    });
});