define('huyang-common/components/follow-unfollow-button', ['exports', 'huyang-common/templates/components/follow-unfollow-button'], function (exports, _followUnfollowButton) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        layout: _followUnfollowButton.default,
        classNames: ['follow-unfollow-button'],

        session: Ember.inject.service(),

        setup: function () {
            this.updateTooltip();
        }.on('didInsertElement'),

        isFollowing: Ember.computed('request.followers.@each.user', function () {
            var userId = parseInt(this.get('session.data.authenticated.userId'));
            var follower = this.get('request.followers').find(function (f) {
                return parseInt(f.get('user.id')) === userId;
            });
            return !_.isUndefined(follower);
        }),

        followClass: function () {
            return this.get('isFollowing') ? 'unfollow' : 'follow';
        }.property('isFollowing'),

        tooltip: function () {
            return this.get('isFollowing') ? 'Unfollow this request to stop receiving notifications about activity.' : 'Follow this request if you want to receive notifications about activity.';
        }.property('isFollowing'),

        buttonText: function () {
            return this.get('isFollowing') ? 'Unfollow this request' : 'Follow this request';
        }.property('isFollowing'),

        updateTooltip: function () {
            this.$('[data-toggle="tooltip"]').tooltip('destroy');
            this.$('[data-toggle="tooltip"]').tooltip({ title: this.get('tooltip') });
        }.observes('isFollowing'),

        actions: {
            toggleFollow: function toggleFollow() {
                var userId = parseInt(this.get('session.data.authenticated.userId'));
                if (this.get('isFollowing')) {
                    var follower = this.get('request.followers').find(function (f) {
                        return parseInt(f.get('user.id')) === userId;
                    });
                    if (follower) {
                        this.get('request').removeFollower(follower.get('id'));
                    }
                } else {
                    this.get('request').addFollower(userId);
                }
            }
        }
    });
});