define('huyang-common/models/occupant-group', ['exports', 'ember-data', 'huyang-common/mixins/formats-list-strings'], function (exports, _emberData, _formatsListStrings) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend(_formatsListStrings.default, {
        account: _emberData.default.belongsTo('account', { async: true }),
        name: _emberData.default.attr('string'),
        grantAll: _emberData.default.attr('boolean'),
        occupants: _emberData.default.hasMany('tenant-user', { async: false }),
        grants: _emberData.default.hasMany('occupant-group-grant', { async: false }),
        status: _emberData.default.attr('string'),

        isActive: Ember.computed.equal('status', 'active'),
        isArchived: Ember.computed.equal('status', 'archived'),

        hasGrants: function () {
            return this.get('grantAll') || this.get('grants.length') > 0;
        }.property('grantAll', 'grants.length'),

        occupantsString: function () {
            return this.prepareListString(this.get('occupants'), ['user.name', 'user.email']);
        }.property('occupants', 'occupants.[]', 'occupants.length'),

        locationsString: function () {
            if (this.get('grantAll')) {
                return 'All locations';
            }

            return this.prepareListString(this.get('grants'), 'buildingOrSubspaceName');
        }.property('grantAll', 'grants', 'grants.[]', 'grants.length')
    });
});