define('huyang-common/validations/time-log', ['exports', 'huyang-common/validators/time-log', 'ember-changeset-validations/validators/index'], function (exports, _timeLog, _index) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = {
        account: (0, _index.validatePresence)(true),
        request: (0, _index.validatePresence)(true),
        logger: (0, _index.validatePresence)(true),
        worker: (0, _index.validatePresence)(true),
        endTime: _timeLog.default.endTime(),
        minutes: (0, _index.validateNumber)({ allowBlank: true, gte: 0 }),
        hours: [(0, _index.validateNumber)({ allowBlank: true, gte: 0 }), _timeLog.default.hours()]
    };
});