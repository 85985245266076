define('huyang-common/components/select-input', ['exports', 'huyang-common/templates/components/select-input'], function (exports, _selectInput) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        layout: _selectInput.default,

        value: null,

        comparisonValue: function () {
            var value = this.get('value');

            if (this.get('useObject') && value) {
                return value.get(this.get('keyPath'));
            }

            return value;
        }.property('value', 'useObject'),

        actions: {
            chooseItem: function chooseItem() {
                var value = null;

                if (this.$('select').val() !== this.get('prompt')) {
                    var itemId = this.$('select').val();
                    var content = this.get('content');

                    if (this.get('useObject') && Ember.isArray(content)) {
                        value = content.findBy(this.get('keyPath'), itemId);
                    } else {
                        value = itemId;
                    }
                }

                this.set('value', value);
                this.sendAction('selectChanged', this.$('select').attr('name'), value);

                this.$('select').blur();
            }
        },

        data: function () {
            // content can either be a hash or an array. array needs keyPath and labelPath defined to work correctly
            var content = this.get('content');

            var data = [];

            if (Ember.isArray(content)) {
                var key = this.get('keyPath');
                var label = this.get('labelPath');

                content.forEach(function (item) {
                    data.push({
                        id: item.get(key),
                        label: item.get(label)
                    });
                });
            } else {
                for (var k in content) {
                    data.push({
                        id: k,
                        label: content[k]
                    });
                }
            }

            // sorted property indicates if data is PRE-SORTED, otherwise this will sort by default
            return this.get('sorted') ? data : data.sortBy('label');
        }.property('content', 'content.[]', 'keyPath', 'labelPath')
    });
});