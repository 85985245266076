define('huyang-common/adapters/application', ['exports', 'ember-data', 'npm:@sentry/browser'], function (exports, _emberData, _browser) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.RESTAdapter.extend({
        api: Ember.inject.service(),
        session: Ember.inject.service(),

        headers: function () {
            return this.get('api').getHeaders();
        }.property(),

        host: function () {
            return this.get('api').getHost();
        }.property(),

        namespace: Ember.computed(function () {
            var accountId = localStorage.getItem('accountId');

            if (!accountId) {
                _browser.default.captureMessage('Missing localStorage.accountId; trying session: ' + this.get('session.data.authenticated.accountId'));
                accountId = this.get('session.data.authenticated.accountId');
            }
            return accountId ? 'api/' + accountId : 'api';
        }),

        // shouldReloadAll & shouldBackgroundReloadRecord defaults switch in
        // Ember Data 2; this preserves the 1.13 behavior
        //
        shouldReloadAll: function shouldReloadAll() {
            return true;
        },
        shouldBackgroundReloadRecord: function shouldBackgroundReloadRecord() {
            return false;
        }
    });
});