define('huyang-common/models/cost-log', ['exports', 'ember-changeset', 'huyang-common/validations/cost-log', 'ember-data', 'ember-changeset-validations'], function (exports, _emberChangeset, _costLog, _emberData, _emberChangesetValidations) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var CostLog = _emberData.default.Model.extend({
        account: _emberData.default.belongsTo('account', { async: true }),
        request: _emberData.default.belongsTo('request', { async: true }),
        category: _emberData.default.belongsTo('cost-category', { async: true }),
        logger: _emberData.default.belongsTo('user', { async: true }),
        attachments: _emberData.default.hasMany('request-attachment', { async: true }),

        created: _emberData.default.attr('date'),
        description: _emberData.default.attr('string'),
        value: _emberData.default.attr('number'),
        billable: _emberData.default.attr('boolean'),

        changeset: function changeset() {
            var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

            // Need to pass in all three (content, validatorFn, validationMap), even though
            // documentation just shows first two args...
            // See https://github.com/DockYard/ember-changeset/issues/202
            var changeset = new _emberChangeset.default(this, (0, _emberChangesetValidations.default)(_costLog.default), _costLog.default);

            changeset.setProperties(props);

            return changeset;
        }
    });

    CostLog.reopenClass({
        changeset: function changeset() {
            var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
                account = _ref.account,
                request = _ref.request,
                logger = _ref.logger;

            var changeset = new _emberChangeset.default({
                account: account,
                request: request,
                logger: logger,
                created: new Date()
            }, (0, _emberChangesetValidations.default)(_costLog.default), _costLog.default);

            return changeset;
        }
    });

    exports.default = CostLog;
});