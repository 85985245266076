define('huyang-common/components/date-input', ['exports', 'huyang-common/templates/components/date-input'], function (exports, _dateInput) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        layout: _dateInput.default,

        setup: function () {
            var start = void 0;
            if (this.get('fieldValue')) {
                start = this.get('fieldValue');
            } else {
                start = moment();
            }

            this.$('.date-input input.date-picker').daterangepicker({
                singleDatePicker: true,
                showDropdowns: true,
                startDate: start
            });
        }.on('didInsertElement')
    });
});