define('huyang-common/models/building-request-type', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        building: _emberData.default.belongsTo('building', { async: false }),
        label: _emberData.default.attr('string'),
        iconClass: _emberData.default.attr('string'),
        active: _emberData.default.attr('boolean'),

        cssClass: function () {
            return 'huyang-' + this.get('iconClass');
        }.property('iconClass'),

        cssId: function () {
            return 'requestType' + this.get('id');
        }.property('requestType'),

        backgroundStyle: function () {
            return new Ember.String.htmlSafe('background-image:url(' + this.get('iconUrl') + ')');
        }.property('iconUrl')
    });
});