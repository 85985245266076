define('huyang-common/utils/rrule-utils', ['exports', 'huyang-common/utils/date-utils'], function (exports, _dateUtils) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.rruleToObj = rruleToObj;
    exports.rruleToText = rruleToText;

    var _slicedToArray = function () {
        function sliceIterator(arr, i) {
            var _arr = [];
            var _n = true;
            var _d = false;
            var _e = undefined;

            try {
                for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
                    _arr.push(_s.value);

                    if (i && _arr.length === i) break;
                }
            } catch (err) {
                _d = true;
                _e = err;
            } finally {
                try {
                    if (!_n && _i["return"]) _i["return"]();
                } finally {
                    if (_d) throw _e;
                }
            }

            return _arr;
        }

        return function (arr, i) {
            if (Array.isArray(arr)) {
                return arr;
            } else if (Symbol.iterator in Object(arr)) {
                return sliceIterator(arr, i);
            } else {
                throw new TypeError("Invalid attempt to destructure non-iterable instance");
            }
        };
    }();

    var days = { su: 'Sunday', mo: 'Monday', tu: 'Tuesday', we: 'Wednesday', th: 'Thursday', fr: 'Friday', sa: 'Saturday' };

    function rruleToObj(rrule) {
        // Convert repeat rule string to object
        var rule = {};
        rrule.split(';').forEach(function (pair) {
            var _pair$split = pair.split('='),
                _pair$split2 = _slicedToArray(_pair$split, 2),
                key = _pair$split2[0],
                val = _pair$split2[1];

            rule[key.toLowerCase()] = val.toLowerCase();
        });
        return rule;
    }

    function rruleToText(rrule, startDate, timezone) {
        // Convert repeat rule string to expanded text, eg: "Every 2 days starting on..."
        // If startDate is not passed in, return a generic string eg: "Every 2 days"
        //
        // rrules:
        //   - Weekly by days:     WKST=SU;INTERVAL=1;FREQ=WEEKLY;BYDAY=TH,FR
        //   - Monthly by date:    WKST=SU;INTERVAL=1;FREQ=MONTHLY;BYMONTHDAY=1
        //   - Monthly by weekday: WKST=SU;INTERVAL=1;FREQ=MONTHLY;BYSETPOS=1;BYDAY=TH

        if (!rrule) {
            return;
        }

        var rule = rruleToObj(rrule);

        var frequencyLabel = rule.freq === 'daily' ? 'day' : rule.freq.slice(0, -2);
        if (rule.interval > 1) {
            // Keep label as "day" if single, otherwise convert to "2 days"
            frequencyLabel = rule.interval + ' ' + frequencyLabel + 's';
        }

        var detailText = '';

        // monthly by date
        if (rule.freq === 'monthly' && rule.bymonthday) {
            if (Number(rule.bymonthday) < 0) {
                var numToText = ['', 'second to ', 'third to '][-rule.bymonthday - 1];
                detailText = ' on the ' + numToText + 'last day';
            } else {
                detailText = ' on the ' + (0, _dateUtils.toOrdinal)(rule.bymonthday);
            }
        }

        // monthy by nth weekday
        else if (rule.freq === 'monthly' && rule.bysetpos && rule.byday) {
                var day = days[rule.byday];
                if (rule.bysetpos === '-1') {
                    detailText = ' on the last ' + day;
                } else {
                    detailText = ' on the ' + (0, _dateUtils.toOrdinal)(rule.bysetpos) + ' ' + day;
                }
            }

            // weekly by days
            else if (rule.freq === 'weekly' && rule.byday) {
                    // Map "byday" days (two-letters) to full word, eg: "th,fr" => "on Thursday, Friday"
                    var selectedDays = rule.byday.split(',').map(function (abbrev) {
                        return days[abbrev];
                    }).join(', ');
                    detailText = ' on ' + selectedDays;
                }

        var text = 'Every ' + frequencyLabel + detailText;

        if (!startDate) {
            return text;
        }

        var start = moment(startDate);

        if (timezone) {
            start.tz(timezone);
        }

        start = start.format('ddd, MMM D, YYYY [at] h:mm a');

        return text + '. The next request will be created on ' + start;
    }
});