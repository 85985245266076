define('huyang-common/components/timezone-picker', ['exports', 'huyang-common/templates/components/timezone-picker'], function (exports, _timezonePicker) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        layout: _timezonePicker.default,

        /*
            these are the only timezones we offer, for now
            list from http://www.timeanddate.com/time/us/time-zones-background.html and
            http://www.timeanddate.com/time/canada/time-zones-background.html
        */
        timezones: [Ember.Object.create({ id: 'America/St_Johns', name: 'Newfoundland' }), Ember.Object.create({ id: 'America/Halifax', name: 'Atlantic' }), Ember.Object.create({ id: 'America/New_York', name: 'Eastern' }), Ember.Object.create({ id: 'America/Chicago', name: 'Central' }), Ember.Object.create({ id: 'America/Denver', name: 'Mountain' }), Ember.Object.create({ id: 'America/Phoenix', name: 'Arizona' }), Ember.Object.create({ id: 'America/Los_Angeles', name: 'Pacific' }), Ember.Object.create({ id: 'America/Anchorage', name: 'Alaska' }), Ember.Object.create({ id: 'Pacific/Honolulu', name: 'Hawaii' })],

        actions: {
            selectTimezone: function selectTimezone(name, value) {
                this.sendAction('timezoneUpdated', value.selected);
            }
        }
    });
});