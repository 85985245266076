define('huyang-common/components/sortable-table/table-header-column', ['exports', 'huyang-common/templates/components/sortable-table/table-header-column'], function (exports, _tableHeaderColumn) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        layout: _tableHeaderColumn.default,
        tagName: 'th',
        classNameBindings: ['isSorted:sorted', 'sortingDisabled', 'align', 'testClass'],

        sortingDisabled: Ember.computed.alias('column.disableSorting'),
        isSorted: Ember.computed.alias('column.isSorted'),
        align: Ember.computed.alias('column.align'),

        testClass: function () {
            // used in testing
            return 'column-' + this.get('column.key');
        }.property('column.key'),

        headerTitle: function () {
            if (this.get('showMultiSortTooltip') && !this.get('isSorted')) {
                return 'SHIFT + click to sort by multiple';
            }

            return '';
        }.property('isSorted', 'showMultiSortTooltip'),

        didInsertElement: function didInsertElement() {
            this.$('span').tooltip();
        },
        click: function click(e) {
            if (!this.get('sortingDisabled')) {
                this.sendAction('sortBy', this.get('column.key'), e.shiftKey);
            }
        },


        actions: {
            toggleCommentsView: function toggleCommentsView() {
                this.set('commentsExpanded', !this.get('commentsExpanded'));
            }
        }
    });
});