define('huyang-common/components/set-user-info', ['exports', 'ember-validations', 'huyang-common/mixins/unsaved-changes', 'huyang-common/templates/components/set-user-info', 'huyang-common/mixins/validates-email'], function (exports, _emberValidations, _unsavedChanges, _setUserInfo, _validatesEmail) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_emberValidations.default, _unsavedChanges.default, _validatesEmail.default, {
        layout: _setUserInfo.default,
        buttonLabel: 'Update',
        photoUpload: true,

        api: Ember.inject.service(),
        session: Ember.inject.service(),
        intlTelInput: Ember.inject.service(),

        fields: function () {
            var controller = this;
            // validate email exists only on edit
            if (this.get('validateEmailExists')) {
                this.set('emailValidations.email', { currentValue: this.get('email') });
            }

            var f = [{
                name: 'name',
                label: 'Name',
                placeholder: 'John Smith',
                validations: {
                    presence: true,
                    length: { minimum: 2 }
                },
                value: this.get('name')
            }, {
                name: 'email',
                label: 'Email',
                placeholder: 'name@company.com',
                validations: this.get('emailValidations'),
                value: this.get('email')
            }, {
                name: 'mobilePhone',
                label: 'Mobile phone',
                component: 'huyang-form-phone',
                properties: Ember.copy({
                    phone: this.get('mobilePhone')
                }),
                validations: {
                    inline: (0, _emberValidations.validator)(function () {
                        var validation = controller.get('intlTelInput').validate();

                        var errors = {};
                        if (validation.error) {
                            errors.mobilePhone = validation.error;
                            this.errors.pushObject(errors.mobilePhone);
                        }
                    })
                }
            }, {
                name: 'endOfDay',
                label: 'End of day',
                helpText: 'Affects when emails related to the end of your workday are sent',
                value: this.get('endOfDay'),
                time: true,
                step: 5
            }, {
                name: 'timezone',
                label: 'Time zone',
                helpText: 'Select your time zone',
                value: this.get('timezone'),
                timezoneSelect: true
            }];
            if (this.get('photoUpload')) {
                f.push({
                    name: 'photoUuid',
                    label: 'Profile photo',
                    photoUpload: true,
                    photoUrl: this.get('photoUrl'),
                    uploadUrl: this.get('uploadUrl')
                });
            }
            return f;
        }.property('name', 'email', 'mobilePhone', 'uploadUrl', 'photoUrl'),

        uploadUrl: function () {
            return '/api/' + this.get('session.data.authenticated.accountId') + '/users/' + this.get('session.data.authenticated.userId') + '/photo';
        }.property('model.id'),

        showError: function () {
            return this.get('isInvalid') && this.get('clickedSave');
        }.property('isInvalid', 'clickedSave'),

        commitButtonLabel: function () {
            return this.get('user') ? 'Update' : 'Save';
        }.property('user'),

        cleanup: function () {
            var _this = this;

            // don't clear fields if model exists because Ember will think the model has locally
            // changed values that should not be overwritten by server values
            if (this.get('user')) {
                return;
            }
            this.get('fields').forEach(function (f) {
                _this.set(f.name, '');
            });
        }.on('willDestroyElement'),

        actions: {
            save: function save(info, onComplete, onError) {
                // set only if changed
                if (this.get('photoUuid')) {
                    info.photoUuid = this.get('photoUuid');
                }
                this.sendAction('action', info, onComplete, onError);
            },

            fileUploaded: function fileUploaded(uuid) {
                // More info on this in set-building-info.js:L144
                this.set('mobilePhone', this.get('intlTelInput.iti') ? this.get('intlTelInput.iti').getNumber() : '');
                this.sendAction('fileUploaded', uuid);
            },

            removeAttachment: function removeAttachment(filename) {
                console.log('user photo removed ', filename);
                /*
                if (this.photo.id) {
                    Ember.$.ajax({
                        type: 'DELETE',
                        url: this.get('uploadUrl')+'/'+this.photo.id
                    });
                }
                delete this.attachments[filename];
                */
            },

            uploadFailed: function uploadFailed(err) {
                console.log('failed result=', err);
                // TODO: notify
            },

            cancel: function cancel() {
                // TODO: need to do anything else?
                console.log('set user info cancel');
            }
        }
    });
});