define('huyang-common/mixins/formats-channel-name', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Mixin.create({
        displayName: function () {
            if (this.get('channel.isOccupant')) {
                return this.get('request.occupantsLabel');
            }

            return this.get('channel.displayName');
        }.property('request', 'channel.displayName'),

        longDisplayName: function () {
            if (this.get('channel.isOccupant')) {
                return this.get('displayName') + ' & ' + this.get('request.building.name') + ' staff';
            }

            return this.get('displayName');
        }.property('displayName'),

        channelTitle: function () {
            if (this.get('channel.isOccupant')) {
                return this.get('longDisplayName');
            } else if (this.get('channel.isStaff')) {
                return this.get('request.building.name') + ' staff only';
            }

            return this.get('displayName');
        }.property('channel')
    });
});