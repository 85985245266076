define('huyang-common/mixins/flash-banner', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Mixin.create({
        flashBanner: function flashBanner(params, banner) {
            var _this = this;

            this.set('bannerPartial', 'banners/' + (banner || 'new-request'));

            Object.keys(params).forEach(function (key) {
                _this.set(key, params[key]);
            });

            // clear any existing timeouts
            this.clearBannerTimeout();

            // show the banner
            Ember.$('.header-contents').addClass('flash');

            // dismiss the banner in 8s
            this.flashTimeout = setTimeout(function () {
                return _this.dismissBanner();
            }, 8000);
        },

        clearBannerTimeout: function clearBannerTimeout() {
            if (this.flashTimeout) {
                clearTimeout(this.flashTimeout);
            }
        },

        dismissBanner: function dismissBanner() {
            Ember.$('.header-contents').removeClass('flash');
        },

        cheatCode: function () {
            var _this2 = this;

            // a function that you can call from the console to test the banner
            window._flashIt = function () {
                return _this2.flashBanner(1000);
            };
        }.on('init'),

        actions: {
            dismissBanner: function dismissBanner() {
                this.clearBannerTimeout();
                this.dismissBanner();
            }
        }
    });
});