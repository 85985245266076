define('huyang-common/services/intercom', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        addChat: function addChat(account, user) {
            var config = Ember.getOwner(this).resolveRegistration('config:environment') || {};
            var hasIntercomFeature = (account.get('features') || []).indexOf('intercom') >= 0;

            if (config.INTERCOM_APP_ID && user.get('hmac') && hasIntercomFeature) {
                window.intercomSettings = {
                    app_id: config.INTERCOM_APP_ID,
                    user_id: user.get('id'),
                    name: user.get('name'),
                    email: user.get('email'),
                    created_at: user.get('created') ? moment(user.get('created')).unix() : '',
                    user_hash: user.get('hmac'),
                    account_status: account.get('status'),
                    account_name: account.get('name')
                };

                var w = window;
                var ic = w.Intercom;
                if (typeof ic === "function") {
                    ic('reattach_activator');
                    ic('update', w.intercomSettings);
                } else {
                    var d = document;
                    var i = function i() {
                        i.c(arguments);
                    };
                    i.q = [];
                    i.c = function (args) {
                        i.q.push(args);
                    };
                    w.Intercom = i;
                    var l = function l() {
                        var s = d.createElement('script');
                        s.type = 'text/javascript';
                        s.async = true;
                        s.src = 'https://widget.intercom.io/widget/' + window.intercomSettings.app_id;
                        var x = d.getElementsByTagName('script')[0];
                        x.parentNode.insertBefore(s, x);
                    };

                    l();
                }
            }
        }
    });
});