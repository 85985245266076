define('huyang-common/components/building-select', ['exports', 'huyang-common/templates/components/building-select'], function (exports, _buildingSelect) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        layout: _buildingSelect.default,
        buildings: [],
        selectedLocations: [], // [ { building: 1, subspace: 2}, { building: 3 }, ....]
        needsSubspace: false,

        setupFields: function () {
            this.resetSelectedBuildings();
            this.setSelectedLocations();
        }.on('init'),

        selectOptions: {
            width: "100%"
        },

        activeBuildings: Ember.computed.filterBy('buildings', 'active'),

        actions: {
            addLocation: function addLocation() {
                var building = this.get('buildingModel');

                if (!building) {
                    return;
                }

                var subspace = this.get('subspaceModel');

                if (building.get('privateSubspaces.length') > 0 && !subspace) {
                    this.set('needsSubspace', true);
                    return;
                }

                building.set('selected', true);

                if (subspace) {
                    subspace.set('selected', true);
                    this.set('needsSubspace', false);
                }

                this.setLocationList();
                this.sendAction('locationsUpdated', this.get('buildings'));
                this.sendAction('locationAdded', building, subspace);
            },

            removeLocation: function removeLocation(buildingId, subspaceId) {
                var building = this.buildingModelById(buildingId);
                var subspace = void 0;

                if (!building) {
                    return;
                }

                if (subspaceId) {
                    var selectedCount = 0;

                    building.get('privateSubspaces').forEach(function (ss) {
                        if (ss.get('id') === subspaceId) {
                            subspace = ss;
                            ss.set('selected', false);
                        }
                        if (ss.get('selected')) {
                            selectedCount += 1;
                        }
                    });

                    if (selectedCount === 0) {
                        building.set('selected', false);
                    }
                } else {
                    building.set('selected', false);
                }

                this.setLocationList();
                this.sendAction('locationsUpdated', this.get('buildings'));
                this.sendAction('locationRemoved', building, subspace);
            },

            selectUpdated: function selectUpdated() {
                // clear needs subspace error
                this.set('needsSubspace', false);
            }
        },

        setSelectedLocations: function setSelectedLocations() {
            if (!this.get('selectedLocations')) {
                return;
            }

            var byBuilding = {};

            this.get('selectedLocations').map(function (loc) {
                var buildingId = loc.building + '';

                if (!byBuilding[buildingId]) {
                    byBuilding[buildingId] = [];
                }

                if (loc.subspace) {
                    byBuilding[buildingId].push(loc.subspace + '');
                }
            });

            this.get('buildings').forEach(function (building) {
                var buildingId = building.get('id');

                if (_.has(byBuilding, buildingId)) {
                    building.set('selected', true);
                    building.get('activeSubspaces').forEach(function (subspace) {
                        if (_.contains(byBuilding[buildingId], subspace.get('id'))) {
                            subspace.set('selected', true);
                        }
                    });
                }
            });

            this.setLocationList();
        },

        setLocationList: function setLocationList() {
            var s = '';

            this.get('buildings').forEach(function (building) {
                if (building.get('selected')) {
                    s += 'b' + building.get('id');
                }
                building.get('activeSubspaces').forEach(function (subspace) {
                    if (subspace.get('selected')) {
                        s += 's' + subspace.get('id');
                    }
                });
            });

            this.set('locationList', s);
        },

        resetSelectedBuildings: function resetSelectedBuildings() {
            if (this.get('buildings')) {
                this.get('buildings').forEach(function (b) {
                    b.set('selected', false);

                    b.get('activeSubspaces').forEach(function (s) {
                        s.set('selected', false);
                    });
                });
            }
        },

        disableSubspaces: function () {
            var building = this.get('buildingModel');
            return !building || !building.get('privateSubspaces.length');
        }.property('selectedBuilding'),

        buildingModelById: function buildingModelById(buildingId) {
            if (!buildingId) {
                return null;
            }
            return this.get('buildings').find(function (b) {
                return b.get('id') === buildingId;
            });
        },

        buildingModel: function () {
            return this.buildingModelById(this.get('selectedBuilding'));
        }.property('selectedBuilding'),

        subspaceModelById: function subspaceModelById(buildingId, subspaceId) {
            if (!buildingId || !subspaceId) {
                return null;
            }
            var building = this.buildingModelById(buildingId);
            if (!building) {
                return null;
            }
            return building.get('privateSubspaces').find(function (s) {
                return s.get('id') === subspaceId;
            });
        },

        subspaceModel: function () {
            return this.subspaceModelById(this.get('selectedBuilding'), this.get('selectedSubspace'));
        }.property('selectedBuilding', 'selectedSubspace'),

        selectBuilding: function () {
            var building = this.get('buildingModel');
            if (building.get('privateSubspaces.length') === 1) {
                this.set('selectedSubspace', building.get('privateSubspaces.firstObject.id'));
            }
        }.observes('selectedBuilding'),

        hasSelectedBuildings: function () {
            return this.get('buildings').filter(function (b) {
                return b.get('selected');
            }).length > 0;
        }.property('buildings.@each.selected')
    });
});