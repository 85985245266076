define('huyang-common/validators/email', ['exports', 'huyang-common/validators/email-in-use'], function (exports, _emailInUse) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _emailInUse.default;
    }
  });
});