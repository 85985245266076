define('huyang-common/components/multiple-file-upload', ['exports', 'huyang-common/templates/components/multiple-file-upload', 'huyang-common/components/file-upload'], function (exports, _multipleFileUpload, _fileUpload) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _fileUpload.default.extend({
        layout: _multipleFileUpload.default,
        classNames: ['multiple-file-upload'],

        photoUpload: false,
        showDropArea: true,

        willDestroyElement: function willDestroyElement() {
            window.onkeyup = null;
        },


        uploadCallback: function uploadCallback(data) {
            // Set value of hidden filename field and trigger change so field can be validated
            this.set('filename', data.result.meta.filename);
            this.$('.filename input').val(data.result.meta.filename);
            this.$('.filename input').trigger('change');
        },

        actions: {
            manualUpload: function manualUpload() {
                Ember.$('#' + this.get('inputId')).click();
            },
            fileUploaded: function fileUploaded(result) {
                this.sendAction('fileUploaded', result.meta.uuid);
            }
        },
        hasFile: function () {
            return this.get('avatar') || this.get('photo');
        }.property('avatar', 'photo'),

        attachListeners: function () {
            var dragCounter = 0;
            var dropArea = this.$('.dropzone-overlay');
            if (!this.get('fullWindow')) {
                dropArea = this.$('.drag-and-drop');
            }

            window.addEventListener('dragover', function (e) {
                e = e || event;
                e.preventDefault();
            }, false);

            window.addEventListener('drop', function (e) {
                e = e || event;
                e.preventDefault();
            }, false);

            if (this.get('fullWindow')) {
                window.onkeyup = function (e) {
                    var key = e.keyCode ? e.keyCode : e.which;
                    // close on esc
                    if (key === 27) {
                        dropArea.removeClass('draghover');
                        dragCounter = 0;
                    }
                };
            }

            var $dragEventsParent = this.$('.drag-and-drop');
            if (this.get('fullWindow')) {
                $dragEventsParent = this.$(window);
            }

            $dragEventsParent.on({
                dragenter: function dragenter() {
                    dragCounter++;

                    if (dragCounter === 1) {
                        dropArea.addClass('draghover');
                    }
                    Ember.$(window).dropZoneTimeout = setTimeout(function () {
                        Ember.$(window).dropZoneTimeout = null;
                        dropArea.removeClass('draghover');
                        dragCounter = 0;
                    }, 4000);
                },
                dragleave: function dragleave() {
                    dragCounter--;

                    if (dragCounter === 0) {
                        dropArea.removeClass('draghover');
                    }
                },
                drop: function drop(e) {
                    e.preventDefault();

                    dragCounter = 0;
                    dropArea.removeClass('draghover');

                    return false;
                }
            });
        }.on('didInsertElement')
    });
});