define('huyang-common/models/base-request-type', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        label: _emberData.default.attr('string'),
        iconClass: _emberData.default.attr('string'),

        cssClass: function () {
            return 'huyang-' + this.get('iconClass');
        }.property('iconClass')

    });
});