define('huyang-common/components/status-alert', ['exports', 'huyang-common/templates/components/status-alert'], function (exports, _statusAlert) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        layout: _statusAlert.default,

        isSuccess: function () {
            return this.get('flash.type') === 'success';
        }.property('flash.type'),

        isDanger: function () {
            return this.get('flash.type') === 'danger';
        }.property('flash.type'),

        isInfo: function () {
            return this.get('flash.type') === 'info';
        }.property('flash.type'),

        iconClass: function () {
            var t = this.get('flash.type');

            if (t === 'success') {
                return 'fa-check';
            } else if (t === 'danger') {
                return 'fa-times';
            }

            return 'fa-info';
        }.property('flash.type')
    });
});