define('huyang-common/components/channel-picker', ['exports', 'huyang-common/templates/components/channel-picker'], function (exports, _channelPicker) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        layout: _channelPicker.default,
        classNames: ['channel-picker'],

        channelSort: ['displayName'],
        channelsSorted: Ember.computed.sort('channels', 'channelSort'),

        actions: {
            setChannel: function setChannel(channel) {
                this.set('channel', channel);
                this.sendAction('channelSelected', channel);
            }
        }
    });
});