define('huyang-common/components/print-request', ['exports', 'huyang-common/templates/components/print-request'], function (exports, _printRequest) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _printRequest.default,

    areImagesShown: true,
    areInfoFieldsShown: true,
    areStaffCommentsShown: true,
    areVendorCommentsShown: false,
    areOccupantCommentsShown: false,
    areCommentsShown: Ember.computed.or('areStaffCommentsShown', 'areVendorCommentsShown', 'areOccupantCommentsShown'),

    init: function init() {
      this._super.apply(this, arguments);

      if (this.get('tenants')) this.set('areOccupantCommentsShown', true);

      var savedSettings = localStorage.getItem('printSettings');
      this.setProperties(JSON.parse(savedSettings));
    },


    settingsUpdater: Ember.observer('areImagesShown', 'areInfoFieldsShown', 'areStaffCommentsShown', 'areVendorCommentsShown', 'areOccupantCommentsShown', 'isFormShown', function () {
      localStorage.setItem('printSettings', JSON.stringify({
        areImagesShown: this.get('areImagesShown'),
        areInfoFieldsShown: this.get('areInfoFieldsShown'),
        areStaffCommentsShown: this.get('areStaffCommentsShown'),
        areVendorCommentsShown: this.get('areVendorCommentsShown'),
        areOccupantCommentsShown: this.get('areOccupantCommentsShown'),
        isFormShown: this.get('isFormShown')
      }));
    }),

    images: Ember.computed('request.attachments', function () {
      var imageAttachments = this.get('request.attachments').filter(function (att) {
        return att.get('isImage');
      });

      imageAttachments.setEach('isChecked', true);
      return imageAttachments;
    }),

    commentImages: Ember.computed('images', function () {
      return this.get('images').filter(function (att) {
        return att.get('comment.id');
      });
    }),

    staffChannel: Ember.computed('channels', function () {
      return this.get('channels').findBy('name', 'staff');
    }),

    vendorChannels: Ember.computed('channels', function () {
      return this.get('channels').filterBy('name', 'vendor');
    }),

    occupantChannel: Ember.computed('channels', function () {
      return this.get('channels').findBy('name', 'occupant');
    }),

    staffComments: Ember.computed('staffChannel', 'request.comments.@each.channel', function () {
      return {
        name: this.get('staffChannel.displayName'),
        comments: this.get('request.comments').filterBy('channel.id', this.get('staffChannel.id')).reject(function (c) {
          return c.get('checklistItem') || c.get('source') === 'request';
        }).reverse()
      };
    }),

    vendorComments: Ember.computed('vendorChannels', 'request.comments.@each.channel', function () {
      var _this = this;

      if (!this.get('request.comments').filterBy('channel.name', 'vendor').length) return [];

      var allVendorComments = [];

      this.get('vendorChannels').forEach(function (vc) {
        allVendorComments.push({
          name: vc.get('displayName'),
          comments: _this.get('request.comments').filterBy('channel.id', vc.get('id')).reverse()
        });
      });

      return allVendorComments;
    }),

    occupantComments: Ember.computed('occupantChannel', 'request.comments.@each.channel', function () {
      return {
        name: this.get('occupantChannel.displayName'),
        comments: this.get('request.comments').filterBy('channel.id', this.get('occupantChannel.id')).reverse()
      };
    }),

    hideAsset: Ember.computed.not('request.asset.id'),

    actions: {
      removeImage: function removeImage(img) {
        img.set('isChecked', false);
      },
      print: function print() {
        window.print();
      }
    }
  });
});