define('huyang-common/models/tenant-user', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        api: Ember.inject.service(),

        user: _emberData.default.belongsTo('user', { async: false }),
        grantAll: _emberData.default.attr('boolean'),
        occupantGroup: _emberData.default.belongsTo('occupant-group', { async: true }),
        occupantGrants: _emberData.default.hasMany('occupant-user-grant', { async: false }),
        status: _emberData.default.attr('string'), // should be 'active', 'pending', 'inactive', or 'blocked'

        isActive: Ember.computed.equal('status', 'active'),
        isPending: Ember.computed.equal('status', 'pending'),
        isBlocked: Ember.computed.equal('status', 'blocked'),
        isInactive: Ember.computed.equal('status', 'inactive'),
        isInactiveOrBlocked: Ember.computed.or('isInactive', 'isBlocked'),

        accountBuildingRoles: function () {
            var roles = [];
            var accountId = parseInt(localStorage.getItem('accountId'));

            this.get('user.accountRoles').forEach(function (ar) {
                if (parseInt(ar.get('account.id')) !== accountId || !ar.get('building')) {
                    return;
                }

                roles.push(ar);
            });

            return roles;
        }.property('user.accountRoles'),

        resendWelcome: function resendWelcome() {
            return this.get('api').ajax({
                type: 'POST',
                url: '/api/' + localStorage.getItem('accountId') + '/tenantUsers/' + this.get('id') + '/welcome'
            });
        },

        /*
        buildings is list of building objects
        if building has one or more subspaces where selected=true, add grants for building+subspace
        else add grant for whole building
         create grants that user doesn't already have and delete any grants the user has but not sent here
        */
        setGrants: function setGrants(buildings) {
            var _this = this;

            var grants = [];
            buildings.forEach(function (b) {
                var selectedSubspaces = b.get('subspaces').filterBy('selected') || [];
                if (selectedSubspaces.length > 0) {
                    selectedSubspaces.forEach(function (sub) {
                        grants.push({
                            building: b.get('id'),
                            subspace: sub.get('id')
                        });
                    });
                } else {
                    grants.push({
                        building: b.get('id')
                    });
                }
            });
            // ember data doesn't support combining multiple requests, so call set grants API method directly
            var data = JSON.stringify({ grants: grants });
            return new Ember.RSVP.Promise(function (resolve, reject) {
                return _this.get('api').ajax({
                    type: 'PUT',
                    url: '/api/' + localStorage.getItem('accountId') + '/tenantUsers/' + _this.get('id') + '/grants',
                    data: data,
                    dataType: 'json'
                }).then(function (response) {
                    // push records
                    // without this, tests fail with
                    // Assertion Failed: You have turned on testing mode, which disabled the run-loop's autorun.
                    // You will need to wrap any code with asynchronous side-effects in a run
                    Ember.run(function () {
                        response.grants.forEach(function (grantRec) {
                            _this.store.createRecord('occupant-user-grant', grantRec.occupantUserGrant);
                        });
                        if (response.deleted) {
                            response.deleted.forEach(function (id) {
                                var rec = _this.store.peekRecord('occupant-user-grant', id);
                                if (rec) {
                                    rec.deleteRecord();
                                }
                            });
                        }
                    });
                    resolve();
                }, function (xhr, status, error) {
                    console.error('error saving grants', error);
                    reject();
                });
            });
        },

        // remove all grants for this tenant user
        // remove grants if user should have access to all locations or access through a group
        removeAllGrants: function removeAllGrants() {
            var _this2 = this;

            var grants = this.get('occupantGrants');
            return new Ember.RSVP.Promise(function (resolve, reject) {
                return _this2.get('api').ajax({
                    type: 'DELETE',
                    url: '/api/' + localStorage.getItem('accountId') + '/tenantUsers/' + _this2.get('id') + '/grants'
                }).then(function () {
                    // without this, tests fail with
                    // Assertion Failed: You have turned on testing mode, which disabled the run-loop's autorun.
                    // You will need to wrap any code with asynchronous side-effects in a run
                    Ember.run(function () {
                        grants.forEach(function (grant) {
                            grant.deleteRecord(); // already deleted on server; no need to save
                        });
                    });
                    resolve();
                }, function (xhr, status, error) {
                    console.error('error saving grants', error);
                    reject();
                });
            });
        }
    });
});