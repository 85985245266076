define('huyang-common/components/bootstrap-modal', ['exports', 'huyang-common/templates/components/bootstrap-modal'], function (exports, _bootstrapModal) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        layout: _bootstrapModal.default,
        classNames: ['modal', 'fade'],
        pressedSave: false,

        hideFooter: false,
        hideSubmit: false,

        setup: function () {
            var _this = this;

            if (this.get('startOpen')) {
                Ember.$('#' + this.get('id')).modal();
                this.sendAction('open', this.get('id'));
            }

            Ember.$('#' + this.get('id')).on('hidden.bs.modal', function () {
                if (!_this.get('pressedSave')) {
                    _this.send('sendCloseAction');
                }
            });
        }.on('didInsertElement'),

        teardown: function () {
            try {
                Ember.$('#' + this.get('id')).off('hidden.bs.modal');
            } catch (err) {}
        }.on('willDestroyElement'),

        confirmLabel: function () {
            return this.get('confirmButton') || 'Save changes';
        }.property('confirmButton'),

        cancelLabel: function () {
            return this.get('cancelButton') || 'Close';
        }.property('cancelButton'),

        observeSendSave: function () {
            if (this.get('sendSave')) {
                this.sendAction();
                Ember.$('#' + this.get('id')).modal('hide');
            }
        }.observes('sendSave'),

        actions: {
            sendSaveAction: function sendSaveAction() {
                var _this2 = this;

                // need to do this to obey disabled property
                this.set('pressedSave', true);
                this.sendAction();

                // reset the saved property shortly
                Ember.run(function () {
                    setTimeout(function () {
                        if (!_this2.get('isDestroyed')) {
                            _this2.set('pressedSave', false);
                        }
                    }, 1000);
                });
            },
            sendCloseAction: function sendCloseAction() {
                this.sendAction('close');
            }
        }
    });
});