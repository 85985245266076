define('huyang-common/components/pending-attachments-list', ['exports', 'huyang-common/templates/components/pending-attachments-list'], function (exports, _pendingAttachmentsList) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        layout: _pendingAttachmentsList.default,

        actions: {
            removeFile: function removeFile(file) {
                this.get('attachments').removeObject(file);
                this.sendAction('action', file);
            }
        }
    });
});