define('huyang-common/components/downtime-alert', ['exports', 'huyang-common/templates/components/downtime-alert'], function (exports, _downtimeAlert) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['downtime-alert'],
    layout: _downtimeAlert.default,

    didInsertElement: function didInsertElement() {
      var _this = this;

      Ember.$('.downtime-alert i').click(function () {
        _this.set('show', false);
        localStorage.setItem('2022-12-14-downtime-dismissal', true);
      });
    }
  });
});