define('huyang-common/models/occupant-user-grant', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        tenantUser: _emberData.default.belongsTo('tenant-user'),
        user: _emberData.default.belongsTo('user'),
        building: _emberData.default.belongsTo('building'),
        subspace: _emberData.default.belongsTo('subspace')
    });
});