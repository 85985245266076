define('huyang-common/components/repeat-schedule-picker', ['exports', 'huyang-common/templates/components/repeat-schedule-picker', 'huyang-common/mixins/repeats'], function (exports, _repeatSchedulePicker, _repeats) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_repeats.default, {
        layout: _repeatSchedulePicker.default,
        classNames: ['repeat-schedule-picker'],
        programEdit: Ember.computed.and('isProgram', 'edit'),

        startDateFormatted: function () {
            var startDate = this.get('startDate');
            var timezone = this.get('session.currentUser.timezone');
            if (timezone) {
                startDate.tz(timezone);
            }
            return this.get('startDate').format('ddd, MMM D, YYYY [at] h:mm a');
        }.property('startDate')
    });
});