define('huyang-common/models/building', ['exports', 'huyang-common/data/countries', 'ember-data', 'huyang-common/mixins/formats-phone'], function (exports, _countries, _emberData, _formatsPhone) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend(_formatsPhone.default, {
        account: _emberData.default.belongsTo('account', { async: true }),
        name: _emberData.default.attr('string'),
        address1: _emberData.default.attr('string'),
        address2: _emberData.default.attr('string'),
        city: _emberData.default.attr('string'),
        addressState: _emberData.default.attr('string'), // need to change name since `state` is a reserved word in some components
        zipCode: _emberData.default.attr('string'),
        country: _emberData.default.attr('string'),
        emergencyPhone: _emberData.default.attr('string'),
        subspaces: _emberData.default.hasMany('subspace', { async: true }),
        requestTypes: _emberData.default.hasMany('request-type-building', { async: true }),
        backgroundUuid: _emberData.default.attr('string'),
        active: _emberData.default.attr('boolean'),
        created: _emberData.default.attr('date'),

        countryString: Ember.computed('country', function () {
            return (_countries.default[this.get('country')] || _countries.default['US']).name;
        }),

        hasImage: function () {
            return this.get('backgroundUuid');
        }.property('backgroundUuid'),

        image: function () {
            return this.get('backgroundUuid') ? this.get('photoUrl') : this.get('staticMapImage');
        }.property('staticMapImage', 'photoUrl', 'backgroundUuid'),

        squareImage: function () {
            return this.get('backgroundUuid') ? this.get('photoUrl') + '?w=300&h=300&crop=entropy&fit=crop' : this.get('staticMapImage');
        }.property('staticMapImage', 'photoUrl', 'backgroundUuid'),

        smallThumbnail: function () {
            return this.get('backgroundUuid') ? this.get('photoUrl') + '?w=80&h=80&fit=crop&crop=entropy&q=100' : this.get('staticMapImage');
        }.property('staticMapImage', 'photoUrl', 'backgroundUuid'),

        staticMapImage: function () {
            return this.getStaticMapImage(600, 600);
        }.property('addressString'),

        privateSubspaces: function () {
            return this.get('subspaces').filter(function (space) {
                return !space.get('common') && space.get('active');
            }).sortBy('position', 'name');
        }.property('subspaces'),

        activeSubspaces: function () {
            return this.get('subspaces').filter(function (space) {
                return space.get('active');
            }).sortBy('position', 'name');
        }.property('subspaces'),

        hasActiveSubspaces: function () {
            return this.get('activeSubspaces.length') > 0;
        }.property('activeSubspaces.length'),

        hasActiveNonCommonSubspaces: function () {
            return this.get('activeSubspaces').filter(function (sub) {
                return !sub.get('common');
            }).length > 0;
        }.property('activeSubspaces.[]'),

        formattedEmergencyPhone: function () {
            return this.formatPhoneDisplay(this.get('emergencyPhone'));
        }.property('emergencyPhone'),

        emergencyPhoneLink: function () {
            return 'tel:' + this.get('formattedPhone');
        }.property('emergencyPhone'),

        addressString: function () {
            return this.get('address1') + ' ' + this.get('city') + ', ' + this.get('addressState') + ', ' + this.get('zipCode');
        }.property('address1', 'city', 'addressState', 'zipCode'),

        getStaticMapImage: function getStaticMapImage(width, height, zoom) {
            if (!zoom) {
                zoom = 20;
            }

            var addressString = this.get('addressString');
            var config = Ember.getOwner(this).resolveRegistration('config:environment');
            return 'https://maps.googleapis.com/maps/api/staticmap?center=' + encodeURIComponent(addressString) + '&zoom=' + zoom + '&size=' + width + 'x' + height + '&maptype=satellite&key=' + config.GOOGLE_MAPS_KEY;
        },

        getBackgroundImageStyle: function getBackgroundImageStyle(width, height) {
            var url = this.get('photoUrl') + '?w=' + width + '&h=' + height + '&fit=crop&crop=entropy';

            if (!url) {
                url = this.getStaticMapImage(2400, 600, 17);
            }

            if (url) {
                return new Ember.String.htmlSafe('background-image: url(' + url + ')');
            }

            return undefined;
        },

        backgroundImageStyle: function () {
            return this.getBackgroundImageStyle(1440, 360);
        }.property('photoUrl'),

        backgroundImageStyleFull: function () {
            return this.getBackgroundImageStyle(2000, 1000);
        }.property('photoUrl'),

        photoUrl: function () {
            if (this.get('backgroundUuid')) {
                var config = Ember.getOwner(this).resolveRegistration('config:environment');
                var baseUrl = config.IMGIX_BASE_URL || config.S3_BASE_URL;

                return baseUrl + '/' + this.get('account.id') + '/buildings/' + this.get('backgroundUuid');
            }

            return undefined;
        }.property('backgroundUuid'),

        destroyRequestTypes: function destroyRequestTypes() {
            var requestTypes = this.get('requestTypes').toArray();
            var promises = requestTypes.map(function (rt) {
                return rt.destroyRecord();
            });
            return Ember.RSVP.all(promises);
        },

        activeRequestTypes: function () {
            return this.get('requestTypes').filterBy('isActive');
        }.property('requestTypes.@each.isActive')
    });
});