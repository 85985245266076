define('huyang-common/components/tenant-visibility-checkbox', ['exports', 'huyang-common/templates/components/tenant-visibility-checkbox'], function (exports, _tenantVisibilityCheckbox) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        layout: _tenantVisibilityCheckbox.default,
        attributeBindings: ['type', 'value', 'checked', 'disabled'],
        tagName: 'input',
        type: 'checkbox',
        checked: false,
        disabled: false,

        _updateElementValue: function () {
            //this.set('checked', this.$().prop('checked'));
            this.set('checked', this.get('comment.tenantVisible'));
        }.on('didInsertElement'),

        saveChange: function saveChange(comment) {
            Ember.run.later(this, function () {
                if (comment.get('isSaving')) {
                    this.saveChange(comment);
                } else {
                    comment.save().then(function () {
                        comment.set('savingVisibility', false);
                    });
                }
            }, 100);
        },

        change: function change() /*event*/{
            var comment = this.get('comment');
            comment.set('savingVisibility', true);
            comment.set('tenantVisible', !comment.get('tenantVisible'));
            this._updateElementValue();
            this.saveChange(comment);
        }
    });
});