define('huyang-common/models/request-schedule', ['exports', 'ember-data', 'huyang-common/utils/rrule-utils'], function (exports, _emberData, _rruleUtils) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        request: _emberData.default.belongsTo('request', { async: true }),
        originator: _emberData.default.belongsTo('user', { async: true }),
        active: _emberData.default.attr('boolean'),
        start: _emberData.default.attr('date'),
        // iCal RRULE format
        repeatRule: _emberData.default.attr('string'),
        dueAfter: _emberData.default.attr('string'),
        afterClose: _emberData.default.attr('string'),
        nextCreate: _emberData.default.attr('date'),
        previousClose: _emberData.default.attr('boolean'),
        previousRelabel: _emberData.default.attr('boolean'),
        previousLabel: _emberData.default.belongsTo('requestLabel', { async: true }),

        rule: function () {
            var rule = this.get('repeatRule');
            if (!rule) {
                return;
            }

            var options = RRule.parseString(rule);
            options.dtstart = this.get('nextCreate');
            return new RRule(options);
        }.property('repeatRule', 'nextCreate'),

        repeatDescription: function () {
            var afterClose = this.get('afterClose');
            var repeatRule = this.get('repeatRule');

            if (afterClose) {
                if (afterClose.indexOf('1 ') === 0) {
                    afterClose = afterClose.replace(/s$/, '');
                }
                return afterClose + ' after close';
            } else if (repeatRule) {
                return (0, _rruleUtils.rruleToText)(repeatRule);
            }
        }.property('repeatRule', 'afterClose'),

        nextYearDates: function () {
            var rule = this.get('rule');
            if (!rule) {
                return null;
            }
            var now = moment();
            var nextYear = moment().add(1, 'year');

            var all = rule.between(now.toDate(), nextYear.toDate());
            return all;
        }.property('repeatRule')
    });
});