define('huyang-common/utils/time', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.elapsedToTimer = elapsedToTimer;
    exports.timerToElapsed = timerToElapsed;
    exports.timerString = timerString;

    var _slicedToArray = function () {
        function sliceIterator(arr, i) {
            var _arr = [];
            var _n = true;
            var _d = false;
            var _e = undefined;

            try {
                for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
                    _arr.push(_s.value);

                    if (i && _arr.length === i) break;
                }
            } catch (err) {
                _d = true;
                _e = err;
            } finally {
                try {
                    if (!_n && _i["return"]) _i["return"]();
                } finally {
                    if (_d) throw _e;
                }
            }

            return _arr;
        }

        return function (arr, i) {
            if (Array.isArray(arr)) {
                return arr;
            } else if (Symbol.iterator in Object(arr)) {
                return sliceIterator(arr, i);
            } else {
                throw new TypeError("Invalid attempt to destructure non-iterable instance");
            }
        };
    }();

    // elapsed is in seconds
    function elapsedToTimer(elapsed) {
        var hours = Math.floor(elapsed / 60 / 60);
        var minutes = Math.floor(elapsed / 60 % 60);
        var seconds = Math.floor(elapsed % 60);

        return [hours, minutes, seconds];
    }

    function timerToElapsed() {
        var hours = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
        var minutes = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
        var seconds = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;

        return (Number(hours) * 60 + Number(minutes)) * 60 + Number(seconds);
    }

    function timerString(_hours, _minutes, _seconds) {
        var _ref = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {},
            full = _ref.full,
            zeroIfEmpty = _ref.zeroIfEmpty;

        var str = '';
        var span = function span(cls, text, unit) {
            return '<span class="timer-string-' + cls + '">' + text + '<span class="unit">' + unit + '</span></span>';
        };

        var _elapsedToTimer = elapsedToTimer(timerToElapsed(_hours, _minutes, _seconds)),
            _elapsedToTimer2 = _slicedToArray(_elapsedToTimer, 3),
            hours = _elapsedToTimer2[0],
            minutes = _elapsedToTimer2[1],
            seconds = _elapsedToTimer2[2];

        if (full) {
            // Always display full timer, eg "00hr 05m 03s"
            var hoursStr = hours > 9 ? hours : '0' + hours;
            var minutesStr = minutes > 9 ? minutes : '0' + minutes;
            var secondsStr = seconds > 9 ? seconds : '0' + seconds;

            // This looks dumb, but the HTML is formatted better
            str = '\n    ' + span('hours', hoursStr, 'hr') + '\n    ' + span('minutes', minutesStr, 'm') + '\n    ' + span('seconds', secondsStr, 's') + '\n';
        } else {
            // Selectively display units of time, without leading zeroes,
            // and extra space if str has content
            if (hours) {
                str += span('hours', hours, 'hr');
            }
            if (minutes) {
                str += '' + (str ? ' ' : '') + span('minutes', minutes, 'm');
            }
            if (seconds || zeroIfEmpty && !hours && !minutes) {
                str += '' + (str ? ' ' : '') + span('seconds', seconds, 's');
            }
        }

        return '<span class="timer-string">' + str + '</span>';
    }

    exports.default = { elapsedToTimer: elapsedToTimer, timerToElapsed: timerToElapsed, timerString: timerString };
});