define('huyang-common/models/request-label', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        api: Ember.inject.service(),

        account: _emberData.default.belongsTo('account', { async: false }),
        name: _emberData.default.attr('string'),
        color: _emberData.default.attr('string'),
        position: _emberData.default.attr('number'),
        isDefaultOpen: _emberData.default.attr('boolean'),
        isDefaultClosed: _emberData.default.attr('boolean'),
        requestCount: _emberData.default.attr('number')
    });
});