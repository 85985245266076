define('huyang-common/adapters/primary-building', ['exports', 'huyang-common/adapters/application'], function (exports, _application) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _application.default.extend({
        namespace: function () {
            // don't include accountId; server looks it up from subdomain
            return 'api';
        }.property('global')
    });
});