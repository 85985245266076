define('huyang-common/components/sortable-table', ['exports', 'huyang-common/templates/components/sortable-table', 'huyang-common/mixins/sorts-data'], function (exports, _sortableTable, _sortsData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_sortsData.default, {
        layout: _sortableTable.default,
        classNames: ['table', 'table-sortable'],
        tagName: 'table',

        actions: {
            _selectAll: function _selectAll() {
                // We're using sendAction here over a closure action since not all
                // sortable-tables will pass a selectAll action to send to
                // table-header-column. This avoids breaking sortable-table
                // implementations that don't use it.
                if (this.get('selectAll')) {
                    this.sendAction('selectAll');
                }
            },
            rowClicked: function rowClicked(id, routeName) {
                this.sendAction('rowClicked', id, routeName);
            },
            onDelete: function onDelete(item) {
                this.sendAction('onDelete', item);
            },
            onEdit: function onEdit(item) {
                this.sendAction('onEdit', item);
            }
        }
    });
});