define('huyang-common/models/cost-category', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    account: _emberData.default.belongsTo('account', { async: true }),

    label: _emberData.default.attr('string'),
    active: _emberData.default.attr('boolean')
  });
});