define('huyang-common/components/priority-picker', ['exports', 'huyang-common/templates/components/priority-picker', 'huyang-common/mixins/displays-priority'], function (exports, _priorityPicker, _displaysPriority) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_displaysPriority.default, {
        layout: _priorityPicker.default,
        classNames: ['priority-picker'],

        actions: {
            radioChanged: function radioChanged(value) {
                this.sendAction('radioChanged', value);
            }
        },

        isCritical: function () {
            return this.get('selectedPriority') === 1;
        }.property('selectedPriority'),

        priorities: function () {
            var priorityMap = this.get('priorityMap');

            var priorities = Object.keys(priorityMap).map(function (p) {
                var value = priorityMap[p];
                var obj = Ember.Object.create(value);

                obj.set('id', parseInt(p));
                return obj;
            });

            return priorities.reverse();
        }.property('global')
    });
});