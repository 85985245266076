define('huyang-common/models/vendor', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        account: _emberData.default.belongsTo('account', { async: true }),
        primaryUser: _emberData.default.belongsTo('user', { async: true }),
        //primaryTrade: DS.belongsTo('trade', { async: true}),

        serviceRequests: _emberData.default.hasMany('vendorServiceRequests', { async: true }),
        //technicians: DS.hasMany('vendorTechnicians', { async: true }),
        //certificates: DS.hasMany('vendorCois', { async: true }),
        name: _emberData.default.attr('string'),
        address1: _emberData.default.attr('string'),
        address2: _emberData.default.attr('string'),
        city: _emberData.default.attr('string'),
        addressState: _emberData.default.attr('string'),
        zipCode: _emberData.default.attr('string'),
        phone: _emberData.default.attr('string')
    });
});