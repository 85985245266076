define('huyang-common/models/request-type-building', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        requestType: _emberData.default.belongsTo('requestType', { async: true }),
        building: _emberData.default.belongsTo('building', { async: true }),

        isActive: function () {
            return this.get('requestType.active');
        }.property('requestType.active')
    });
});