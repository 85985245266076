define('huyang-common/models/occupant-group-grant', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        group: _emberData.default.belongsTo('occupant-group', { async: true }),
        building: _emberData.default.belongsTo('building', { async: true }),
        subspace: _emberData.default.belongsTo('subspace', { async: true }),

        buildingOrSubspaceName: function () {
            if (this.get('subspace.name')) {
                return this.get('building.name') + ', ' + this.get('subspace.name');
            }

            return this.get('building.name');
        }.property('building', 'subspace')
    });
});