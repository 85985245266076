define('huyang-common/components/people-picker', ['exports', 'huyang-common/templates/components/people-picker'], function (exports, _peoplePicker) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        layout: _peoplePicker.default,
        classNames: ['people-picker-container'],

        deselectAllPeople: function deselectAllPeople() {
            if (this.get('people')) {
                this.get('people').forEach(function (p) {
                    p.set('selected', false);
                });
            }
        },

        peopleFiltered: function () {
            var limit = parseInt(this.get('limit'));
            var people = this.get('people') || [];

            people = people.sortBy(this.get('peopleSortKey') || 'name');

            if (!isNaN(limit)) {
                return people.slice(0, limit);
            }

            return people;
        }.property('people', 'limit', 'people.[]'),

        hasMore: function () {
            return this.get('people.length') > this.get('peopleFiltered.length');
        }.property('peopleFiltered.length', 'people.length'),

        morePeople: function () {
            // these are the people that aren't in the filter
            var filtered = this.get('peopleFiltered');

            return this.get('people').filter(function (person) {
                return !filtered.includes(person);
            });
        }.property('people.[]', 'peopleFiltered.[]'),

        selectedPersonIsHidden: function () {
            var people = this.get('people') || [];
            var selectedPerson = people.findBy('selected', true);

            if (selectedPerson) {
                return this.get('morePeople').includes(selectedPerson);
            }

            return false;
        }.property('people.@each.selected', 'morePeople.[]'),

        selectedPersonId: function () {
            var people = this.get('people') || [];
            var person = people.findBy('selected', true);
            return person ? person.get('id') : undefined;
        }.property('people.@each.selected'),

        actions: {
            selectPerson: function selectPerson(person) {
                var removeSelect = false;

                if (person.get('selected')) {
                    removeSelect = true;
                }

                this.deselectAllPeople();

                if (this.popover && Ember.$(this.popover.element).is(':visible')) {
                    this.send('toggleMorePeople');
                }

                if (!removeSelect) {
                    person.set('selected', true);
                    this.sendAction('personSelected', person);
                } else {
                    this.sendAction('personDeselected', person);
                }
            },
            toggleMorePeople: function toggleMorePeople() {
                if (this.popover) {
                    Ember.$(this.popover.element).toggle();
                } else {
                    this.popover = new Tether({
                        element: '.people-menu-container',
                        target: '.person.more',
                        attachment: 'top center',
                        targetAttachment: 'bottom center',
                        targetOffset: '10px 0'
                    });
                }
            },
            selectChanged: function selectChanged() {
                var id = arguments[1];

                if (id) {
                    var people = this.get('people') || [];
                    var person = people.findBy('id', id);

                    if (person) {
                        this.send('selectPerson', person);
                    }
                }
            }
        }
    });
});