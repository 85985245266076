define('huyang-common/utils/date-utils', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.calculateDaysInMonth = calculateDaysInMonth;
    exports.toIntervalObject = toIntervalObject;
    exports.toOrdinal = toOrdinal;
    exports.humanizeDuration = humanizeDuration;
    function calculateDaysInMonth(date) {
        var month = moment(date).month() + 1,
            year = moment(date).year(),
            thisMonthStart = moment(year + '-' + (month < 10 ? '0' : '') + month + '-01'),
            // Manually pre-pend single-digit months with 0
        nextMonthStart = moment(year + '-' + (month < 9 ? '0' : '') + (month + 1) + '-01'); // because phantomjs can't handle things

        return Math.floor(nextMonthStart.diff(thisMonthStart, 'days'));
    }

    function toIntervalObject(startDay, endDay) {
        // Converts difference between two days into a relative time object,
        // e.g. {interval: 5, unit: "days"}, {2, 'week'}, {1, 'months'}, etc
        var start = moment(startDay),
            end = moment(endDay),
            days = Math.floor(end.diff(start, 'days')),
            obj = Object.create(null);

        if (days % 7 === 0) {
            obj.unit = 'week';
            obj.interval = Math.floor(days / 7);
        } else if (days <= 30) {
            obj.unit = 'day';
            obj.interval = days;
        } else {
            obj.unit = 'month';
            obj.interval = Math.floor(end.diff(start, 'months'));
        }
        return obj;
    }

    function toOrdinal(n) {
        if (n % 10 === 1 && n % 100 !== 11) {
            return n + 'st';
        } else if (n % 10 === 2 && n % 100 !== 12) {
            return n + 'nd';
        } else if (n % 10 === 3 && n % 100 !== 13) {
            return n + 'rd';
        }
        return n + 'th';
    }

    /**
        A more detailed humanize method than moment.duration().humanize, with multiple
        units for fractions (2 hours, 45 minutes) instead of rounding (3 hours).
    
        Deciding which unit to use is a bit tricky, because it relies on several
        different types of rounding
     */

    function humanizeDuration(milliseconds) {
        var pluralize = function pluralize(num, unit) {
            return num.toLocaleString() + ' ' + unit + (num === 1 ? '' : 's');
        };

        var duration = function duration(num1, unit1, num2, unit2) {
            var part1 = pluralize(num1, unit1);

            // Don't show "0 minutes"
            return num2 ? part1 + ', ' + pluralize(num2, unit2) : part1;
        };

        // Only round minutes, since hours/days show fractions
        var minutes = Math.round(milliseconds / 60000);

        if (minutes < 60) {
            return duration(minutes, 'minute');
        }

        var hours = minutes / 60;

        if (hours < 24) {
            var h = Math.floor(hours);
            var m = Math.round((hours - h) * 60);

            return duration(h, 'hour', m, 'minute');
        }

        var days = hours / 24;

        // Up to 7 days, 23 hours
        if (hours < 191.5) {
            var d = Math.floor(days);
            var _h = Math.round((days - d) * 24);

            return duration(d, 'day', _h, 'hour');
        }

        return duration(Math.round(days), 'day');
    }
});