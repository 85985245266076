define('huyang-common/components/set-request-info', ['exports', 'huyang-common/templates/components/set-request-info', 'huyang-common/mixins/request-info'], function (exports, _setRequestInfo, _requestInfo) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_requestInfo.default, {
        layout: _setRequestInfo.default,

        showEstimatedHours: Ember.computed.or('request.isTemplate', 'repeatText')
    });
});