define('huyang-common/adapters/cost-log', ['exports', 'huyang-common/adapters/application'], function (exports, _application) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _application.default.extend({
        urlForCreateRecord: function urlForCreateRecord(modelName, snapshot) {
            // use with-attach endpoint to combine create and attach into a single request
            var url = this._super(modelName, snapshot);
            if (snapshot.record.attachment && snapshot.record.attachment.length) {
                url += '/attach';
            }
            return url;
        }
    });
});