define('huyang-common/components/request-meta', ['exports', 'huyang-common/templates/components/request-meta', 'huyang-common/mixins/displays-priority', 'huyang-common/utils/date-utils'], function (exports, _requestMeta, _displaysPriority, _dateUtils) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    var _slicedToArray = function () {
        function sliceIterator(arr, i) {
            var _arr = [];
            var _n = true;
            var _d = false;
            var _e = undefined;

            try {
                for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
                    _arr.push(_s.value);

                    if (i && _arr.length === i) break;
                }
            } catch (err) {
                _d = true;
                _e = err;
            } finally {
                try {
                    if (!_n && _i["return"]) _i["return"]();
                } finally {
                    if (_d) throw _e;
                }
            }

            return _arr;
        }

        return function (arr, i) {
            if (Array.isArray(arr)) {
                return arr;
            } else if (Symbol.iterator in Object(arr)) {
                return sliceIterator(arr, i);
            } else {
                throw new TypeError("Invalid attempt to destructure non-iterable instance");
            }
        };
    }();

    exports.default = Ember.Component.extend(_displaysPriority.default, {
        layout: _requestMeta.default,
        avatarCrop: '?fit=crop&crop=faces&h=512&w=512',

        session: Ember.inject.service(),

        buildingImageUrl: function () {
            return this.get('request.building.smallThumbnail');
        }.property('request.building.smallThumbnail'),

        creatorImageUrl: function () {
            return '' + this.get('request.originator.photoUrl') + this.get('avatarCrop');
        }.property('request.originator.photoUrl'),

        ownerImageUrl: function () {
            return '' + this.get('request.owner.photoUrl') + this.get('avatarCrop');
        }.property('request.owner.photoUrl'),

        requestLabelStyle: function () {
            var color = this.get('request.requestLabel.color');
            return 'background-color: ' + color + ';';
        }.property('request.requestLabel.color'),

        requestLabelName: function () {
            return this.get('request.requestLabel.name') || 'None';
        }.property('request.requestLabel.name'),

        assetName: function () {
            return this.get('request.asset.tag') || 'None';
        }.property('request.asset.tag'),

        buttonLabel: function () {
            if (this.get('request.owner.vendorTechnician.vendor')) {
                return this.get('request.owner.vendorTechnician.vendor.name') + ': ' + this.get('request.owner.displayName');
            }

            if (this.get('request.owner.id')) {
                return this.get('request.owner.displayName');
            }

            return 'Nobody';
        }.property('request.owner.id', 'request.owner.vendorTechnician.vendor'),

        requestLabelOptions: function () {
            return this.get('requestLabels').sortBy('position', 'name').map(function (requestLabel) {
                return {
                    id: requestLabel.get('id'),
                    name: requestLabel.get('name'),
                    color: requestLabel.get('color')
                };
            });
        }.property(),

        sortedStaffUsers: function () {
            var users = this.get('staffUsers') || [];
            var buildingId = this.get('request.building.id');
            // top owners for this building
            var owners = (this.get('topOwners') || []).find(function (topOwner) {
                return topOwner.get('id') === buildingId;
            });

            if (owners) {
                var ownerIds = owners.get('users'); // array of user ids

                users.forEach(function (u) {
                    var index = ownerIds.indexOf(parseInt(u.get('id')));

                    u.set('sortKey', index >= 0 ? index : ownerIds.length + ' ' + u.get('name'));
                });
                return users.sortBy('sortKey');
            }

            return users.sortBy('name');
        }.property('staffUsers.[]', 'request.building.id'),

        priorities: function () {
            var priorities = [];
            var pmap = this.get('priorityMap');

            Object.keys(pmap).forEach(function (level) {
                var p = pmap[level];

                if (p) {
                    priorities.push({
                        level: level,
                        className: p.className,
                        label: p.label
                    });
                }
            });

            return priorities;
        }.property(),

        datePickerOptions: function () {
            var request = this.get('request'),
                now = moment(),
                minDate = void 0,
                defaultDate = void 0;

            if (moment(request.get('created')).isBefore(now)) {
                // already created
                minDate = request.get('created');
            } else {
                // create in future
                minDate = moment(now).add(1, 'day');
            }
            if (request.get('dueDate')) {
                defaultDate = moment(request.get('dueDate'));
            } else {
                defaultDate = moment(minDate).add(7, 'days').startOf('day').hour(17);
            }

            this.set('dueDate', defaultDate);

            return {
                inline: true,
                sideBySide: false,
                minDate: minDate,
                defaultDate: defaultDate,
                stepping: 5
            };
        }.property(),

        dueDateLabel: function () {
            if (this.get('schedule.dueAfter')) {
                var _get$split = this.get('request.schedule.dueAfter').split(' '),
                    _get$split2 = _slicedToArray(_get$split, 2),
                    interval = _get$split2[0],
                    unit = _get$split2[1];

                if (+interval === 1 && unit.slice(-1) === 's') {
                    unit = unit.slice(0, -1);
                }

                return interval + ' ' + unit + ' after creation';
            }

            if (this.get('request.dueDate')) {
                var dt = moment(this.get('request.dueDate'));
                var userTz = this.get('session.currentUser.timezone');
                if (userTz) {
                    dt = dt.tz(userTz);
                }
                return dt.format('ddd M/D/YYYY h:mm a');
            } else {
                return 'None';
            }
        }.property('request.dueDate', 'hasRelativeDueDate', 'request.schedule.dueAfter'),

        isScheduled: Ember.computed.or('hasSchedule', 'request.isScheduled'),

        actions: {
            storeDueDate: function storeDueDate(momentDate) {
                this.set('dueDate', momentDate);
            },

            hideDueDatePicker: function hideDueDatePicker() {
                var dt = moment(this.get('request.dueDate'));
                this.set('dueDate', dt);
                // tell date time picker to update
                this.set('selectedDate', dt.toDate());
            },

            saveDueDate: function saveDueDate() {
                var _this = this;

                var request = this.get('request'),
                    schedule = request.get('schedule'),
                    newDueDate = this.get('dueDate'),
                    existingDueDate = request.get('dueDate'),
                    flash = this.get('flashMessages');

                request.set('dueDate', newDueDate.toDate());
                if (request.get('isPending')) {
                    // Update creation date to maintain same interval and update schedule
                    var _toIntervalObject = (0, _dateUtils.toIntervalObject)(request.get('created'), existingDueDate),
                        unit = _toIntervalObject.unit,
                        interval = _toIntervalObject.interval,
                        newCreateDate = moment(newDueDate).subtract(interval, unit).toDate();

                    // request.save looks for createDate
                    // update request ignores created; update schedule sets request.created


                    request.set('createDate', newCreateDate);
                    schedule.setProperties({ start: newCreateDate, nextCreate: newCreateDate });
                }
                request.save().then(function () {
                    if (schedule && schedule.get('isDirty')) {
                        return schedule.save();
                    }
                }).then(function () {
                    flash.clearMessages();
                    flash.success('Due date ' + (existingDueDate ? 'update' : 'save') + 'd!');
                    if (_this.updateMeta) {
                        _this.sendAction('updateMeta');
                    }
                });
            },

            removeDueDate: function removeDueDate() {
                var _this2 = this;

                var request = this.get('request');
                request.set('dueDate', null);
                request.save().then(function () {
                    _this2.get('flashMessages').success('Due date removed.');
                    if (_this2.updateMeta) {
                        _this2.sendAction('updateMeta');
                    }
                });
            },

            setRequestLabel: function setRequestLabel(requestLabelOption) {
                var _this3 = this;

                var flash = this.get('flashMessages');
                flash.clearMessages();

                var optionId = requestLabelOption ? requestLabelOption.id : null;

                if (this.get('request.requestLabel.id') === optionId) {
                    return;
                }

                var request = this.get('request');
                var requestLabel = this.get('requestLabels').find(function (requestLabel) {
                    return requestLabel.get('id') === optionId;
                });

                request.set('requestLabel', requestLabel);
                request.save().then(function () {
                    flash.success('Request label updated!');
                    if (_this3.updateMeta) {
                        _this3.sendAction('updateMeta');
                    }
                });
            },

            setPriority: function setPriority(level) {
                var _this4 = this;

                this.get('flashMessages').clearMessages();
                this.get('request').set('priority', level);
                this.get('request').save().then(function () {
                    _this4.get('flashMessages').success('Priority updated!');
                    if (_this4.updateMeta) {
                        _this4.sendAction('updateMeta');
                    }
                });
            },

            reassign: function reassign(user) {
                var _this5 = this;

                this.get('flashMessages').clearMessages();
                this.get('request').set('owner', user);
                this.get('request').save().then(function () {
                    _this5.get('flashMessages').success('Reassigned request');
                    if (_this5.updateMeta) {
                        _this5.sendAction('updateMeta');
                    }
                });
            },

            assignToVendor: function assignToVendor(user) {
                this.sendAction('assignToVendor', user);
            },

            createVendor: function createVendor(email) {
                this.sendAction('createVendor', email);
            }
        }
    });
});