define('huyang-common/components/followers-list', ['exports', 'huyang-common/templates/components/followers-list'], function (exports, _followersList) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        layout: _followersList.default,
        classNames: ['followers-list'],

        actions: {
            unfollow: function unfollow(followerId) {
                this.get('request').removeFollower(followerId);
            }
        }
    });
});