define('huyang-common/components/request-label-dropdown', ['exports', 'huyang-common/templates/components/request-label-dropdown'], function (exports, _requestLabelDropdown) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        layout: _requestLabelDropdown.default,
        classNames: ['request-label-dropdown'],

        requestLabelOptions: function () {
            return this.get('labels').sortBy('position', 'name');
        }.property('labels.@each.position', 'labels.@each.name'),

        requestLabelName: function () {
            return this.get('selectedLabel.id') ? this.get('selectedLabel.name') : 'None';
        }.property('selectedLabel.id'),

        selectedLabel: function () {
            if (this.get('labelSelected')) {
                return this.get('newLabel');
            }
            return this.get('defaultLabel') || this.get('request.requestLabel');
        }.property('labelSelected', 'defaultLabel', 'newLabel', 'request.requestLabel.name'),

        actions: {
            setLabel: function setLabel(label) {
                this.set('labelSelected', true);
                this.set('newLabel', label);
                this.sendAction('setLabel', label);
            }
        }
    });
});