define('huyang-common/models/request-attachment', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        request: _emberData.default.belongsTo('request', { async: true }),
        filename: _emberData.default.attr('string'),
        attachmentType: _emberData.default.attr('string'), // pdf, png, etc
        uuid: _emberData.default.attr('string'),
        created: _emberData.default.attr('date'),
        comment: _emberData.default.belongsTo('requestComment', { async: true }),
        costLog: _emberData.default.belongsTo('costLog', { async: true }),
        user: _emberData.default.belongsTo('user', { async: true }),

        iconClassMap: {
            'excel': 'fa-file-excel-o',
            'pdf': 'fa-file-pdf-o',
            'word': 'fa-file-word-o',
            'zip': 'fa-file-zip-o',
            'text': 'fa-file-text-o',
            'other': 'fa-file-o'
        },

        isImage: function () {
            var attType = this.get('attachmentType') || '';
            return attType.indexOf('image/') === 0 || attType.indexOf('img/') === 0;
        }.property('attachmentType'),

        isPdf: function () {
            return this.get('fileType') === 'pdf';
        }.property('fileType'),

        canPreview: function () {
            return this.get('isImage') || this.get('isPdf');
        }.property('isImage', 'isPdf'),

        fullUrl: function fullUrl(baseUrl) {
            var filename = this.get('filename') || '';
            var parts = filename.split('.');
            var extension = parts.length > 1 ? parts[parts.length - 1] : '';
            var accountId = this.get('request.account.id') || this.get('pmProgram.account.id');

            return baseUrl + '/' + accountId + '/requests/' + this.get('uuid') + '.' + extension;
        },

        downloadUrl: function () {
            var config = Ember.getOwner(this).resolveRegistration('config:environment');
            return this.fullUrl(config.S3_BASE_URL);
        }.property('uuid'),

        url: function () {
            var config = Ember.getOwner(this).resolveRegistration('config:environment');
            return this.fullUrl(config.IMGIX_BASE_URL || config.S3_BASE_URL);
        }.property('uuid'),

        imageUrl: function () {
            return this.get('url') + '?w=2200';
        }.property('url'),

        thumbnailUrl: function () {
            return this.get('url') + '?w=640&fit=crop';
        }.property('url'),

        thumbnailSmallSquareUrl: function () {
            return this.get('url') + '?w=80&h=80&fit=crop';
        }.property('url'),

        fileType: function () {
            var attType = this.get('attachmentType') || '';
            var iconKey = Object.keys(this.get('iconClassMap')).find(function (key) {
                return attType.indexOf(key) >= 0;
            });

            if (!iconKey) {
                iconKey = 'other';
            }

            return iconKey;
        }.property('attachmentType'),

        iconClass: function () {
            if (this.get('isImage')) {
                return 'fa-file-image-o';
            }

            var iconKey = this.get('fileType');
            return this.get('iconClassMap')[iconKey];
        }.property('fileType'),

        thumbnailClass: function () {
            return this.get('isImage') ? 'image' : 'file ' + this.get('fileType');
        }.property('isImage', 'iconClass')
    });
});