define('huyang-common/services/intl-tel-input', ['exports', 'npm:intl-tel-input', 'npm:libphonenumber-js'], function (exports, _npmIntlTelInput, _npmLibphonenumberJs) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    iti: null,
    error: null,

    errorMap: ['Invalid number', 'Invalid country code', 'Invalid number: too short', 'Invalid number: too long', 'Invalid number'],

    initInput: function initInput($el, country) {
      this.set('error', null);
      var iti = (0, _npmIntlTelInput.default)($el[0], {
        initialCountry: country || 'US',
        preferredCountries: ['us', 'ca', 'mx', 'gb'],
        separateDialCode: true,
        utilsScript: 'https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.13/js/utils.min.js'
      });

      this.set('iti', iti);
    },
    validate: function validate() {
      var iti = this.get('iti');

      if (!iti || iti.isValidNumber()) {
        return {};
      } else {
        var errorCode = iti.getValidationError();
        return { error: this.get('errorMap')[errorCode] };
      }
    },
    formatRawNumber: function formatRawNumber(number) {
      if (!number) return '';

      var phoneNumber = (0, _npmLibphonenumberJs.default)(number);

      if (phoneNumber) {
        return '+' + phoneNumber.countryCallingCode + ' ' + phoneNumber.formatNational();
      }

      return number;
    }
  });
});