define('huyang-common/helpers/links-target', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.linksTarget = linksTarget;


  // Opens external links in a new tab
  // https://internal.tikkit.gridium.com/requests/228652/info
  //
  function linksTarget(params /*, hash*/) {
    var string = params[0];

    if (!string) {
      return '';
    }

    var linksRegex = /<a(.*?)<\/a>/g;
    var links = string.match(linksRegex);

    if (!links) {
      return string;
    }

    for (var i = 0; i < links.length; i++) {
      var hostname = window.location.hostname;
      var isLinkInternal = links[i].match(hostname);
      var isLinkWorkOrder = links[i].match('work-order-link');

      if (!isLinkInternal && !isLinkWorkOrder) {
        var linkSplit = links[i].split('<a ');
        var newLink = '<a target="_blank" ' + linkSplit[1];
        string = string.replace(links[i], newLink);
      }
    }

    return string;
  }

  exports.default = Ember.Helper.helper(linksTarget);
});