define('huyang-common/mixins/request-info', ['exports', 'huyang-common/mixins/creates-followers', 'huyang-common/mixins/creates-vendors', 'ember-validations', 'huyang-common/mixins/handles-attachments', 'huyang-common/mixins/modal-helper', 'huyang-common/mixins/repeats', 'npm:@sentry/browser', 'huyang-common/utils/rrule-utils'], function (exports, _createsFollowers, _createsVendors, _emberValidations, _handlesAttachments, _modalHelper, _repeats, _browser, _rruleUtils) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Mixin.create(_createsFollowers.default, _createsVendors.default, _emberValidations.default, _handlesAttachments.default, _modalHelper.default, _repeats.default, {
        session: Ember.inject.service(),
        store: Ember.inject.service(),

        // shows internal request values on the page, useful for identifying disconnects from components and actual properties
        debug: false,

        submitDisabled: false,

        // the ID of the input element for the file upload
        inputId: 'requestFileUpload',
        peopleLimit: 5,
        allowAssigningToVendors: true,

        request: null,
        assignModeStaff: true,
        isLoading: false,
        hasErrors: false,
        scheduling: false,
        createLater: false,
        submissionError: false,

        trades: [],
        vendor: null,
        vendorInfo: {},

        selectedFollowers: [],

        validations: {
            'request.title': { presence: true },
            'request.building': { presence: true },
            'request.requestType': { presence: true },
            'request.priority': { presence: true }
        },

        estimatedHoursValidationFailure: Ember.computed('request.estimatedHours', function () {
            return !this.get('request.estimatedHours') || this.get('request.estimatedHours') <= 0;
        }),

        formValidations: function () {
            /*
                this is provided to form-group components to allow each component to
                check if the field passed validations and to change the display
                depending on the validation rules
            */

            var validations = this.get('validations');

            // TODO: This works, but phantom is having problems that I could not find
            // a way around.
            //
            // if (this.get('showEstimatedHours')) {
            //   // this shows the required *
            //   validations['request.estimatedHours'] = { numericality: {greaterThan: 0} };
            //
            //   // but for whatever reason, updating validations dynamically does not
            //   // work thoroughly, so we have to do some manual labor here to get the
            //   // error message to show
            //   if (this.get('hasErrors')) {
            //     if (this.get('estimatedHoursValidationFailure')) {
            //       const estimatedHours = ['must be greater than 0'];
            //
            //       if (this.errors.request) {
            //         this.errors.request.estimatedHours = estimatedHours;
            //       } else {
            //         // if this is the only error, the request key needs to be created
            //         // rather than just added to
            //         this.errors.request = { estimatedHours: estimatedHours };
            //       }
            //     } else if (this.errors.request.estimatedHours) {
            //       delete this.errors.request.estimatedHours;
            //     }
            //   }
            // }

            return {
                validations: validations,
                errors: this.get('hasErrors') ? this.errors : null
            };
        }.property('validations', 'hasErrors', 'showEstimatedHours'),

        setup: function () {
            // creating request from checklist item
            if (this.get('testStore')) {
                this.set('store', this.get('testStore'));
            }

            if (this.get('checklistItemId')) {
                var rci = this.get('store').peekRecord('request-checklist-item', this.get('checklistItemId'));

                if (rci) {
                    var r = rci.get('checklist.request');
                    var requestLabel = rci.get('requestLabel.id') ? rci.get('requestLabel') : r.get('requestLabel');

                    this.set('request', {
                        title: rci.get('body'),
                        requestLabel: requestLabel,
                        description: 'Related to item #' + r.get('workOrder') + ': ' + r.get('title'),

                        building: r.get('building'),
                        subspace: r.get('subspace'),
                        owner: r.get('owner'),
                        requestType: r.get('requestType'),
                        followers: r.get('followers')
                    });
                }
            }

            // set default attributes
            if (Ember.isBlank(this.get('request'))) {
                // don't override `request` if it was supplied as a component param
                this.set('request', {});
            }

            this.set('assignModeStaff', true); // TODO: set this to false if assigned to vendor
            this.set('isLoading', false);
            this.set('hasErrors', false);

            if (this.get('buildings.length') === 1) {
                this.set('request.building', this.get('buildings.firstObject'));
            }

            if (this.get('request.building.activeSubspaces.length') === 1) {
                this.set('request.subspace', this.get('building.activeSubspaces.firstObject'));
            }

            if (!this.get('request.requestLabel')) {
                this.set('request.requestLabel', this.get('defaultRequestLabel'));
            }

            if (!this.get('request.priority')) {
                this.set('request.priority', 3);
            }

            if (!this.get('dueAfterInterval')) {
                this.set('dueAfterInterval', 1);
            }

            if (!this.get('dueAfterUnit')) {
                this.set('dueAfterUnit', 'weeks');
            }

            if (!this.get('alwaysIncludeDay')) {
                this.set('alwaysIncludeDay', moment().date());
            }

            if (this.get('showAddFollowers')) {
                // originator is a follower by default
                var userId = this.get('session.currentUser.id');
                var originator = this.get('users').find(function (u) {
                    return u.get('id') === userId;
                });

                if (this.get('request.followers.length')) {
                    this.set('selectedFollowers', this.get('request.followers').map(function (f) {
                        return f.get('user.id');
                    }));
                } else if (originator) {
                    this.set('selectedFollowers', [userId]);
                }
            }

            if (this.get('showEstimatedHours') && !this.get('request.estimatedHours')) {
                this.set('request.estimatedHours', 1);
            }

            var dueDate = this.get('request.dueDate');

            if (!dueDate) {
                dueDate = moment().add(7, 'day').startOf('hour');
                dueDate.add(moment().minute() < 30 ? 1 : 2, 'hour');

                if (this.get('session.currentUser.timezone')) {
                    dueDate = moment.tz(dueDate, this.get('session.currentUser.timezone'));
                }
            }

            var createDate = this.get('request.schedule.start');

            if (createDate) {
                createDate = moment(createDate);
                if (createDate.isAfter(moment())) {
                    this.set('createLater', true);
                }
            } else {
                createDate = moment(dueDate).subtract(1, 'day');
            }

            this.set('dueDate', dueDate);
            this.set('createDate', createDate);
        }.on('init'),

        people: function () {
            var _this = this;

            if (!this.get('users')) {
                return [];
            }

            var building = this.get('request.building');
            var topOwners = null;

            if (building && this.get('topOwners')) {
                topOwners = this.get('topOwners').find(function (o) {
                    return o.get('id') === building.get('id');
                });
            }
            this.set('peopleSortKey', topOwners ? 'sort' : 'name');

            return this.get('users').filter(function (u) {
                var includeUser = !building || u.hasStaffRole(building.get('account.id'), building.get('id'));
                u.set('selected', includeUser && _this.get('request.owner.id') === u.get('id'));
                if (includeUser && topOwners) {
                    var isTop = topOwners.get('users').indexOf(parseInt(u.get('id'))) >= 0;
                    u.set('sort', (isTop ? 0 : 1) + '-' + u.get('name'));
                }
                return includeUser;
            });
        }.property('users', 'request.building', 'assignModeStaff'),

        setPeopleLimit: function () {
            if (this.get('hideAssignTo')) {
                return;
            }

            var limit = 5;
            var SCREEN_WIDTH_CUTOFF = 1200;

            // show one less person on smaller screens
            if (Ember.$(window).width() <= SCREEN_WIDTH_CUTOFF) {
                limit = limit - 1;
            }

            if (this.get('people').length === limit + 1) {
                limit = limit + 1;
            }

            this.set('peopleLimit', limit);
        }.on('didInsertElement'),

        requestTypesFor: function requestTypesFor(building) {
            if (!building) {
                return [];
            }
            var rtb = building.get('requestTypes') || [];
            var rtIds = rtb.map(function (rt) {
                return rt.get('requestType.id');
            });
            var requestTypes = this.get('requestTypes') || [];
            return requestTypes.filter(function (reqType) {
                return reqType.get('active') && rtIds.includes(reqType.get('id'));
            });
        },

        requestTypesForBuilding: function () {
            return this.requestTypesFor(this.get('request.building'));
        }.property('request.building.requestTypes.[]', 'requestTypes'),

        requestTypeList: function () {
            // need a selected building first
            if (!this.get('request.building')) {
                return [];
            }

            var reqId = parseInt(this.get('request.id'));

            return this.get('requestTypesForBuilding').map(function (it) {
                it.set('selected', parseInt(it.id) === reqId);
                return it;
            });
        }.property('request.building', 'request', 'requestTypesForBuilding'),

        defaultRequestLabel: function () {
            return (this.get('requestLabels') || []).find(function (label) {
                return label.get('isDefaultOpen');
            });
        }.property('requestLabels'),

        setOwner: function setOwner(user) {
            this.set('request.owner', user);

            if (user) {
                var userId = user.get('id');

                if (this.get('showAddFollowers') && !this.get('selectedFollowers').includes(userId)) {
                    this.get('selectedFollowers').pushObject(userId);
                }
            }
        },

        resetOwner: function () {
            var staff = this.get('assignModeStaff');

            if (staff) {
                this.set('newVendorEmail', null);
                this.set('vendor', null);
            } else {
                this.setOwner(null);
            }
        }.observes('assignModeStaff'),

        showSecondColumn: function () {
            var _this2 = this;

            var showColumn = false;

            // show the right hand column if any of the following properties are truthy
            ['hintText', 'showAddFollowers', 'showDueDate'].forEach(function (prop) {
                if (_this2.get(prop)) {
                    showColumn = true;
                }
            });

            return showColumn;
        }.property('hintText', 'showAddFollowers', 'showDueDate'),

        hasFollowerErrors: function () {
            return this.get('hasErrors') && this.get('errors.selectedFollowers');
        }.property('hasErrors', 'errors.selectedFollowers'),

        willDestroy: function willDestroy() {
            Ember.$('.people-menu-container').remove();
        },

        uploadUrl: function () {
            return '/api/' + this.get('session.data.authenticated.accountId') + '/requestAttachments';
        }.property('model'),

        showLocationInput: function () {
            return this.get('buildings.length') !== 1;
        }.property('buildings.length'),

        showSubspaceInput: function () {
            return this.get('request.building') && this.get('request.building.activeSubspaces.length') > 0;
        }.property('request.building', 'request.building.activeSubspaces.length'),

        showRequestLabelInput: function () {
            return !this.get('hideRequestLabels') && this.get('requestLabels.length');
        }.property('hideRequestLabels', 'requestLabels.length'),

        sortedRequestLabels: function () {
            return this.get('requestLabels').sortBy('position', 'name');
        }.property('requestLabels'),

        emergencyPhone: function () {
            return this.get('request.building.emergencyPhone') ? this.get('request.building.formattedEmergencyPhone') : '';
        }.property('request.building.emergencyPhone', 'request.building.formattedEmergencyPhone'),

        showAssignTo: function () {
            return !this.get('hideAssignTo') && this.get('request.building');
        }.property('hideAssignTo', 'request.building'),

        showAsset: function () {
            return !this.get('hideAsset') && this.get('request.building') && !this.get('edit');
        }.property('hideAsset', 'request.building', 'edit'),

        setDefaultSubject: function () {
            if (!this.get('subject') && this.get('request.title')) {
                var subject = this.get('request.title');

                if (this.get('request.building') && this.get('request.subspace.name')) {
                    subject = this.get('request.title') + ' (' + this.get('request.building.address1') + ' - ' + this.get('request.subspace.name') + ')';
                } else if (this.get('request.building')) {
                    subject = this.get('request.title') + ' (' + this.get('request.building.address1') + ')';
                }

                this.set('subject', subject);
            }
        }.observes('assignModeStaff'),

        repeatText: function () {
            var timezone = this.get('session.currentUser.timezone');
            var afterClose = this.get('request.afterClose');
            var repeatRule = this.get('request.repeatRule');
            var start = this.get('request.createDate');

            if (start && timezone) {
                start = moment(start);
                start.tz(timezone);
            }

            if (afterClose) {
                // Depluralize the display text only for single interval
                if (afterClose.slice(0, 2) === '1 ') {
                    afterClose = afterClose.slice(0, -1);
                }
                var startText = moment(start).format('ddd, MMM D, YYYY [at] h:mm a');
                return afterClose + ' after the previous request is closed, starting on ' + startText;
            } else if (repeatRule) {
                if (repeatRule && start) {
                    return (0, _rruleUtils.rruleToText)(repeatRule, start);
                }
            }
        }.property('request.afterClose', 'request.repeatRule', 'request.createDate'),

        clearDueDateSchedule: function clearDueDateSchedule() {
            var _this3 = this;

            // Clear props set by due date picker
            var fieldsToClear = ['dueDate', 'createDate'];
            fieldsToClear.forEach(function (f) {
                return _this3.set('request.' + f, null);
            });
        },

        clearRepeatSchedule: function clearRepeatSchedule() {
            var _this4 = this;

            // Rather than clear every single controller prop set by recurrence picker,
            // just clear the ones that control saving to fields and displaying text
            this.set('afterClose', null);
            this.set('repeatRule', null);

            // Clear all repeat fields
            var fieldsToClear = ['createDate', 'repeatRule', 'afterClose', 'dueAfter', 'previousClose', 'previousRelabel', 'previousLabel'];

            fieldsToClear.forEach(function (f) {
                return _this4.set('request.' + f, null);
            });
        },

        alwaysIncludeMonth: Ember.computed('alwaysIncludeMonthInt', function () {
            if (this.get('alwaysIncludeMonthInt')) {
                return moment.months(this.get('alwaysIncludeMonthInt'));
            }

            // default is current month
            return moment.months()[moment().month()];
        }),

        saveRequest: function saveRequest() {
            var _this5 = this;

            this.set('isLoading', true);
            var attachmentsList = this.get('attachmentsList');
            var modelName = this.get('request.constructor.modelName');

            if (modelName) {
                // it's an existing model that we just need to update
                var request = this.get('request');

                this.set('request.updated', new Date());

                if (attachmentsList) {
                    this.set('request.attachment', attachmentsList.map(function (file) {
                        return file.get('id');
                    }));
                }

                request.save().then(function () {
                    if (!_this5.get('assignModeStaff') && _this5.get('vendor')) {
                        _this5.saveServiceRequest(request).then(function () {
                            _this5.set('isLoading', false);
                            _this5.sendAction('requestSaved', request);
                        }).catch(function (error) {
                            _this5.set('submissionError', error.message);
                            _this5.set('isLoading', false);
                            _browser.default.captureMessage('Error saving service request: ' + error.message, { extra: error.errors[0] });
                        });
                    } else {
                        _this5.sendAction('requestSaved', request);
                    }
                }).catch(function (error) {
                    _this5.set('submissionError', error.message);
                    _this5.set('isLoading', false);
                    _browser.default.captureMessage('Error saving request: ' + error.message, { extra: error.errors[0] });
                });
            } else {
                // this is a brand new request
                var info = this.get('request');
                var followerUsers = this.get('selectedFollowers');

                info.account = this.get('request.building.account');

                // set a bunch of default data for the new request
                info.created = new Date();
                info.updated = info.created;
                info.opened = info.created;
                info.secondsOpen = 0;
                info.status = 'open';
                info.originator = this.get('session.currentUser');
                // if dates are moments, convert so Ember can serialize
                if (this.get('scheduling') && this.get('dueDate') && this.get('dueDate').toDate) {
                    info.dueDate = this.get('dueDate').toDate();
                }
                if (this.get('scheduling') && this.get('createLater') && this.get('createDate') && this.get('createDate').toDate) {
                    info.createDate = this.get('createDate').toDate();
                }

                if (attachmentsList && attachmentsList.length) {
                    info.attachment = attachmentsList.map(function (file) {
                        return file.get('id');
                    });
                }

                this.createFollowers(followerUsers, info.building.get('id')).then(function (result) {

                    info.followerUsers = result.followerIds;
                    info.newUsers = result.newUserIds;

                    var newRequest = _this5.get('store').createRecord('request', info);

                    if (_this5.get('checklist')) {
                        newRequest.set('checklists', [_this5.get('checklist')]);
                    }

                    newRequest.save().then(function () {
                        if (!_this5.get('assignModeStaff') && _this5.get('vendor')) {
                            _this5.saveServiceRequest(newRequest).then(function () {
                                _this5.set('isLoading', false);
                                _this5.sendAction('requestSaved', newRequest, result.newUserIds);
                            }).catch(function (error) {
                                _this5.set('submissionError', error.message);
                                _this5.set('isLoading', false);
                                _browser.default.captureMessage('Error creating new service request: ' + error.message, { extra: error.errors[0] });
                            });
                        } else {
                            _this5.set('isLoading', false);
                            _this5.sendAction('requestSaved', newRequest, result.newUserIds);
                        }
                    }).catch(function (error) {
                        var message = error.errors[0].detail;

                        if (message.indexOf('Duplicate')) {
                            message = 'A matching request already exists';
                        } else {
                            message = 'There was an error creating this request';
                        }

                        _this5.set('submissionError', message);
                        _this5.set('isLoading', false);
                        _browser.default.captureMessage('Error creating new request: ' + message, { extra: error.errors[0] });
                    });
                });
            }
        },

        saveServiceRequest: function saveServiceRequest(request) {
            var user = request.get('owner');
            var subject = this.get('subject');
            var vendor = void 0;

            if (user) {
                // find recipient's vendor ID and possible technician ID
                var technician = this.get('technicians').findBy('user.id', user.get('id'));
                console.log(technician, user.get('id'));

                if (technician) {
                    vendor = technician.get('vendor');
                } else {
                    console.log(this.get('vendors'), user.get('id'));
                    vendor = this.get('vendors').findBy('primaryUser.id', user.get('id'));
                }

                if (vendor) {
                    var accountId = localStorage.getItem('accountId');
                    var account = this.get('store').peekRecord('account', accountId);

                    var serviceRequest = this.get('store').createRecord('vendorServiceRequest', {
                        account: account,
                        vendor: vendor,
                        technician: technician,
                        request: request,
                        commentSubject: subject,
                        commentText: request.get('description'),
                        disableComment: true
                    });

                    return serviceRequest.save();
                }
            }
        },

        disableSubmitDuringUpload: Ember.observer('attachmentsList.@each.id', function () {
            var submitDisabled = false;

            this.get('attachmentsList').forEach(function (att) {
                if (!att.get('id')) {
                    submitDisabled = true;
                }
            });

            this.set('submitDisabled', submitDisabled);
        }),

        // assets are building-scoped; clear the asset if the building changes
        buildingObserver: Ember.observer('request.building', function () {
            this.set('request.asset', null);
        }),

        actions: {
            addChecklist: function addChecklist() {
                var _this6 = this;

                var data = {
                    'account': localStorage.getItem('accountId')
                };

                this.get('store').createRecord('request-checklist', data).save().then(function (checklist) {
                    _this6.set('checklist', checklist);
                });
            },
            removeChecklist: function removeChecklist() {
                this.set('checklist', false);
            },
            validate: function validate() {
                var _this7 = this;

                this.set('hasErrors', false);
                this.set('isLoading', true);

                this.validate().then(function () {
                    // a bit more manual labor since EV doesn't want to do the
                    // work for us on a dynamically-set validation
                    // if (this.get('showEstimatedHours') && this.get('estimatedHoursValidationFailure')) {
                    //   this.set('hasErrors', true);
                    //   this.set('isLoading', false);
                    // } else {
                    //   if (this.get('newVendorEmail')) {
                    //       // present vendor info form
                    //       this.set('isLoading', false);
                    //       this.openModal('newVendorModal');
                    //   }
                    //   else {
                    //       this.saveRequest();
                    //   }
                    // }

                    if (_this7.get('newVendorEmail')) {
                        // present vendor info form
                        _this7.set('isLoading', false);
                        _this7.openModal('newVendorModal');
                    } else {
                        _this7.saveRequest();
                    }
                }).catch(function () {
                    _this7.set('hasErrors', true);
                    _this7.set('isLoading', false);
                });
            },
            saveVendorAndRequest: function saveVendorAndRequest() {
                var _this8 = this;

                var vendorInfo = this.get('vendorInfo');

                this.saveVendor(vendorInfo).then(function (vendor) {
                    _this8.closeModal();
                    _this8.set('request.owner', vendor.get('primaryUser'));
                    _this8.saveRequest();
                });
            },
            selectOwner: function selectOwner(user) {
                this.setOwner(user);
            },
            unsetOwner: function unsetOwner() {
                this.setOwner(null);
            },
            buildingChanged: function buildingChanged() {
                var value = arguments[1];
                var owner = this.get('request.owner');
                var building = this.get('request.building');

                this.set('request.building', building);

                // unset the chosen subspace when the building is changed
                this.set('request.subspace', null);

                // unset the request type if it isn't part of the new building
                var brt = this.get('requestTypesForBuilding').findBy('id', this.get('request.requestType.id'));

                if (!brt) {
                    this.set('request.requestType', null);
                }

                // if owner does not have access to this building, unset
                if (owner && !owner.hasStaffRole(building.get('account.id'), value)) {
                    this.setOwner(null);
                }
            },
            prioritySelected: function prioritySelected(value) {
                this.set('request.priority', parseInt(value));
            },
            cancel: function cancel() {
                this.sendAction('cancel');
            },
            toggleAssignMode: function toggleAssignMode() {
                this.toggleProperty('assignModeStaff');
            },
            selectVendor: function selectVendor(vendorUser) {
                this.set('vendor', vendorUser);

                if (vendorUser && typeof vendorUser.get === 'function') {
                    // is actual user object, can actually assign
                    this.set('request.owner', vendorUser);
                }
            },
            createUserWithEmail: function createUserWithEmail(email) {
                this.set('newVendorEmail', email);
            },
            updateVendorData: function updateVendorData(values) {
                var _this9 = this;

                Object.keys(values).forEach(function (key) {
                    _this9.set('vendorInfo.' + key, values[key]);
                });
            },
            setScheduling: function setScheduling(value) {
                this.set('scheduling', value);

                if (!value) {
                    this.clearDueDateSchedule();
                }
            },
            saveScheduleDates: function saveScheduleDates(dates) {
                var dueDate = dates.dueDate,
                    createDate = dates.createDate;


                this.set('dueDate', dueDate);
                this.set('createDate', createDate);
            },
            openRepeating: function openRepeating() {
                this.openModal('repeatingModal');
            },
            setRepeatRule: function setRepeatRule(props) {
                this.set('afterCloseTemp', {});
                this.set('repeatTemp', props);

                // Fields have default values, but temp props need to be updated for display
                if (!this.get('initialRepeatSet')) {
                    this.set('repeatRule', props.repeatRule);
                    this.set('createDate', props.createDate);
                    this.set('initialRepeatSet', true);
                }
            },
            setAfterClose: function setAfterClose(props) {
                this.set('repeatTemp', {});
                this.set('afterCloseTemp', props);
            },
            saveRepeat: function saveRepeat() {
                var _this10 = this;

                var repeatRuleFields = ['repeatRule', 'createDate', 'dueAfter', 'previousClose', 'previousRelabel', 'previousLabel', 'allowBalancing', 'balancingSchedule'];

                var afterCloseFields = ['afterClose', 'createDate', 'dueAfter'];

                var afterClose = this.get('afterCloseTemp');
                var repeat = this.get('repeatTemp');

                afterClose = afterClose && Object.keys(afterClose).length ? afterClose : null; // if ({}) is true
                repeat = repeat && Object.keys(repeat).length ? repeat : null;

                if (repeat) {
                    repeatRuleFields.forEach(function (attr) {
                        if (repeat[attr]) {
                            _this10.set('request.' + attr, repeat[attr]);
                        }
                    });

                    this.set('allowBalancing', repeat['allowBalancing']);
                    this.set('balancingSchedule', repeat['balancingSchedule']);
                    this.set('dueAfter', repeat['dueAfter']);
                }

                if (afterClose) {
                    afterCloseFields.forEach(function (attr) {
                        if (afterClose[attr]) {
                            _this10.set('request.' + attr, afterClose[attr]);
                        }
                    });
                }
            }
        }
    });
});