define('huyang-common/components/notify-users-message', ['exports', 'huyang-common/templates/components/notify-users-message'], function (exports, _notifyUsersMessage) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        layout: _notifyUsersMessage.default,
        classNames: ['notify-users-message'],
        users: [],
        extraUsers: [],

        // how many users should be listed before there's a tooltip?
        // note: this limit is actually its value + 1, since we only show the bonus
        // tooltip if there are at least 2 extra users BEYOND the below limit.
        // so there can be 0 - limit + 1 users listed at any time.
        limit: 3,

        moreThanOne: function () {
            return this.get('users.length') > 1;
        }.property('users.length'),

        userList: function () {
            var _this = this;

            var users = this.get('users').sortBy('displayName');
            var limit = this.get('limit');

            this.get('extraUsers').clear();

            if (users.length === 1) {
                return users[0].get('displayName');
            } else if (users.length > 1) {
                var message = '';

                users.forEach(function (user, i) {
                    if (i <= limit - 1) {
                        if (i === users.length - 1) {
                            message = message + 'and ';
                        }

                        message = message + user.get('displayName');

                        if (i !== users.length - 1 && i !== users.length - 2 && i !== limit - 1) {
                            // include comma as long as user isn't last or second last
                            message = message + ', ';
                        } else {
                            message = message + ' ';
                        }
                    } else {
                        _this.get('extraUsers').pushObject(user);
                    }
                });

                // if theres only one extra user, just display them
                if (this.get('extraUsers.length') === 1) {
                    message = message + ' and ' + this.get('extraUsers')[0].get('displayName');
                }

                return message;
            }

            return '';
        }.property('users.[]'),

        hasExtraUsers: function () {
            return this.get('extraUsers.length') >= 2;
        }.property('extraUsers.length')
    });
});