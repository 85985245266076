define('huyang-common/models/user', ['exports', 'ember-data', 'huyang-common/mixins/formats-phone', 'npm:jstimezonedetect'], function (exports, _emberData, _formatsPhone, _npmJstimezonedetect) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend(_formatsPhone.default, {
        // NOTE: if you add/remove attributes, update keySets
        accountRoles: _emberData.default.hasMany('accountRole', { async: false }),
        tenantUsers: _emberData.default.hasMany('tenant-user', { async: true }),
        timeLogs: _emberData.default.hasMany('time-log', { async: true, inverse: 'worker' }),

        api: Ember.inject.service(),
        email: _emberData.default.attr('string'),
        password: _emberData.default.attr('string'),
        name: _emberData.default.attr('string'),
        mobilePhone: _emberData.default.attr('string'),
        photoUuid: _emberData.default.attr('string'),
        endOfDay: _emberData.default.attr('number'),
        created: _emberData.default.attr('date'),
        hmac: _emberData.default.attr('string'),

        notifyNew: _emberData.default.attr('number'),
        notifyAssign: _emberData.default.attr('number'),
        notifyProduct: _emberData.default.attr('number'),
        notifyComment: _emberData.default.attr('number'),
        notifyDue: _emberData.default.attr('number'),
        notifyClosed: _emberData.default.attr('number'),
        notifyTimer: _emberData.default.attr('number'),

        smsThresholds: _emberData.default.attr(),
        smsRecurring: _emberData.default.attr(),

        title: _emberData.default.attr('string'),
        timezone: _emberData.default.attr('string'),
        occupantStatus: _emberData.default.attr('string'),

        trackingEnabled: _emberData.default.attr('boolean'),

        keySets: {
            'all': ['email', 'name', 'mobilePhone', 'photoUuid', 'notifyNew', 'notifyAssign', 'notifyProduct', 'notifyComment', 'notifyDue', 'notifyClosed', 'notifyTimer', 'smsThresholds', 'smsRecurring', 'title', 'timezone', 'endOfDay'],
            'staff': ['email', 'name', 'mobilePhone', 'timezone'],
            'occupant': ['email', 'name', 'mobilePhone', 'title', 'timezone']
        },

        // which keys to include in POST and PUT
        // may be altered for some user types (ie don't send photo with staff update)
        keySet: 'all',

        serializeKeys: function serializeKeys() {
            // always include email
            var keys = this.keySets[this.get('keySet')] || this.keySets['all'];
            if (!_.contains(keys, 'email')) {
                keys.push('email');
            }
            return keys;
        },

        openTimers: function () {
            return this.get('timeLogs').filter(function (tl) {
                return !tl.get('endTime');
            });
        }.property('timeLogs.@each.endTime'),

        activeTimers: function () {
            return this.get('openTimers').filter(function (tl) {
                return tl.get('active');
            });
        }.property('openTimers.@each.active'),

        isAdmin: function () {
            var role = this.get('accountRoles').find(function (r) {
                return r.get('role.name') === 'admin';
            });

            return !_.isUndefined(role);
        }.property('accountRoles.[]'),

        isVendor: function () {
            var accountId = localStorage.getItem('accountId');

            if (this.get('isAdmin') || this.hasStaffRole(accountId)) {
                return false;
            }

            return this.hasAnyRole(accountId, ['vendor', 'vendor_technician']);
        }.property('session.data.authenticated.userId', 'session.data.authenticated.accountId'),

        hasAnyRole: function hasAnyRole(accountId, roleNames) {
            // doesn't check buildings
            accountId = parseInt(accountId);

            var role = this.get('accountRoles').find(function (ar) {
                return parseInt(ar.get('account.id')) === accountId && roleNames.includes(ar.get('role.name'));
            });

            return !_.isUndefined(role);
        },

        // buildingId is "undefined" when saving "staff-all" role
        hasRole: function hasRole(accountId, roleName, buildingId) {
            accountId = parseInt(accountId);
            if (buildingId) {
                buildingId = parseInt(buildingId);
            }

            var role = this.get('accountRoles').find(function (ar) {
                var arName = ar.get('role.name');
                var arAccount = parseInt(ar.get('account.id'));

                if (arAccount !== accountId || arName !== roleName) {
                    return false;
                }

                // For some reason, the prior conditional **has** to happen before
                // calling ar.get('building.id'), otherwise will receive:
                // TypeError: Cannot read property '_internalModel' of undefined
                if (ar.get('building.id')) {
                    if (!buildingId) {
                        return false;
                    }
                    return parseInt(ar.get('building.id')) === buildingId;
                } else {
                    return !buildingId;
                }
            });

            return !_.isUndefined(role);
        },

        hasStaffRole: function hasStaffRole(accountId, buildingId) {
            // Halves number of iterations over roles versus
            // user.hasRole('staff') || user.hasRole('staff-all')
            accountId = parseInt(accountId);
            if (buildingId) {
                buildingId = parseInt(buildingId);
            }

            var role = this.get('accountRoles').find(function (ar) {
                var arName = ar.get('role.name');
                var arAccount = parseInt(ar.get('account.id'));

                if (arAccount !== accountId || arName !== 'staff' && arName !== 'staff-all') {
                    return false;
                }
                if (arName === 'staff-all') {
                    return true;
                }
                if (ar.belongsTo('building').id()) {
                    if (!buildingId) {
                        return false;
                    }
                    return parseInt(ar.belongsTo('building').id()) === buildingId;
                } else {
                    return !buildingId;
                }
            });

            return !_.isUndefined(role);
        },

        // accountRoles is array of [account, user, role, building]
        addRoles: function addRoles(accountRoles) {
            var _this = this;

            // FIXME: Optimize number of API calls
            //
            // ember data doesn't support combining multiple requests,
            // so call add roles API method directly
            // all should be for the same account (this one) but model can't access session
            var accountId = accountRoles[0].account;
            var data = JSON.stringify({ accountRoles: accountRoles });
            var store = this.store;
            var user = this;
            return new Ember.RSVP.Promise(function (resolve, reject) {
                return _this.get('api').ajax({
                    type: 'POST',
                    url: '/api/' + accountId + '/multiAccountRoles',
                    data: data,
                    dataType: 'json'
                }).then(function (response) {
                    // push records
                    // without this, tests fail with
                    // Assertion Failed: You have turned on testing mode, which disabled the run-loop's autorun.
                    // You will need to wrap any code with asynchronous side-effects in a run
                    Ember.run(function () {
                        response.accountRoles.forEach(function (ar) {
                            ar = ar.accountRole;
                            // push is used to notify Ember Data's store of new or updated records that exist in the backend
                            var payload = {
                                data: {
                                    id: ar.id,
                                    type: 'account-role',
                                    attributes: {
                                        account: ar.accountId,
                                        building: ar.buildingId,
                                        role: ar.roleId,
                                        user: ar.userId
                                    }
                                }
                            };
                            store.push(payload);

                            // get object from the store and set relationships
                            var arObj = store.peekRecord('account-role', ar.id);

                            arObj.set('role', store.peekRecord('role', ar.roleId || ar.role));
                            arObj.set('user', store.peekRecord('user', ar.userId || ar.user));
                            arObj.set('account', store.peekRecord('account', ar.accountId || ar.account));
                            if (ar.buildingId || ar.building) {
                                arObj.set('building', store.peekRecord('building', ar.buildingId || ar.building));
                            }
                            _this.get('accountRoles').pushObject(arObj);
                        });
                        // refresh user with new account roles
                        user.reload().then(function () {
                            return resolve();
                        });
                    });
                }, function (xhr, status, error) {
                    console.error('error saving roles', error);
                    reject();
                });
            });
        },

        addRole: function addRole(account, roleName, building) {
            var _this2 = this;

            var buildingId = building ? building.get('id') : null;

            Ember.RSVP.hashSettled({
                hasRole: this.hasRole(account.get('id'), roleName, buildingId),
                roles: this.store.query('role', { name: roleName })
            }).then(function (results) {
                if (results.hasRole.value) {
                    return; // already done
                }
                var role = results.roles.value.find(function (r) {
                    return r.get('name') === roleName;
                });

                var info = {
                    user: _this2,
                    account: account,
                    role: role
                };

                if (building) {
                    info.building = building;
                }

                var ar = _this2.store.createRecord('accountRole', info);

                return ar.save().then(function () {
                    return _this2.get('accountRoles').pushObject(ar);
                });
            });
        },

        removeAccountRole: function removeAccountRole(ar) {
            this.get('accountRoles').removeObject(ar);
            return ar.destroyRecord();
        },

        removeRole: function removeRole(account, roleName, building) {
            var _this3 = this;

            var accountId = parseInt(account.get('id'));
            var buildingId = building ? parseInt(building.get('id')) : null;

            return this.store.query('role', { name: roleName }).then(function (roles) {
                var role = roles.find(function (r) {
                    return r.get('name') === roleName;
                });

                var ar = _this3.get('accountRoles').find(function (accountRole) {
                    var match = accountId === parseInt(accountRole.get('account.id')) && role.get('name') === accountRole.get('role.name');

                    if (match && buildingId) {
                        match = buildingId === parseInt(accountRole.get('building.id'));
                    }
                    return match;
                });

                if (ar) {
                    _this3.get('accountRoles').removeObject(ar);
                    return ar.destroyRecord();
                }
            });
        },

        hasPhoto: function () {
            return this.get('photoUuid') != null;
        }.property('photoUuid'),

        photoUrl: function () {
            var config = Ember.getOwner(this).resolveRegistration('config:environment');
            var baseUrl = config.IMGIX_BASE_URL || config.S3_BASE_URL;

            return baseUrl + '/users/' + this.get('photoUuid');
        }.property('photoUuid'),

        initials: function () {
            var name = this.get('name') || this.get('email');
            var parts = name.split(' ');
            if (parts.length > 1) {
                return (parts[0][0] + parts[1][0]).toUpperCase();
            } else {
                return (name[0] + name[1]).toUpperCase();
            }
        }.property('name'),

        firstName: function () {
            var name = this.get('name');

            if (name) {
                return name.split(' ')[0];
            }

            return this.get('email').split('@')[0];
        }.property('name'),

        staffBuildingIds: function () {
            var buildingIds = this.get('accountRoles').map(function (ar) {
                return ar.get('role.name') === 'staff' ? ar.get('building.id') : null;
            });
            return _.compact(buildingIds);
        }.property('accountRoles'),

        formattedMobilePhone: Ember.computed('mobilePhone', function () {
            return this.formatPhone(this.get('mobilePhone'));
        }),

        formattedMobilePhoneDisplay: Ember.computed('mobilePhone', function () {
            return this.formatPhoneDisplay(this.get('mobilePhone'));
        }),

        save: function save() {
            // guess the timezone from the browser so that notifications will use it
            if (!this.get('timezone')) {
                this.set('timezone', _npmJstimezonedetect.default.determine().name());
            }
            return this._super();
        },

        displayName: function () {
            return this.get('name') ? this.get('name') : this.get('email');
        }.property('name', 'email'),

        occupantGroup: function () {
            var tu = this.get('tenantUsers.firstObject');

            if (tu && tu.get('occupantGroup.id')) {
                return tu.get('occupantGroup');
            }

            return null;
        }.property('id'),

        isOccupant: function () {
            return this.get('occupantStatus') ? true : false;
        }.property('occupantStatus'),

        contactString: function () {
            var name = this.get('name');
            var email = this.get('email').toLowerCase();

            if (name) {
                return name + ' &lt;' + email + '&gt;';
            }

            return email;
        }.property('name', 'email')
    });
});