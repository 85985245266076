define('huyang-common/mixins/tracked', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Mixin.create({
        extractId: function extractId() {
            // url looks like /settings/buildings/110/request-types
            // pull out id and send as metadata
            var url = this.get('url');
            var ids = url.match(/\d+/);
            var label = ids && ids.length ? ids[0] : null;
            url = url.replace(/\d+/g, ':id');
            url = url.replace(/token\/.*/, 'token');
            var accountId = localStorage.getItem('accountId') || '';
            return {
                url: accountId + url,
                label: label,
                location: document.location.origin + url
            };
        },

        trackPageview: function () {
            var config = Ember.getOwner(this).resolveRegistration('config:environment');
            if (!config.GOOGLE_ANALYTICS_ENABLED) {
                return;
            }
            var data = this.extractId();
            ga('set', 'location', data.location); // without token
            ga('set', 'dimension1', localStorage.getItem('accountId') || '');
            ga('set', 'userId', localStorage.getItem('userId') || '');
            ga('send', 'pageview', data.label, {
                'page': data.url,
                'title': window.location.hostname + data.url
            });
        }.on('didTransition'),

        actions: {
            trackTab: function trackTab(name) {
                var config = Ember.getOwner(this).resolveRegistration('config:environment');
                if (!config.GOOGLE_ANALYTICS_ENABLED) {
                    return;
                }
                console.log('track tab ' + name);
                ga('send', 'event', 'tab-click', name);
            }
        }
    });
});