define('huyang-common/mixins/renders-repeat-text', ['exports', 'huyang-common/utils/rrule-utils'], function (exports, _rruleUtils) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Mixin.create({
        session: Ember.inject.service(),

        renderedRepeatText: function renderedRepeatText(schedule) {
            var _ref = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
                lowercased = _ref.lowercased;

            if (!schedule) {
                return;
            }

            var timezone = this.get('session.currentUser.timezone');
            var repeatRule = schedule.get('repeatRule');
            var afterClose = schedule.get('afterClose');

            if (afterClose) {
                if (afterClose.indexOf('1 ') === 0) {
                    afterClose = afterClose.replace(/s$/, '');
                }
                return afterClose + ' after the previous request is closed';
            }

            // Use nextCreate rather than start, to avoid handling start dates in past
            var repeatText = (0, _rruleUtils.rruleToText)(repeatRule, schedule.get('nextCreate'), timezone);

            if (lowercased) {
                return repeatText ? repeatText[0].toLowerCase() + repeatText.slice(1) : null;
            }
            return repeatText;
        }
    });
});