define('huyang-common/components/set-request-type-info', ['exports', 'huyang-common/templates/components/set-request-type-info'], function (exports, _setRequestTypeInfo) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        classNames: ['set-request-type-info'],
        layout: _setRequestTypeInfo.default,

        setup: function () {
            this.set('editing', false);
        }.on('didInsertElement'),

        isEditing: function () {
            return this.get('editing');
        }.property('editing'),

        usesLabel: function () {
            var it = this.get('requestType');

            if (it) {
                var uses = it.get('uses');
                var label = this.get('requestType.label');

                var isAr = uses === 1 ? 'is' : 'are';
                var pluralized = uses === 1 ? 'request' : 'requests';

                return 'There ' + isAr + ' ' + uses + ' ' + pluralized + ' filed as ' + label + '. This type will be deactivated so no more ' + label + ' requests can be filed.';
            }

            return '';
        }.property('requestType.uses'),

        actions: {
            edit: function edit() {
                var _this = this;

                if (!this.get('editing')) {
                    this.set('editing', true);
                    setTimeout(function () {
                        _this.$('input').select();

                        _this.$('input').on('keyup', function (e) {
                            if (e.keyCode === 27) {
                                // escape key
                                _this.send('cancelEdit');
                            }
                        });
                    }, 100);
                }
            },

            cancelEdit: function cancelEdit() {
                try {
                    this.$('input').off('keyup');
                } catch (err) {}
                this.set('editing', false);
                this.get('requestType').rollbackAttributes();
            },

            save: function save() {
                this.get('requestType').save();
                this.set('editing', false);
            },

            remove: function remove() {
                var it = this.get('requestType');

                if (it.get('uses')) {
                    this.$('#' + this.get('requestType.id')).modal('show');
                } else {
                    it.deleteRecord();
                    it.save();
                    this.set('editing', false);
                }
            },

            activate: function activate() {
                var it = this.get('requestType');
                it.set('active', true);
                it.save();
                this.set('editing', false);
            },

            deactivate: function deactivate() {
                var rt = this.get('requestType');
                rt.set('active', false);
                rt.save();
                this.set('editing', false);
                this.$('#' + this.get('requestType.id')).modal('hide');
            }
        }
    });
});