define('huyang-common/mixins/version-check', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Mixin.create({

        api: Ember.inject.service(),

        checkVersion: function checkVersion() {
            var due = localStorage.getItem('versionCheckDue');
            var now = moment();
            if (due && moment(due, 'X').isAfter(now)) {
                // not expired yet
                return;
            }
            // get deployed version
            this.get('api').ajaxWithoutAuth({ url: '/build.txt' }).then(function (response) {
                var availableBuild = response.trim();
                var build = localStorage.getItem('build');
                now.add(1, 'days');
                localStorage.setItem('versionCheckDue', now.format('X'));
                if (!build) {
                    // first load
                    localStorage.setItem('build', availableBuild);
                    return;
                }
                if (build !== availableBuild) {
                    localStorage.removeItem('build');
                    location.reload();
                }
            });
        }
    });
});