define('huyang-common/models/time-log', ['exports', 'ember-data', 'ember-changeset', 'ember-changeset-validations', 'huyang-common/utils/time', 'huyang-common/validations/time-log'], function (exports, _emberData, _emberChangeset, _emberChangesetValidations, _time, _timeLog) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.secondsToNow = exports.cacheElapsed = exports.castChangeset = undefined;

    var _slicedToArray = function () {
        function sliceIterator(arr, i) {
            var _arr = [];
            var _n = true;
            var _d = false;
            var _e = undefined;

            try {
                for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
                    _arr.push(_s.value);

                    if (i && _arr.length === i) break;
                }
            } catch (err) {
                _d = true;
                _e = err;
            } finally {
                try {
                    if (!_n && _i["return"]) _i["return"]();
                } finally {
                    if (_d) throw _e;
                }
            }

            return _arr;
        }

        return function (arr, i) {
            if (Array.isArray(arr)) {
                return arr;
            } else if (Symbol.iterator in Object(arr)) {
                return sliceIterator(arr, i);
            } else {
                throw new TypeError("Invalid attempt to destructure non-iterable instance");
            }
        };
    }();

    var computed = Ember.computed,
        observer = Ember.observer;


    /**
     * Since these need to operate on changesets, they obviously
     * cannot exist on the model instance itself.
     * Exporting solely to test them.
     */

    var castChangeset = exports.castChangeset = function castChangeset(changeset) {
        var hours = changeset.get('hours');
        var minutes = changeset.get('minutes');

        if (hours) {
            changeset.set('hours', Number(hours));
        }
        if (minutes) {
            changeset.set('minutes', Number(minutes));
        }

        return changeset;
    };

    var cacheElapsed = exports.cacheElapsed = function cacheElapsed(obj) {
        if (!obj.get('active')) {
            return obj;
        }

        var totalSeconds = secondsToNow(obj);

        var _elapsedToTimer = (0, _time.elapsedToTimer)(totalSeconds),
            _elapsedToTimer2 = _slicedToArray(_elapsedToTimer, 3),
            hours = _elapsedToTimer2[0],
            minutes = _elapsedToTimer2[1],
            seconds = _elapsedToTimer2[2];

        var totalHours = (obj.get('hours') || 0) + hours;
        var totalMinutes = (obj.get('minutes') || 0) + minutes;

        var props = {
            active: false,
            startTime: null,
            seconds: (obj.get('seconds') || 0) + seconds
        };

        // Do not set hours/minutes if they are 0, or it will trigger
        // validation on form opening which is just bad UX... Seconds
        // are not validated, so who cares
        if (totalHours) {
            props.hours = totalHours;
        }
        if (totalMinutes) {
            props.minutes = totalMinutes;
        }

        // For some reason, this returns a POJO rather than an Ember object
        // which was causing failures on Ember 2 upgrade
        // return obj.setProperties(props);

        obj.set('active', props.active);
        obj.set('startTime', props.startTime);
        obj.set('seconds', props.seconds);
        obj.set('hours', props.hours);
        obj.set('minutes', props.minutes);

        return obj;
    };

    var secondsToNow = exports.secondsToNow = function secondsToNow(obj) {
        var startTime = obj.get('startTime');

        // This is really, really dumb, but computed properties can somehow get called
        // before the object is properly serialized, meaning startTime will be a
        // string instead of a date... Ugh Ember
        if (startTime.constructor !== Date) {
            startTime = new Date(startTime);
        }

        return Math.floor((new Date() - startTime) / 1000);
    };

    var TimeLog = _emberData.default.Model.extend({
        account: _emberData.default.belongsTo('account', { async: true }),
        request: _emberData.default.belongsTo('request', { async: true }),
        logger: _emberData.default.belongsTo('user'),
        worker: _emberData.default.belongsTo('user'),
        created: _emberData.default.attr('date'),
        memo: _emberData.default.attr('string'),
        billable: _emberData.default.attr('boolean'),

        // For timers, active: false indicates a paused timer
        active: _emberData.default.attr('boolean'),
        // Whether a time log started as a timer
        timer: _emberData.default.attr('boolean'),
        // Whether the elapsed field was set manually (as a log or a timer that is edited)
        manualTime: _emberData.default.attr('boolean'),
        // When a timer is started or resumed
        startTime: _emberData.default.attr('date'),
        // When a timer is saved or the end time of a manual log
        endTime: _emberData.default.attr('date'),
        // For paused/logged records, caching amount of time elapsed
        hours: _emberData.default.attr('number'),
        minutes: _emberData.default.attr('number'),
        seconds: _emberData.default.attr('number'),

        /**
         * On 'init' required for active timers loaded from store.
         * Observe 'active' for new timers created (will init before getting attrs),
         * or for pausing/resuming.
         */
        startTimer: Ember.on('init', observer('active', function () {
            var _this = this;

            var _interval = 'timerIntervalId';

            if (this.get('active')) {
                var interval = window.setInterval(function () {
                    _this.notifyPropertyChange('currentTime');
                }, 1000);

                this.set(_interval, interval);
            } else if (this.get(_interval)) {
                window.clearInterval(this.get(_interval));
                this.set(_interval, null);
            }
        })),

        /**
         * Paused timers simply need to pull cached hours, etc, but active timers
         * will have to add duration from latest startTime.
         */
        currentTime: computed('active', function () {
            var hours = this.get('hours') || 0;
            var minutes = this.get('minutes') || 0;
            var seconds = this.get('seconds') || 0;

            if (this.get('active')) {
                seconds += secondsToNow(this);
            }

            return (0, _time.timerString)(hours, minutes, seconds, { full: true });
        }),

        toggleActive: function toggleActive() {
            if (this.get('active')) {
                return this.pauseTimer();
            }

            return this.resumeTimer();
        },
        pauseTimer: function pauseTimer() {
            if (this.get('active')) {
                cacheElapsed(this);
                return this.save();
            }

            return this;
        },
        resumeTimer: function resumeTimer() {
            if (this.get('active')) {
                return this;
            }

            this.setProperties({
                active: true,
                startTime: new Date()
            });
            return this.save();
        },
        isTimerForUser: function isTimerForUser(user) {
            return this.get('worker.id') === user.get('id') && this.get('timer') && !this.get('endTime');
        },


        /**
         * Return true if a user other than the worker added the log
         */
        otherLogger: computed('logger.id', function () {
            return this.get('logger.id') !== this.get('worker.id');
        }),

        changeset: function changeset() {
            var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

            // Need to pass in all three (content, validatorFn, validationMap), even though
            // documentation just shows first two args...
            // See https://github.com/DockYard/ember-changeset/issues/202
            var changeset = new _emberChangeset.default(this, (0, _emberChangesetValidations.default)(_timeLog.default), _timeLog.default);

            changeset.setProperties(props);

            // These will be cast to ints on creating/saving a model, but in between creating
            // and receiving a 200 OK on model save, it causes request.loggedTime to output an
            // invalid string, so just cast these automatically on saving changeset
            changeset._save = changeset.save;
            changeset.save = function () {
                return castChangeset(this)._save();
            };

            // Logging an active/paused timer requires setting an end time, but editing
            // an existing one should not change the time.. Set to inactive via cacheElapsed
            if (!changeset.get('endTime')) {
                changeset.set('endTime', new Date());
            }

            return cacheElapsed(changeset);
        },


        iconClass: computed('active', function () {
            return this.get('active') ? 'pause-circle-o' : 'play-circle-o';
        }),

        buttonClass: computed('active', function () {
            return this.get('active') ? 'success' : 'default';
        })
    });

    TimeLog.reopenClass({
        changeset: function changeset() {
            var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
                account = _ref.account,
                request = _ref.request,
                logger = _ref.logger,
                worker = _ref.worker;

            var changeset = new _emberChangeset.default({
                account: account,
                request: request,
                logger: logger,
                worker: worker || logger,
                created: new Date(),
                endTime: moment().startOf('minute').toDate()
            }, (0, _emberChangesetValidations.default)(_timeLog.default), _timeLog.default);

            changeset._save = changeset.save;
            changeset.save = function () {
                return castChangeset(this)._save();
            };

            return changeset;
        }
    });

    exports.default = TimeLog;
});