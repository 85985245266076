define('huyang-common/components/form-group', ['exports', 'huyang-common/templates/components/form-group'], function (exports, _formGroup) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        layout: _formGroup.default,
        classNames: ['form-group'],

        isRequired: function () {
            var v = this.get('validations');
            var name = this.get('name');

            if (v && name && v.validations[name]) {
                return v.validations[name].presence || v.validations[name].numericality;
            }

            return false;
        }.property('validations', 'name'),

        errors: function () {
            var v = this.get('validations');
            var name = this.get('name');

            if (v && name && v.errors && Ember.isArray(v.errors.get(name))) {
                return v.errors.get(name);
            }

            return [];
        }.property('validations.errors', 'name')
    });
});