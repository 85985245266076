define('huyang-common/components/priority-string', ['exports', 'huyang-common/templates/components/priority-string', 'huyang-common/mixins/displays-priority'], function (exports, _priorityString, _displaysPriority) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_displaysPriority.default, {
        layout: _priorityString.default,
        tagName: 'span',
        priority: Ember.computed.alias('request.priority'),
        classNames: ['priority-string']
    });
});