define('huyang-common/models/account', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var Account = _emberData.default.Model.extend({
        name: _emberData.default.attr('string'),
        status: _emberData.default.attr('string'),
        subdomain: _emberData.default.attr('string'),
        owner: _emberData.default.belongsTo('user', { async: false }),
        logoUuid: _emberData.default.attr('string'),
        api: Ember.inject.service(),
        occupantConfig: _emberData.default.attr(),
        requestTypes: _emberData.default.hasMany('request-type', { async: false }),
        costCategories: _emberData.default.hasMany('cost-category', { async: false }),
        features: _emberData.default.attr(),
        assets: _emberData.default.attr('boolean'),

        inactiveMessageStaff: _emberData.default.attr('string'),
        inactiveMessageTenants: _emberData.default.attr('string'),
        inactiveMessageVendors: _emberData.default.attr('string'),

        activeCostCategories: Ember.computed.filterBy('costCategories', 'active', true),

        logoUrl: function () {
            var config = Ember.getOwner(this).resolveRegistration('config:environment');
            var baseUrl = config.IMGIX_BASE_URL || config.S3_BASE_URL;

            return baseUrl + '/' + this.get('id') + '/' + this.get('logoUuid');
        }.property('logoUuid'),

        logoBackgroundStyle: function () {
            var url = this.get('logoUuid') ? this.get('logoUrl') : '/img/default-logo.png';
            return new Ember.String.htmlSafe("background-image: url('" + url + "')");
        }.property('logoUuid'),

        createOccupants: function createOccupants(emails, buildingId, requestId) {
            var data = {
                emails: emails,
                building_id: buildingId,
                request_id: requestId
            };
            return this.get('api').ajax({
                type: 'POST',
                url: '/api/' + this.get('id') + '/accounts/' + this.get('id') + '/occupants',
                data: JSON.stringify(data),
                dataType: 'json'
            });
        },

        hasFeature: function hasFeature(feature) {
            // everything available in dev
            var config = Ember.getOwner(this).resolveRegistration('config:environment');
            if (config.environment === 'development' || config.environment === 'test') {
                return true;
            }
            var features = this.get('features') || [];
            return features.indexOf(feature) >= 0;
        },

        hasLogo: function () {
            return !Ember.isBlank(this.get('logoUuid'));
        }.property('logoUuid')
    });
    exports.default = Account;
});