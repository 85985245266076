define('huyang-common/components/notifications-table', ['exports', 'huyang-common/templates/components/notifications-table'], function (exports, _notificationsTable) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        layout: _notificationsTable.default,
        classNames: ['notifications-table'],

        email: 1,
        sms: 2,

        defaultThreshold: 3,

        notifications: [],

        labels: {
            header: 'Notify me when',
            newRequest: 'New request arrives',
            assign: 'Request is assigned to me',
            comment: 'Comment is made on a request I follow',
            closed: 'Request I follow is closed or re-opened',
            due: 'My request is due soon',
            timer: 'I have an active timer at the end of the day'
        },

        isSet: function isSet(eventType, method) {
            var val = this.get('user.notify' + eventType) || 0;
            return val & this.get(method);
        },

        newEmail: function () {
            return this.isSet('New', 'email');
        }.property('user.notifyNew'),

        newSms: function () {
            return this.isSet('New', 'sms');
        }.property('user.notifyNew'),

        disableNewSmsRecur: function () {
            return !this.isSet('New', 'sms');
        }.property('user.notifyNew'),

        assignEmail: function () {
            return this.isSet('Assign', 'email');
        }.property('user.notifyAssign'),

        assignSms: function () {
            return this.isSet('Assign', 'sms');
        }.property('user.notifyAssign'),

        disableAssignSmsRecur: function () {
            return !this.isSet('Assign', 'sms');
        }.property('user.notifyAssign'),

        closedEmail: function () {
            return this.isSet('Closed', 'email');
        }.property('user.notifyClosed'),

        closedSms: function () {
            return this.isSet('Closed', 'sms');
        }.property('user.notifyClosed'),

        disableClosedSmsRecur: function () {
            return !this.isSet('Closed', 'sms');
        }.property('user.notifyClosed'),

        commentEmail: function () {
            return this.isSet('Comment', 'email');
        }.property('user.notifyComment'),

        commentSms: function () {
            return this.isSet('Comment', 'sms');
        }.property('user.notifyComment'),

        disableCommentSmsRecur: function () {
            return !this.isSet('Comment', 'sms');
        }.property('user.notifyComment'),

        dueEmail: function () {
            return this.isSet('Due', 'email');
        }.property('user.notifyDue'),

        dueSms: function () {
            return this.isSet('Due', 'sms');
        }.property('user.notifyDue'),

        disableDueSmsRecur: function () {
            return !this.isSet('Due', 'sms');
        }.property('user.notifyDue'),

        productEmail: function () {
            return this.isSet('Product', 'email');
        }.property('user.notifyProduct'),

        timerEmail: function () {
            return this.isSet('Timer', 'email');
        }.property('user.notifyTimer'),

        smsThresholds: function () {
            return this.get('user.smsThresholds');
        }.property('user.smsThresholds'),

        smsRecurring: function () {
            return this.get('user.smsRecurring') || { 'new': false, assign: true, comment: true, closed: true };
        }.property('user.smsRecurring'),

        productSms: function () {
            return false;
        }.property(),

        setChecked: function () {
            var _this = this;

            var thresholds = this.get('user.smsThresholds') || {};
            var recurring = this.get('user.smsRecurring') || {};

            this.set('user.smsThresholds', thresholds);
            this.set('user.smsRecurring', recurring);

            ['New', 'Assign', 'Product', 'Comment', 'Due', 'Closed'].forEach(function (notifType) {
                var key = notifType.toLowerCase();

                if (!thresholds[key]) {
                    _this.set('user.smsThresholds.' + key, _this.get('defaultThreshold'));
                }

                ['Email', 'Sms'].forEach(function (method) {
                    _this.set(notifType.toLowerCase() + method, _this.isSet(notifType, method.toLowerCase()));
                });
            });
        }.observes('user.notifyProduct', 'user.notifyNew', 'user.notifyAssign', 'user.notifyComment', 'user.notifyClosed', 'user.notifyDue').on('didInsertElement'),

        showNew: function () {
            return !this.get('notifications.length') || this.get('notifications').includes('new');
        }.property('notifications.[]'),

        showAssign: function () {
            return !this.get('notifications.length') || this.get('notifications').includes('assign');
        }.property('notifications.[]'),

        showProduct: function () {
            return !this.get('notifications.length') || this.get('notifications').includes('product');
        }.property('notifications.[]'),

        showComment: function () {
            return !this.get('notifications.length') || this.get('notifications').includes('comment');
        }.property('notifications.[]'),

        showClosed: function () {
            return !this.get('notifications.length') || this.get('notifications').includes('closed');
        }.property('notifications.[]'),

        showDue: function () {
            return this.get('notifications.length') && this.get('notifications').includes('due');
        }.property('notifications.[]'),

        showTimer: function () {
            return !this.get('notifications.length') || this.get('notifications').includes('timer');
        }.property('notifications.[]'),

        defaultNotifications: function () {
            return Ember.Object.create({
                notifyNew: 1,
                notifyAssign: 1,
                notifyProduct: 1,
                notifyComment: 1,
                notifyClosed: 1,
                notifyDue: 1,
                notifyTimer: 1
            });
        }.property(),

        actions: {
            check: function check(value, checked) {
                // eventType notifType (New Email)
                var parts = value.split(' ');
                var eventType = parts[0];
                var notifType = parts[1];
                var user = this.get('user');
                var prevEmail = this.isSet(eventType, 'email');
                var prevSms = this.isSet(eventType, 'sms');
                var notifValue = user.get('notify' + eventType) || 0;
                if (eventType === 'Recur') {
                    // set user.smsRecurring.[new|assign|closed|comment] = value
                    this.sendAction('action', 'smsRecurring.' + notifType, checked);
                    return;
                }
                if (notifType === 'Email') {
                    if (prevEmail !== checked) {
                        notifValue += prevEmail ? -this.get('email') : this.get('email');
                    }
                } else if (notifType === 'Sms') {
                    if (prevSms !== checked) {
                        notifValue += prevSms ? -this.get('sms') : this.get('sms');
                    }
                }

                console.log('check', eventType, notifType, notifValue);
                this.sendAction('action', 'notify' + eventType, notifValue);
            }
        }
    });
});