define('huyang-common/components/action-checkbox', ['exports', 'huyang-common/templates/components/action-checkbox'], function (exports, _actionCheckbox) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        layout: _actionCheckbox.default,
        attributeBindings: ['type', 'value', 'checked', 'disabled'],
        tagName: 'input',
        type: 'checkbox',
        checked: false,
        disabled: false,

        _updateElementValue: function () {
            this.set('checked', this.$().prop('checked'));
        }.on('didInsertElement'),

        change: function change() /*event*/{
            this._updateElementValue();
            this.sendAction('action', this.get('value'), this.get('checked'));
        }
    });
});