define('huyang-common/serializers/tenant-user', ['exports', 'huyang-common/serializers/rest-new'], function (exports, _restNew) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _restNew.default.extend({
        serialize: function serialize(snapshot, options) {
            var data = this._super(snapshot, options);
            // add grants if present (not part of the the tenantUser model)
            // so API can add grants before notifying
            if (snapshot.record.grants && snapshot.record.grants.length) {
                data.grants = snapshot.record.grants;
            }
            return data;
        }
    });
});