define('huyang-common/components/time-input', ['exports', 'huyang-common/templates/components/time-input'], function (exports, _timeInput) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var _time = 'fieldValue';

    exports.default = Ember.Component.extend({
        layout: _timeInput.default,
        tagName: 'span',
        classNames: ['time-input'],

        step: 1, // Minutes to step by in dropdown

        didReceiveAttrs: function didReceiveAttrs() {
            this._super.apply(this, arguments);

            var time = this.get(_time) || 17;
            var periodTime = time >= 12 ? time - 12 : time;
            var selectedHour = Math.floor(periodTime);
            var selectedMinute = Math.floor((periodTime - selectedHour) * 60);
            var selectedPeriod = time >= 12 ? 'pm' : 'am';

            var step = this.get('step');
            var hoursOptions = [];
            var minutesOptions = [];

            for (var i = 0; i < 60; i++) {
                if (i < 12) {
                    // When it comes to 12, the 'actual' hour needs to be 0
                    var label = i === 0 ? 12 : i;
                    hoursOptions.push({ value: i, label: label });
                }

                if (i % step === 0) {
                    // Minutes need to be zero-padded if necessary
                    var _label = i < 10 ? '0' + i : '' + i;
                    minutesOptions.push({ value: i, label: _label });
                }
            }

            this.setProperties({
                selectedHour: selectedHour,
                selectedMinute: selectedMinute,
                selectedPeriod: selectedPeriod,
                hoursOptions: hoursOptions,
                minutesOptions: minutesOptions
            });
        },


        what: Ember.observer(_time, function () {
            console.log(this.get(_time));
        }),

        setTime: function setTime(hour12, minute, period) {
            var hour24 = period === 'am' ? hour12 : hour12 + 12;
            this.set(_time, hour24 + minute / 60);
        },


        actions: {
            updateHour: function updateHour(hourString) {
                var hour = Number(hourString);

                this.set('selectedHour', hour);
                this.setTime(hour, this.get('selectedMinute'), this.get('selectedPeriod'));
            },
            updateMinute: function updateMinute(minuteString) {
                var minute = Number(minuteString);

                this.set('selectedMinute', minute);
                this.setTime(this.get('selectedHour'), minute, this.get('selectedPeriod'));
            },
            updatePeriod: function updatePeriod(period) {
                this.set('selectedPeriod', period);
                this.setTime(this.get('selectedHour'), this.get('selectedMinute'), period);
            }
        }
    });
});