define('huyang-common/serializers/user', ['exports', 'huyang-common/serializers/rest-new'], function (exports, _restNew) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _restNew.default.extend({
        serializeAttribute: function serializeAttribute(snapshot, json, key, attributes) {
            if (_.contains(snapshot.record.serializeKeys(), key) || key === 'password') {
                return this._super(snapshot, json, key, attributes);
            }
        }
    });
});