define('huyang-common/mixins/creates-vendors', ['exports', 'ember-validations'], function (exports, _emberValidations) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Mixin.create({
        api: Ember.inject.service(),
        intlTelInput: Ember.inject.service(),
        store: Ember.inject.service(),

        vendor: {},

        // for some reason, using the validates-email mixin here borks the tests
        emailValidations: {
            presence: { message: 'Email must be present' },
            format: {
                with: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                allowBlank: false,
                message: 'Email must be valid'
            }
        },

        tradeOptions: function () {
            return this.get('trades').map(function (trade) {
                return Ember.Object.create({
                    id: trade.get('id'),
                    label: trade.get('label')
                });
            });
        }.property('trades.[]'),

        formFields: function () {
            var controller = this;
            return [{
                name: 'email',
                value: this.get('vendor.email'),
                label: 'Email address',
                placeholder: 'service@acmeplumbing.com',
                validations: this.emailValidations
            }, {
                name: 'name',
                value: this.get('vendor.name'),
                label: 'Vendor name',
                placeholder: 'ACME Plumbing',
                validations: { presence: true }
            }, {
                name: 'trade',
                label: 'Primary trade',
                component: 'vendor-trade-select',
                properties: {
                    trades: this.get('trades'),
                    value: this.get('vendor.trade')
                },
                action: 'valuesChanged'
            }, {
                name: 'mobilePhone',
                label: 'Mobile phone',
                component: 'huyang-form-phone',
                properties: Ember.copy({
                    phone: this.get('vendor.phone')
                }),
                validations: {
                    inline: (0, _emberValidations.validator)(function () {
                        var validation = controller.get('intlTelInput').validate();

                        var errors = {};
                        if (validation.error) {
                            errors.mobilePhone = validation.error;
                            this.errors.pushObject(errors.mobilePhone);
                        }
                    })
                }
            }];
        }.property('tradeOptions.[]', 'vendor.name', 'vendor.phone', 'vendor.email', 'vendor.trade'),

        lookupEmail: function lookupEmail(email) {
            var _this = this;

            // pre-fill the email address on the vendor form
            this.set('vendor.email', email);

            return this.get('api').ajax({
                type: 'GET',
                dataType: 'json',
                url: '/api/vendor_query?email=' + email
            }).then(function (response) {
                if (response.company) {
                    // set vendor form info
                    var company = response.company;

                    var vendorData = {
                        email: email,
                        name: company.name,
                        location: company.location,
                        phone: company.phone || company.site.phoneNumbers[0]
                    };

                    _this.set('vendor.name', vendorData.name);
                    _this.set('vendor.location', vendorData.location);
                    _this.set('vendor.phone', vendorData.phone);
                    _this.set('vendor.searchResults', response);

                    _this.send('updateVendorData', vendorData);
                }
            }, function (error) {
                console.error(error);
            });
        },

        saveVendor: function saveVendor(vendorInfo) {
            var _this2 = this;

            var store = this.get('store');

            var accountId = localStorage.getItem('accountId');
            var account = store.peekRecord('account', accountId);

            vendorInfo.account = account;
            vendorInfo.primaryTrade = store.peekRecord('trade', vendorInfo.trade);

            var iti = this.get('intlTelInput.iti');
            if (!vendorInfo.phone) vendorInfo.phone = iti ? iti.getNumber() : '';

            return store.query('user', { email: vendorInfo.email }).then(function (users) {
                return users.get('firstObject');
            }).then(function (user) {
                if (user) {
                    user.set(name, vendorInfo.name);
                } else {
                    user = store.createRecord('user', {
                        name: vendorInfo.name,
                        email: vendorInfo.email
                    });
                }
                return user.save();
            }).then(function (user) {
                user.addRole(account, 'vendor');
                vendorInfo.primaryUser = user;
                return _this2.get('store').createRecord('vendor', vendorInfo).save();
            });
        },

        actions: {
            updateVendorData: function updateVendorData() {
                // this should be implemented by consuming controllers
            }
        }
    });
});