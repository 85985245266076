define('huyang-common/overrides/route', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.reopen({
        config: Ember.computed(function () {
            return Ember.getOwner(this).resolveRegistration('config:environment');
        }),

        afterModel: function afterModel(model) {
            this._super();

            /*
                improves usability by automatically scrolling to the top
                when the route is loaded
            */

            if (!this.get('disableAutoScrollToTop')) {
                this.scrollToTop();
            }

            /*
                automatically set the document title if the route has
                documentTitle defined, or if the model has a title or name field
            */

            var Config = this.get('config');

            var titleFormat = Config.titleFormat;
            var titleSuffix = Config.defaultTitle;

            if (titleFormat && titleSuffix) {
                var title = void 0;

                var modelTitleProps = ['title', 'name'];
                var isModel = Ember.typeOf(model) === 'instance';

                if (this.get('documentTitle')) {
                    title = this.get('documentTitle');
                } else if (isModel) {
                    modelTitleProps.forEach(function (prop) {
                        if (!title) {
                            title = model.get(prop);
                        }
                    });
                } else if (model.request) {
                    title = '#' + model.request.get('workOrder') + ' - ' + model.request.get('title');
                }

                if (title) {
                    document.title = title + ' | ' + titleSuffix;
                } else {
                    document.title = titleSuffix;
                }

                var label = Config.ENV_LABEL;
                var icons = {
                    'local': '🔴',
                    'dev': '🔵',
                    'dev2': '🔷',
                    'stage': '🔶'
                };

                if (icons[label]) {
                    document.title = icons[label] + ' ' + document.title;
                }
            }
        },

        scrollToTop: function scrollToTop() {
            window.scrollTo(0, 0);
        }
    });
});