define('huyang-common/components/loading-spinner', ['exports', 'huyang-common/templates/components/loading-spinner'], function (exports, _loadingSpinner) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _loadingSpinner.default,
    message: 'Loading...'
  });
});