define('huyang-common/helpers/value-for-key', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.valueForKey = valueForKey;
    function valueForKey(params) {
        var item = params[0];
        var key = params[1];
        var html = params.length > 2 && params[2];
        var emptyText = params.length > 3 ? params[3] : '';
        var limit = params.length > 4 ? params[4] : null;
        var format = params.length > 5 ? params[5] : '%@';

        var output = item ? item.get(key) : '';
        if (!item) {
            console.log('undefined item');
            console.trace();
        }
        if (output && typeof format === 'string') {
            output = format.replace('%@', output);
        }

        if (typeof output === 'string') {
            if (limit) {
                var ellipsis = '';

                if (output.length > limit) {
                    ellipsis = '...';
                }

                output = output.substr(0, limit) + ellipsis;
            }
        }

        if (output) {
            return html ? Ember.String.htmlSafe(output) : output;
        } else if (emptyText) {
            return emptyText;
        }

        return '';
    }

    exports.default = Ember.Helper.helper(valueForKey);
});