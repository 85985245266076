define('huyang-common/lib/field', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Object.extend({
        errors: [],

        example: function () {
            return 'ex: ' + this.get('placeholder');
        }.property('placholder'),

        isRequired: function () {
            return this.get('required') || this.get('validations.presence');
        }.property('required', 'validations.presence'),

        textAreaRows: function () {
            return this.get('rows') ? this.get('rows') : 4;
        }.property('rows'),

        hasErrors: function () {
            return this.get('errors.length') > 0;
        }.property('errors.length', 'errors.[]')
    });
});