define('huyang-common/validators/technician', ['exports', 'ember-validations/validators/base'], function (exports, _base) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _base.default.extend({
        call: function call() {
            var email = this.model.get('email');
            var vendorId = this.options.vendorId;
            var message = this.options.message || 'Technician emails must be unique';

            // not set yet or not valid; do nothing
            if (_.isUndefined(email) || !email.match(/.+@.+\..+/)) {
                return;
            }

            // ok to keep current value
            if (this.options.current && this.options.current === email) {
                return;
            }

            if (!vendorId) {
                throw new Error('Technician cannot be validated without providing a vendor');
            }

            var exists = this.get('api').ajax({
                url: '/api/technician_email_exists',
                async: false,
                data: { vendor_id: vendorId, email: email }
            }).responseText;

            if (exists === 'true') {
                this.errors.pushObject(message);
            }
        }
    });
});