define('huyang-common/components/ladda-button', ['exports', 'huyang-common/templates/components/ladda-button'], function (exports, _laddaButton) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        layout: _laddaButton.default,
        tagName: 'span',
        classNames: ['ladda-button-wrapper'],

        btnClass: 'btn-success',
        btnStyle: 'slide-left',

        spinnerColor: function () {
            if (this.get('btnClass') === 'btn-default') {
                return '#888';
            }

            return '#fff';
        }.property('btnClass'),

        setup: function () {
            this.button = this.$('.ladda-button').ladda();

            // .ladda 'start' and 'stop' mess with button[disabled], which is problematic
            // on insert because then setLoading will clear it... So need to manually set
            // to desired state on initial insert element
            var disabled = this.button[0].disabled;

            this.setLoading();
            this.button[0].disabled = disabled;
        }.on('didInsertElement'),

        setLoading: function () {
            if (this.button) {
                if (this.get('loading')) {
                    this.button.ladda('start');
                } else {
                    this.button.ladda('stop');
                }
            }
        }.observes('loading'),

        actions: {
            click: function click() {
                if (!this.get('loading')) {
                    this.sendAction();
                }
            }
        }
    });
});