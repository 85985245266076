define('huyang-common/components/set-notifications', ['exports', 'huyang-common/templates/components/set-notifications'], function (exports, _setNotifications) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        layout: _setNotifications.default,
        actions: {
            saveButtonClicked: function saveButtonClicked() {
                var _this = this;

                this.set('isLoading', true);
                this.get('user').save().then(function () {
                    _this.set('isLoading', false);
                    _this.get('flashMessages').success('Changes saved!');
                });
            },
            cancel: function cancel() {
                this.get('user').rollbackAttributes();
            },
            check: function check(eventType, value) {
                this.set('user.' + eventType, value);
            },
            setPriorityThreshold: function setPriorityThreshold(eventType, threshold) {
                var thresholds = this.get('user.smsThresholds');
                thresholds[eventType] = threshold;

                this.set('user.smsThresholds', thresholds);
            }
        }
    });
});