define('huyang-common/mixins/header-navigation-route', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Mixin.create({
        navigationModel: function navigationModel(options) {
            var _this = this;

            var context = options.queryParams;

            this.set('requestIds', context.requestIds);
            this.set('previousRoute', context.previousRoute);
            this.set('fromId', context.fromId);

            if (context.previousModelId) {
                this.set('previousModelId', context.previousModelId);
            } else {
                this.set('previousModelId', undefined);
            }

            this.set('currentPage', context.currentPage ? parseInt(context.currentPage) : 1);
            this.set('totalPages', context.totalPages ? parseInt(context.totalPages) : 1);
            this.set('filter', context.filter);
            this.set('perPage', context.perPage ? parseInt(context.perPage) : 50);
            this.set('sortColumn', context.sortColumn);
            this.set('sortDirection', context.sortDirection);
            this.set('paramNames', context.paramNames);
            // arbitrary query params; list in context.paramNames
            if (context.paramNames) {
                context.paramNames.split(',').forEach(function (param) {
                    _this.set(param, context[param]);
                });
            }
        },
        navigationSetupController: function navigationSetupController(controller) {
            var _this2 = this;

            controller.set('requestIds', this.get('requestIds'));
            controller.set('previousRoute', this.get('previousRoute'));
            controller.set('fromId', this.get('fromId'));

            if (this.get('previousModelId')) {
                controller.set('previousModelId', this.get('previousModelId'));
            } else {
                controller.set('previousModelId', undefined);
            }
            controller.set('currentPage', this.get('currentPage'));
            controller.set('totalPages', this.get('totalPages'));
            controller.set('filter', this.get('filter'));
            controller.set('perPage', this.get('perPage'));
            controller.set('sortColumn', this.get('sortColumn'));
            controller.set('sortDirection', this.get('sortDirection'));
            controller.set('users', this.modelFor(this.get('parentModel')).users);
            controller.set('requestLabels', this.modelFor(this.get('parentModel')).requestLabels);
            if (this.get('paramNames')) {
                controller.set('paramNames', this.get('paramNames'));
                this.get('paramNames').split(',').forEach(function (param) {
                    controller.set(param, _this2.get(param));
                });
            }
        }
    });
});