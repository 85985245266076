define('huyang-common/mixins/sorts-multiple-columns', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Mixin.create({
        sortColumns: [],

        sortByMultiple: function sortByMultiple(column, addToSort) {
            var columns = this.get('sortColumns');
            var existing = columns.find(function (c) {
                return c[0] === column;
            });

            if (existing) {
                // sequence goes asc, desc, remove
                var direction = existing[1];

                if (direction === 'asc') {
                    var i = columns.indexOf(existing);
                    columns.replace(i, 1, [[column, 'desc']]);
                } else if (direction === 'desc') {
                    columns.removeObject(existing);
                }
            } else if (addToSort === true) {
                columns.pushObject([column, 'asc']);
            } else {
                this.set('sortColumns', [[column, 'asc']]);
            }
        },


        actions: {
            sortBy: function sortBy(column, addToSort) {
                this.sortByMultiple(column, addToSort);
            }
        }
    });
});