define('huyang-common/components/huyang-form', ['exports', 'ember-validations', 'huyang-common/templates/components/huyang-form', 'huyang-common/lib/field', 'huyang-common/mixins/handles-attachments', 'huyang-common/mixins/modal-helper'], function (exports, _emberValidations, _huyangForm, _field, _handlesAttachments, _modalHelper) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_emberValidations.default, _handlesAttachments.default, _modalHelper.default, {
        ajax: Ember.inject.service(),
        intlTelInput: Ember.inject.service(),
        session: Ember.inject.service(),

        layout: _huyangForm.default,
        validations: {},
        values: {},
        isLoading: false,
        inputId: 'requestFileUpload',
        singleUse: false,
        hideDelete: true,

        multiColumn: function () {
            return this.get('narrowFields.length') > 0;
        }.property('narrowFields.length'),

        actions: {
            commitChanges: function commitChanges() {
                var _this = this;

                this.checkValidity().then(function () {
                    if (!_this.get('hasErrors')) {
                        _this.set('isLoading', true);

                        if (!_this.get('hasPendingUploads')) {
                            _this.saveChanges();
                        }
                    }
                }).catch(this.updateErrors.bind(this));
            },
            saveButtonClicked: function saveButtonClicked() {
                this.$('.form').submit();
            },
            cancel: function cancel() {
                this.resetFields();

                if (this.get('model')) {
                    this.get('model').rollbackAttributes();
                }

                this.sendAction('onCancel');
            },
            clickDelete: function clickDelete() {
                this.sendAction('onDelete');
            },
            fileUploaded: function fileUploaded(uuid, data) {
                this.sendAction('fileUploaded', uuid, data);
            },
            uploadFailed: function uploadFailed(r) {
                alert('The upload failed: ' + r);
            },
            accessUpdated: function accessUpdated(hasAllAccess) {
                // Set prop so validator has access
                this.set('hasAllAccess', hasAllAccess);
                this.sendAction('accessUpdated', hasAllAccess);
            },

            locationsUpdated: function locationsUpdated(event, params, selectedValues) {
                this.sendAction('locationsUpdated', event, params, selectedValues);
            },
            personSelected: function personSelected(person) {
                this.sendAction('personSelected', person);
            },
            vendorSelected: function vendorSelected(vendorUser) {
                this.sendAction('vendorSelected', vendorUser);
            },
            userPlusEmailChanged: function userPlusEmailChanged(name, values) {
                this.set(name, values);
                this.values[name] = values;
                this.sendAction('userPlusEmailChanged', values);
            },
            selectChanged: function selectChanged(name, value) {
                this.values[name] = value;
                this.sendAction('selectChanged', name, value);
            },
            prioritySelected: function prioritySelected(value) {
                this.sendAction('prioritySelected', value);
            },
            colorSelected: function colorSelected(value) {
                this.sendAction('colorSelected', value);
            },
            timezoneUpdated: function timezoneUpdated(value) {
                this.sendAction('timezoneUpdated', value);
            },
            buildingsUpdated: function buildingsUpdated(buildings) {
                this.sendAction('locationsUpdated', buildings);
                var locationField = this.get('fieldsObserved').find(function (field) {
                    return field.buildingSelect;
                });
                var self = this;
                // remove error if a building is now selected
                this.validate().catch(function () {
                    if (self.errors && self.errors[locationField.name]) {
                        locationField.set('errors', self.errors[locationField.name]);
                    }
                });
            },
            notificationUpdated: function notificationUpdated(eventType, value) {
                this.sendAction('onNotificationUpdate', eventType, value);
            },
            resetValues: function resetValues() {
                this.resetFields();
            },
            dynamicComponentUpdated: function dynamicComponentUpdated(actionName, data) {
                this.sendAction(actionName, data);
            }
        },

        init: function init() {
            // store the fields so we can reset the form if need be
            this._originalFields = Ember.copy(this.get('fields'));
            this._originalNarrowFields = Ember.copy(this.get('narrowFields'));
            this.values = {};

            // need to inject the validations right away so ember-validation works correctly
            this.setupValidations();
            this._super.apply(this, arguments);
        },

        saveChanges: function saveChanges() {
            var _this2 = this;

            var model = this.get('model');
            var fv = this.get('fieldValues');

            if (model) {
                var changed = [];

                Object.keys(fv).forEach(function (key) {
                    if (fv[key] !== model.get(key)) {
                        changed.push(key);
                    }
                });

                var hfa = this.get('fields').findBy('component', 'huyang-form-address');
                if (hfa) {
                    fv.country = this.$('#hfa-country').val();
                    fv.address1 = this.$('#hfa-address1').val();
                    fv.city = this.$('#hfa-city').val();
                    fv.addressState = this.$('#hfa-state').val();
                    fv.zipCode = this.$('#hfa-zip').val();
                }

                var hfp = this.get('fields').findBy('component', 'huyang-form-phone');
                if (hfp) {
                    fv[hfp.name] = this.get('intlTelInput.iti').getNumber() || this.$('#hfp-phone').val();
                }

                model.setProperties(fv);
                this.set('isLoading', true);

                model.save().then(function () {
                    _this2.onComplete();
                    _this2.sendAction('changesSaved', fv, changed);
                }).catch(this.onError.bind(this));
            } else {
                this.set('isLoading', true);
                this.sendAction('changesSaved', fv, this.onComplete.bind(this), this.onError.bind(this));
            }
        },

        autoSaveChanges: function () {
            if (this.get('autoUpdate')) {
                var fv = this.get('fieldValues');
                this.sendAction('valuesChanged', fv);
            }
        }.observes('fieldsObserved.[]', 'fieldsObserved.@each.value'),

        setupValidations: function setupValidations() {
            // sets the validations object that ember-validations looks for
            var validations = {};
            var fields = (this.get('fields') || []).concat(this.get('narrowFields') || []);
            fields.forEach(function (f) {
                if (f.validations) {
                    validations[f.name] = f.validations;
                }
            });

            this.validations = validations;
        },

        fieldsObserved: function () {
            var _this3 = this;

            var modelId = this.get('model.id');
            var fields = (this.get('fields') || []).concat(this.get('narrowFields') || []);
            return fields.map(function (field, idx) {
                // check for a stored value (this preserves input when the form dynamically changes)
                if (_this3.values[field.name]) {
                    field.value = _this3.values[field.name];
                }

                if (!_.has(field, 'id')) {
                    field.id = modelId ? field.name + modelId : field.name + idx;
                }

                return _field.default.create(field);
            });
        }.property('fields', 'fields.[]', 'fields.@each.name', 'fields.@each.data', 'fields.@each.hide', 'narrowFields', 'narrowFields.[]', 'narrowFields.@each.name', 'narrowFields.@each.data', 'narrowFields.@each.hide'),

        wideFieldsObserved: function () {
            var fields = this.get('fields') || [];
            var names = fields.map(function (field) {
                return field.name;
            });
            return this.get('fieldsObserved').filter(function (field) {
                return names.includes(field.get('name'));
            });
        }.property('fields', 'fields.[]', 'fields.@each.name', 'fields.@each.data', 'fields.@each.hide'),

        narrowFieldsObserved: function () {
            var fields = this.get('narrowFields') || [];
            var names = fields.map(function (field) {
                return field.name;
            });
            return this.get('fieldsObserved').filter(function (field) {
                return names.includes(field.get('name'));
            });
        }.property('narrowFields', 'narrowFields.[]', 'narrowFields.@each.name', 'narrowFields.@each.data', 'narrowFields.@each.hide'),

        attachFiles: function () {
            var fields = this.get('fields') || [];
            return fields.find(function (field) {
                return field.withAttachments;
            });
        }.property('fields', 'fields.[]'),

        fieldValues: function () {
            // returns a handy key/value object
            var fv = {};

            this.get('fieldsObserved').forEach(function (f) {
                if (!f.get('photoUpload') && !f.get('excludeFromSaveData')) {
                    fv[f.get('name')] = f.get('value');
                }
            });

            if (this.get('attachFiles')) {
                fv.attachmentsList = this.get('attachmentsList');
            }

            return fv;
        }.property('fieldsObserved.[]', 'fieldsObserved.@each.value'),

        autoResetSomeFields: function () {
            var _this4 = this;

            Ember.run.once(function () {
                var fields = _this4.get('fieldsWithResetRules');

                if (fields) {
                    fields.forEach(function (f) {
                        f.get('resetFieldsOnChange').forEach(function (name) {
                            if (_this4.values[name]) {
                                delete _this4.values[name];
                            }
                        });
                    });
                }
            });
        }.observes('fieldsObserved.@each.value'),

        fieldsWithResetRules: Ember.computed.filterBy('fieldsObserved', 'resetFieldsOnChange'),

        storeValues: function () {
            var _this5 = this;

            this.get('fieldsObserved').forEach(function (f) {
                var val = f.get('value');

                if (typeof val !== 'undefined') {
                    _this5.values[f.get('name')] = val;
                }
            });
        }.observes('fieldsObserved.@each.value'),

        showRequiredMessage: function () {
            if (this.get('hideRequiredMessage')) {
                return false;
            }

            var hasRequired = false;

            this.get('fieldsObserved').forEach(function (f) {
                if (f.get('isRequired')) {
                    hasRequired = true;
                }
            });

            return hasRequired;
        }.property('fieldsObserved.@each.validations'),

        checkValidity: function checkValidity() {
            Ember.setProperties(this, this.get('fieldValues'));
            return this.validate().then(this.updateErrors.bind(this));
        },

        updateErrors: function updateErrors() {
            var _this6 = this;

            this.get('fieldsObserved').forEach(function (f) {
                var name = f.get('name');
                if (_this6.errors && _this6.errors[name]) {
                    f.set('errors', _this6.errors[name]);
                }
            });
        },

        uploadComplete: function uploadComplete() {
            if (!this.get('hasPendingUploads') && this.get('isLoading')) {
                this.saveChanges();
            }
        },

        hasErrors: function () {
            var hasErrors = false;

            this.get('fieldsObserved').forEach(function (f) {
                if (f.get('errors') && f.get('errors.length') > 0) {
                    hasErrors = true;
                }
            });

            return hasErrors;
        }.property('fieldsObserved.@each.hasErrors'),

        showStatus: function () {
            return this.get('hasErrors') || this.get('showRequiredMessage');
        }.property('hasErrors', 'showRequiredMessage'),

        onComplete: function onComplete() {
            if (this.get('singleUse')) {
                this.resetFields();
            }

            this.set('isLoading', false);

            if (!this.get('disableFlashMessages')) {
                this.get('flashMessages').success('Changes saved!');
            }
        },

        onError: function onError(response) {
            // convert Ember Data formatted errors to Ember Validations format:
            // response.responseJSON.errors.name = ['message'] ->
            //   this.errors[name] = ['message'] and field.errors = ['message']
            if (response && response.responseJSON && response.responseJSON.errors) {
                var errors = this.errors;
                var responseErrors = response.responseJSON.errors;
                var fields = this.get('fieldsObserved');
                _.keys(responseErrors).forEach(function (name) {
                    var field = fields.find(function (field) {
                        return field.name === name;
                    });
                    if (field && errors[name]) {
                        errors[name] = responseErrors[name];
                        field.set('errors', errors[name]);
                    }
                });
            } else {
                if (!this.get('disableFlashMessages')) {
                    this.get('flashMessages').danger('Error saving');
                }
            }
            this.set('isLoading', false);
            this.sendAction('formError');
        },

        resetFields: function resetFields() {
            this.values = {};
            if (this.get('fields')) {
                this.get('fields').replace(0, this._originalFields.length, Ember.copy(this._originalFields));
            }
            if (this.get('narrowFields')) {
                this.get('narrowFields').replace(0, this._originalNarrowFields.length, Ember.copy(this._originalNarrowFields));
            }
        },

        fileUploadId: function () {
            return 'requestFileUpload';
        }.property()
    });
});