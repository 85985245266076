define('huyang-common/components/request-body', ['exports', 'huyang-common/templates/components/request-body', 'huyang-common/mixins/shows-gallery'], function (exports, _requestBody, _showsGallery) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_showsGallery.default, {
        layout: _requestBody.default,

        loadedGalleryImages: [],
        loadedGalleryUrls: [],

        actions: {
            printRequest: function printRequest() {
                window.print();
            },
            onUpdateMeta: function onUpdateMeta() {
                this.sendAction('onUpdateMeta');
            },
            addComment: function addComment(info, attachments, callback) {
                this.sendAction('addComment', info, attachments, callback);
            },
            clickClose: function clickClose() {
                this.sendAction('clickClose');
            },
            clickOpen: function clickOpen() {
                this.sendAction('clickOpen');
            }
        }
    });
});