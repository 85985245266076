define('huyang-common/components/request-asset-picker', ['exports', 'huyang-common/templates/components/request-asset-picker'], function (exports, _requestAssetPicker) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _requestAssetPicker.default,
    classNames: ['request-asset-picker', 'dropup'],
    classNameBindings: ['request.asset.id:has-asset', 'showLabel:form-group'],

    api: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    session: Ember.inject.service(),
    store: Ember.inject.service(),

    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      var showListener = this.dropdownShown.bind(this);
      var hideListener = this.dropdownHidden.bind(this);

      this.$().on('show.bs.dropdown', showListener);
      this.$().on('hide.bs.dropdown', hideListener);

      // set focus on first item on down arrow keypress
      // for arrow key nav
      this.$('input').on('keyup', function (e) {
        if (e.keyCode === 40 && _this.get('results.length')) {
          _this.$('li').eq(0).find('a').focus();
        }
      });

      this.setProperties({ showListener: showListener, hideListener: hideListener });
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      var showListener = this.get('showListener');
      var hideListener = this.get('hideListener');

      this.$().off('show.bs.dropdown', showListener);
      this.$().off('hide.bs.dropdown', hideListener);

      // Clear timeouts because tests run faster than 200ms
      window.clearTimeout(this.get('openMenuTimeout'));
      window.clearTimeout(this.get('chooseTabTimeout'));
    },
    dropdownShown: function dropdownShown() {
      this.set('menuOpen', true);
    },
    dropdownHidden: function dropdownHidden() {
      this.set('menuOpen', false);
      this.set('q', '');
      this.set('results', []);
    },


    focusSearch: Ember.observer('menuOpen', function () {
      var _this2 = this;

      if (this.get('menuOpen')) {
        this.set('openMenuTimeout', window.setTimeout(function () {
          return _this2.$('input').focus();
        }, 200));
      } else {
        this.set('query', '');
      }
    }),

    search: function search() {
      var _this3 = this;

      this.set('noResults', false);
      this.get('api').ajax({
        type: 'GET',
        url: '/api/' + this.get('session.data.authenticated.accountId') + '/assetSearch?q=' + this.get('q') + '&building=' + this.get('request.building.id'),
        dataType: 'json'
      }).then(function (results) {
        if (_this3.get('q') && !results.assets.length) {
          _this3.set('noResults', true);
        }

        _this3.set('results', results.assets);
      });
    },


    queryObserver: Ember.observer('q', function () {
      Ember.run.debounce(this, this.search, 250);
    }),

    showLabel: Ember.computed.and('shouldShowLabel', 'request.asset'),

    actions: {
      selectResult: function selectResult(result) {
        var _this4 = this;

        var asset = this.get('store').peekRecord('asset', result.id);
        var assetCategory = this.get('store').peekRecord('assetCategory', result.assetCategory.id);
        var assetMakeModel = null;
        if (result.assetMakeModel) {
          assetMakeModel = this.get('store').peekRecord('assetMakeModel', result.assetMakeModel.id);
        }

        // this is kind of goofy, but I'm not sure how else to make
        // this work as it's a relationship
        if (!asset) {
          asset = this.get('store').createRecord('asset', result);
        }

        if (!assetCategory) {
          assetCategory = this.get('store').createRecord('assetCategory', result.assetCategory);
        }

        if (!assetMakeModel) {
          assetMakeModel = this.get('store').createRecord('assetMakeModel', result.assetMakeModel);
        }

        asset.set('assetCategory', assetCategory);
        asset.set('assetMakeModel', assetMakeModel);
        this.set('request.asset', asset);

        // only save if editing a request; this is undefined
        // if the request has not been saved yet
        if (!this.get('disableAsyncSave')) {
          this.get('request').save().then(function () {
            _this4.get('flashMessages').clearMessages();
            _this4.get('flashMessages').success('Asset updated!');
          });
        }
      },
      removeAsset: function removeAsset() {
        var _this5 = this;

        this.set('request.asset', null);

        if (!this.get('disableAsyncSave')) {
          this.get('request').save().then(function () {
            _this5.get('flashMessages').clearMessages();
            _this5.get('flashMessages').success('Asset removed!');
          });
        }
      }
    }
  });
});