define('huyang-common/components/channel-display-name', ['exports', 'huyang-common/templates/components/channel-display-name', 'huyang-common/mixins/formats-channel-name'], function (exports, _channelDisplayName, _formatsChannelName) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_formatsChannelName.default, {
        layout: _channelDisplayName.default,

        tagName: 'span',
        classNames: ['channel-display-name']
    });
});