define('huyang-common/models/subspace', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        building: _emberData.default.belongsTo('building', { async: false }),
        name: _emberData.default.attr('string'),
        common: _emberData.default.attr('boolean'),
        position: _emberData.default.attr('number'),
        active: _emberData.default.attr('boolean')
    });
});