define('huyang-common/components/new-comment-form', ['exports', 'huyang-common/templates/components/new-comment-form', 'ember-validations', 'huyang-common/mixins/handles-attachments'], function (exports, _newCommentForm, _emberValidations, _handlesAttachments) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_emberValidations.default, _handlesAttachments.default, {
        layout: _newCommentForm.default,
        inputId: 'commentFileUpload',
        placeholder: '',

        session: Ember.inject.service(),

        validations: {
            body: {
                presence: true
            }
        },

        uploadUrl: function () {
            return '/api/' + this.get('request.account.id') + '/requestAttachments';
        }.property('request.account.id'),

        canSubmit: function () {
            return this.get('attachmentsList.length') > 0 || this.get('isValid');
        }.property('attachmentsList.[]', 'isValid'),

        uploadComplete: function uploadComplete() {
            if (!this.get('hasPendingUploads') && this.get('isLoading')) {
                this.saveComment();
            }
        },

        submitButtonClass: function () {
            if (this.get('darkMode')) {
                return 'btn-success btn-dark';
            }

            return 'btn-success';
        }.property('darkMode'),

        textAreaClassName: function () {
            var defaultClass = 'form-control comment-input';

            if (this.get('hasAttachments')) {
                return defaultClass + ' has-attachments';
            }

            if (this.get('checklistItem')) {
                return defaultClass + ' is-checklist-item';
            }

            return defaultClass;
        }.property('hasAttachments'),

        saveComment: function saveComment() {
            var _this = this;

            var info = {
                request: this.get('request'),
                created: new Date(),
                source: 'web',
                tenantVisible: this.get('tenantVisible'),
                body: this.get('body'),
                author: this.get('session.currentUser')
            };

            if (this.get('channel')) {
                info.channel = this.get('channel');
            }

            if (this.get('checklistItem')) {
                info.checklistItem = this.get('checklistItem');
            }

            var attachments = this.get('attachmentsList');

            if (attachments && attachments.length) {
                info.attachment = attachments.map(function (file) {
                    return file.get('id');
                });
            }

            // set body to unrendered version so it'll be visible immediately
            info.renderedBody = info.body;

            this.sendAction('addComment', info, attachments, function () {
                // clear the list after the comment has saved
                _this.get('attachmentsList').clear();
            });

            this.set('body', '');
            this.set('clickedSave', false);
            this.set('tenantVisible', false);
            this.set('isLoading', false);
        },

        fileUploadId: function () {
            if (this.get('checklistItem')) {
                return 'commentFileUploadChecklistItem';
            }

            return 'commentFileUpload';
        }.property('checklistItem'),

        showError: function () {
            return this.get('isInvalid') && this.get('clickedSave');
        }.property('isInvalid', 'clickedSave'),

        setup: function () {
            this.set('body', '');
        }.on('didInsertElement'),

        focusInput: function () {
            if (this.get('autofocus') !== false) {
                this.$('.comment-input').focus();
                window.scrollTo(0, 0);
            }
        }.on('didInsertElement'),

        actions: {
            addComment: function addComment() {
                if (this.get('canSubmit')) {
                    this.set('isLoading', true);

                    if (!this.get('hasPendingUploads')) {
                        this.saveComment();
                    }
                } else {
                    this.set('clickedSave', true);
                }
            },
            channelSelected: function channelSelected(channel) {
                this.set('channel', channel);
            }
        }
    });
});