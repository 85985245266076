define('huyang-common/components/huyang-form-address', ['exports', 'huyang-common/components/dynamic-component', 'huyang-common/templates/components/huyang-form-address'], function (exports, _dynamicComponent, _huyangFormAddress) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _dynamicComponent.default.extend({
    layout: _huyangFormAddress.default,
    intlTelInput: Ember.inject.service(),

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      var country = this.get('country') || 'US';
      this.set('selectedCountry', country);

      var config = Ember.getOwner(this).resolveRegistration('config:environment');
      var prefix = config.CLOUDFRONT_PREFIX ? 'https:' + config.CLOUDFRONT_PREFIX : '/';
      var json = prefix + 'addressfield.json';

      if (config.addressJson) json = config.addressJson;

      this.$().closest('form').addressfield({
        json: json,
        fields: {
          country: '#hfa-country',
          thoroughfare: '#hfa-address1',
          locality: '#hfa-locality-fields',
          localityname: '#hfa-city',
          administrativearea: '#hfa-state',
          postalcode: '#hfa-zip'
        }
      });
    }
  });
});