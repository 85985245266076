define('huyang-common/components/weekday-picker', ['exports', 'huyang-common/templates/components/weekday-picker'], function (exports, _weekdayPicker) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        layout: _weekdayPicker.default,
        classNames: ['weekday-picker'],

        dayIsSelected: function dayIsSelected(day) {
            var selected = this.get('selectedDays');
            if (selected && selected.length) {
                return selected.map(function (d) {
                    return d.get('name');
                }).indexOf(day) > -1;
            } else {
                return day === this.get('today');
            }
        },

        weekdays: function () {
            var _this = this;

            return ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'].map(function (day) {
                var chars = _this.get('chars') || 2;
                var btnClass = _this.dayIsSelected(day) ? 'btn-primary' : 'btn-default';
                return Ember.Object.create({
                    day: day,
                    label: day.slice(0, chars),
                    inputName: 'weekday' + day,
                    class: btnClass
                });
            });
        }.property(),

        weekdayOptions: function () {
            // Lets view be updated on class change
            return this.get('weekdays');
        }.property('weekdays.@each.class'),

        actions: {
            toggleDay: function toggleDay(day) {
                day.set('class', day.get('class') === 'btn-default' ? 'btn-primary' : 'btn-default');
                this.sendAction('clickWeekday', day.get('day'));
            }
        }
    });
});