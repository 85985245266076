define('huyang-common/mixins/displays-priority', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Mixin.create({
        priorityMap: {
            1: { className: 'critical', label: 'Critical' },
            2: { className: 'high', label: 'High' },
            3: { className: 'med', label: 'Medium' },
            4: { className: 'low', label: 'Low' }
        },

        priorityClass: function () {
            var priorityMap = this.get('priorityMap');
            var pm = priorityMap[this.get('priority')];

            return pm ? pm.className : '';
        }.property('priority'),

        priorityLabel: function () {
            var priorityMap = this.get('priorityMap');
            var pm = priorityMap[this.get('priority')];

            return pm ? pm.label : '?';
        }.property('priority')
    });
});