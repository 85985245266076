define('huyang-common/components/tabbed-dropdown-menu', ['exports', 'huyang-common/templates/components/tabbed-dropdown-menu', 'huyang-common/mixins/validates-email'], function (exports, _tabbedDropdownMenu, _validatesEmail) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var computed = Ember.computed,
        observer = Ember.observer;
    exports.default = Ember.Component.extend(_validatesEmail.default, {
        layout: _tabbedDropdownMenu.default,
        classNames: ['tabbed-dropdown-menu'],

        allowNobody: true,
        currentTab: 'staff',
        closeMenuOnSelect: true,
        staff: [],
        vendors: [],
        occupants: [],
        query: '',

        staffTabActive: Ember.computed.equal('currentTab', 'staff'),
        vendorTabActive: Ember.computed.equal('currentTab', 'vendors'),
        occupantTabActive: Ember.computed.equal('currentTab', 'occupants'),

        session: Ember.inject.service(),

        didInsertElement: function didInsertElement() {
            this._super.apply(this, arguments);

            var showListener = this.dropdownShown.bind(this);
            var hideListener = this.dropdownHidden.bind(this);

            this.$('.btn-group').on('show.bs.dropdown', showListener);
            this.$('.btn-group').on('hide.bs.dropdown', hideListener);

            this.setProperties({ showListener: showListener, hideListener: hideListener });
        },
        willDestroyElement: function willDestroyElement() {
            this._super.apply(this, arguments);

            var showListener = this.get('showListener');
            var hideListener = this.get('hideListener');

            this.$('.btn-group').off('show.bs.dropdown', showListener);
            this.$('.btn-group').off('hide.bs.dropdown', hideListener);

            // Clear timeouts because tests run faster than 200ms
            window.clearTimeout(this.get('openMenuTimeout'));
            window.clearTimeout(this.get('chooseTabTimeout'));
        },
        dropdownShown: function dropdownShown() {
            this.set('menuOpen', true);
        },
        dropdownHidden: function dropdownHidden() {
            this.set('menuOpen', false);
        },


        focusSearch: observer('menuOpen', function () {
            var _this = this;

            if (this.get('menuOpen')) {
                this.set('openMenuTimeout', window.setTimeout(function () {
                    return _this.$('input').focus();
                }, 200));
            } else {
                this.set('currentTab', 'staff');
                this.set('query', '');
            }
        }),

        filterByQuery: function filterByQuery(collection, q) {
            if (!q) {
                return collection;
            }

            return collection.filter(function (user) {
                var name = (user.get('name') || '').toLowerCase();
                var email = user.get('email').toLowerCase();

                return name.indexOf(q) > -1 || email.indexOf(q) > -1;
            });
        },


        // followers-form can filter staff/occupants for multiselect
        users: computed('staff.[]', 'occupants.[]', 'staffTabActive', 'vendorTabActive', 'occupantTabActive', 'query', function () {
            var q = (this.get('query') || '').toLowerCase().trim();

            if (this.get('staffTabActive')) {
                return this.filterByQuery(this.get('staff'), q);
            } else if (this.get('vendorTabActive')) {
                return this.filterByQuery(this.get('vendors'), q);
            } else if (this.get('occupantTabActive')) {
                return this.filterByQuery(this.get('occupants'), q);
            }

            return [];
        }),

        searchPlaceholder: computed('staffTabActive', 'vendorTabActive', function () {
            if (this.get('staffTabActive')) {
                return 'Search building staff...';
            } else if (this.get('vendorTabActive')) {
                return 'Search vendors and technicians...';
            }

            return 'Search...';
        }),

        queryIsValidUnusedEmail: computed('query', 'users.length', function () {
            return this.isEmail(this.get('query')) && this.get('users.length') === 0;
        }),

        showCreateUserLink: computed('currentTab', 'queryIsValidUnusedEmail', function () {
            return this.get('currentTab') !== 'staff' && this.get('queryIsValidUnusedEmail') && this.get('session.currentUser.isAdmin');
        }),

        createUserIcon: computed('currentTab', function () {
            if (this.get('currentTab') === 'vendors') {
                return 'truck';
            } else {
                return 'user';
            }
        }),

        createUserLabel: computed('currentTab', function () {
            var tab = this.get('currentTab'); // vendors | occupants

            return tab.replace(/s$/, '');
        }),

        preventMenuClose: function preventMenuClose() {
            var e = this.get('lastClickEvent');

            if (!e) {
                return;
            }

            e.stopPropagation();
            e.preventDefault();
        },


        actions: {
            // Depending on whether the menu should remain open until clicked out,
            // we need to access the original event via `onClick` (since {{action}}
            // does not pass it) and then stop it later, since stopping it now would
            // prevent `chooseTab`, etc (as onClick happens before {{action}})
            storeEvent: function storeEvent(e) {
                this.set('lastClickEvent', e);
            },
            chooseTab: function chooseTab(tab) {
                var _this2 = this;

                this.set('currentTab', tab);
                this.preventMenuClose();

                this.set('chooseTabTimeout', window.setTimeout(function () {
                    var $input = _this2.$('.tabbed-menu-content .menu-input input');

                    // So this is pretty dumb, but tests might otherwise break with
                    // `undefined is not an object (evaluating '$input.focus')`
                    if ($input) {
                        $input.focus();
                    }
                }, 200));
            },
            chooseUser: function chooseUser(user) {
                if (user && user.get('isVendor')) {
                    this.sendAction('assignToVendor', user);
                } else {
                    this.sendAction('chooseUser', user);
                }

                if (!this.get('closeMenuOnSelect')) {
                    this.preventMenuClose();
                }
            },
            createUser: function createUser() {
                var tab = this.get('currentTab');
                var validEmail = this.get('query');

                if (tab === 'occupants') {
                    // No user object to add, so just send valid email because mixins/creates-followers
                    // will handle this automatically
                    this.sendAction('createOccupant', validEmail);
                } else {
                    // Creating vendors happens on "assign to" and handled elsewhere
                    this.sendAction('createVendor', validEmail);
                }
            },
            createVendor: function createVendor() {
                this.sendAction('createVendor', this.get('query'));
            }
        }
    });
});