define('huyang-common/mixins/handles-attachments', ['exports', 'huyang-common/lib/pending-file'], function (exports, _pendingFile) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Mixin.create({
        attachmentsList: [],
        api: Ember.inject.service(),

        // Convenience methods to override
        fileAdded: function fileAdded() {},
        fileRemoved: function fileRemoved() {},
        uploadComplete: function uploadComplete() {},


        hasPendingUploads: function () {
            var hasPending = false;

            this.get('attachmentsList').forEach(function (file) {
                if (!file.get('uploaded')) {
                    hasPending = true;
                }
            });

            return hasPending;
        }.property('attachmentsList.@each.uploaded'),

        attachmentsForForm: function () {
            var _this = this;

            if (!this.get('fileUploadId')) {
                return this.get('attachmentsList');
            } else {
                return this.get('attachmentsList').filter(function (att) {
                    return att.get('inputId') === _this.get('fileUploadId');
                });
            }
        }.property('attachmentsList.length', 'fileUploadId'),

        hasAttachments: function () {
            var _this2 = this;

            if (!this.get('attachmentsList.length')) {
                return false;
            }
            if (!this.get('fileUploadId')) {
                return true;
            }
            return this.get('attachmentsList').find(function (att) {
                return att.get('inputId') === _this2.get('fileUploadId');
            });
        }.property('attachmentsList.length', 'fileUploadId'),

        _setup: function () {
            if (Ember.isArray(this.get('attachmentsList'))) {
                this.get('attachmentsList').clear();
            }
        }.on('didInsertElement'),

        removeAllPendingAttachments: function removeAllPendingAttachments() {
            var _this3 = this;

            this.get('attachmentsList').forEach(function (att) {
                _this3.send('removePendingAttachment', att);
            });
        },

        actions: {
            openFileBrowser: function openFileBrowser() {
                this.$('#' + this.get('inputId')).click();
            },
            fileAdded: function fileAdded(data, inputId) {
                var attachmentsList = this.get('attachmentsList');

                if (data.files) {
                    data.files.forEach(function (file) {
                        attachmentsList.pushObject(_pendingFile.default.create({
                            id: file.id,
                            lastModifiedDate: file.lastModifiedDate,
                            name: file.name,
                            size: file.size,
                            type: file.type,
                            inputId: inputId
                        }));
                    });

                    this.fileAdded();
                }
            },
            uploadComplete: function uploadComplete(uuid, data) {
                var self = this;

                if (data.files) {
                    data.files.forEach(function (file) {
                        // find pending file object
                        var pending = self.get('attachmentsList').find(function (f) {
                            return f.get('id') === file.id;
                        });

                        if (pending) {
                            pending.set('id', data.result.requestAttachment.id);
                            pending.set('uuid', uuid);
                        }
                    });

                    this.uploadComplete();
                }
            },
            uploadFailed: function uploadFailed(err) {
                console.log('failed result=', err);
                // TODO:
            },

            removePendingAttachment: function removePendingAttachment(att) {
                // remove from attachments list
                var listObj = this.get('attachmentsList').find(function (obj) {
                    return obj.get('id') === att.get('id');
                });
                if (listObj) {
                    this.get('attachmentsList').removeObject(listObj);
                }
                // remove from database
                var url = this.get('uploadUrl') + '/' + att.get('id');
                this.get('api').ajax({
                    url: url,
                    method: 'DELETE'
                });

                this.fileRemoved(att.get('id'));
            }
        }
    });
});