define('huyang-common/components/ajax-sortable-table', ['exports', 'huyang-common/templates/components/sortable-table', 'huyang-common/components/sortable-table'], function (exports, _sortableTable, _sortableTable2) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _sortableTable2.default.extend({
        layout: _sortableTable.default,

        // data is sorted on the server side
        sortedData: function () {
            return this.get('data');
        }.property('data'),

        actions: {
            sortBy: function sortBy(key, addToSort) {
                this._super(key);
                this.sendAction('sortBy', key, addToSort);
            }
        }
    });
});