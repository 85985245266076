define('huyang-common/serializers/rest-new', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var RestSerializer = _emberData.default.RESTSerializer.extend(); // use old API on rest serializer


    RestSerializer.reopen({
        isNewSerializerAPI: true
    });

    exports.default = RestSerializer;
});