define('huyang-common/components/save-button', ['exports', 'huyang-common/templates/components/save-button'], function (exports, _saveButton) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        layout: _saveButton.default,
        buttonClasses: 'btn btn-success',
        tagName: 'span',

        disabled: function () {
            return !this.get('valid') || this.get('saved');
        }.property('saved', 'valid'),

        showSaved: function () {
            return this.get('saved') && this.get('clickedSave');
        }.property('saved', 'clickedSave'),

        actions: {
            save: function save() {
                this.sendAction();
                this.set('clickedSave', true);
            }
        }
    });
});