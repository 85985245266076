define('huyang-common/models/view-config', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        account: _emberData.default.belongsTo('account'),
        user: _emberData.default.belongsTo('user'),
        viewName: _emberData.default.attr('string'),
        columnList: _emberData.default.attr()
    });
});