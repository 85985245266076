define('huyang-common/lib/pending-file', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Object.extend({
        iconClass: function () {
            var type = this.get('type') || '';

            if (type === 'application/pdf') {
                return 'fa-file-pdf-o';
            } else if (type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || type === 'application/vnd.ms-excel') {
                return 'fa-file-excel-o';
            } else if (type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
                return 'fa-file-word-o';
            } else if (type === 'application/zip') {
                return 'fa-file-zip-o';
            } else if (type === 'text/plain') {
                return 'fa-file-text-o';
            } else if (type === 'image/gif' || type === 'image/jpeg' || type === 'image/png') {
                return 'fa-file-image-o';
            }

            return 'fa-file-o';
        }.property('type'),

        uploaded: function () {
            return this.get('uuid');
        }.property('uuid')
    });
});