define('huyang-common/components/due-date-picker', ['exports', 'huyang-common/templates/components/due-date-picker', 'huyang-common/utils/date-utils'], function (exports, _dueDatePicker, _dateUtils) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var inflector = Ember.Inflector.inflector;

    exports.default = Ember.Component.extend({
        layout: _dueDatePicker.default,
        classNames: ['due-date-picker'],
        invalidCreateDate: false,

        session: Ember.inject.service(),

        setup: function () {
            var obj = (0, _dateUtils.toIntervalObject)(this.get('createDate'), this.get('dueDate'));
            this.set('unit', obj.unit);
            this.set('interval', obj.interval);
        }.on('init'),

        dueDatePickerOptions: function () {
            return {
                defaultDate: moment(this.get('dueDate')),
                minDate: this.get('minDate') || moment(),
                inline: true,
                sideBySide: false,
                stepping: 5
            };
        }.property('dueDate'),

        daysTilDueDate: function () {
            return Math.round(moment(this.get('dueDate')).diff(moment(), 'days', true));
        }.property('dueDate'),

        createDateIntervals: function () {
            var arr = [];
            for (var i = 1; i < 31; i++) {
                arr.push(i);
            }
            return arr;
        }.property(),

        createDateUnits: [{ id: 'day', label: 'day(s)' }, { id: 'week', label: 'week(s)' }, { id: 'month', label: 'month(s)' }],

        createDateUnitLabel: function () {
            var unit = this.get('unit'),
                interval = this.get('interval');
            if (interval !== 1) {
                unit = inflector.pluralize(unit);
            }
            return unit;
        }.property('interval', 'unit'),

        dueDateFormatted: function () {
            return moment(this.get('dueDate')).format('ddd, MMM D, YYYY [at] h:mm a');
        }.property('dueDate'),

        highlightDate: function () {
            if (this.get('createLater')) {
                return this.get('createDate');
            }
        }.property('createDate', 'createLater'),

        sendUpdate: function sendUpdate(dueDate, createDate) {
            var data = { dueDate: dueDate };

            if (createDate) {
                data.createDate = createDate;
            } else if (dueDate && this.get('showScheduling') && this.get('createLater')) {
                var unit = this.get('unit') || 'day';
                var interval = this.get('interval') || 1;
                var newCreateDate = moment(dueDate).subtract(interval, unit);

                this.set('invalidCreateDate', this.get('createLater') && newCreateDate.isBefore(moment()));
                if (!this.get('invalidCreateDate')) {
                    data.createDate = newCreateDate;
                }
            }
            this.sendAction('action', data);
        },

        actions: {
            setProperty: function setProperty(prop, value) {
                if (prop !== 'dueDate' && prop !== 'createDate') {
                    this.set(prop, value);
                }
                var dueDate = prop === 'dueDate' ? value : this.get('dueDate');
                var createDate = prop === 'createDate' ? value : null;

                this.sendUpdate(dueDate, createDate);
            },

            setDueDate: function setDueDate(date) {
                this.set('dueDate', date);
                this.sendUpdate(date);
            }
        }
    });
});