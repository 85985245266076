define('huyang-common/models/request', ['exports', 'ember-data', 'huyang-common/mixins/displays-priority', 'huyang-common/utils/date-utils', 'huyang-common/utils/time'], function (exports, _emberData, _displaysPriority, _dateUtils, _time) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend(_displaysPriority.default, {
        account: _emberData.default.belongsTo('account', { async: true }),
        building: _emberData.default.belongsTo('building', { async: true }),
        subspace: _emberData.default.belongsTo('subspace', { async: true }), // optional
        requestType: _emberData.default.belongsTo('requestType', { async: true }),
        requestLabel: _emberData.default.belongsTo('requestLabel', { async: true }),
        attachments: _emberData.default.hasMany('requestAttachment', { async: true }),
        events: _emberData.default.hasMany('requestEvents', { async: true, inverse: 'request' }),
        comments: _emberData.default.hasMany('requestComment', { async: true }),
        followers: _emberData.default.hasMany('requestFollower', { async: true }),
        timeLogs: _emberData.default.hasMany('timeLog', { async: true }),
        costLogs: _emberData.default.hasMany('costLog', { async: true }),
        // for request templates
        schedule: _emberData.default.belongsTo('requestSchedule', { async: false }),
        // for requests in repeating series - can do `request.template`
        // but not `template.requests` without an inverse association
        template: _emberData.default.belongsTo('request', { async: true, inverse: null }),

        title: _emberData.default.attr('string'),
        description: _emberData.default.attr('string'),
        renderedDescription: _emberData.default.attr('string'),
        status: _emberData.default.attr('string'),
        created: _emberData.default.attr('date'),
        updated: _emberData.default.attr('date'),
        originator: _emberData.default.belongsTo('user', { async: true }),
        owner: _emberData.default.belongsTo('user', { async: true }),
        priority: _emberData.default.attr('number'),
        secondsOpen: _emberData.default.attr('number'),
        opened: _emberData.default.attr('date'),
        workOrder: _emberData.default.attr('number'),
        unreadComments: _emberData.default.attr(),
        dueDate: _emberData.default.attr('date'),

        api: Ember.inject.service(),

        // connect a list of attachments ids to this work order
        // the attachments should have already been created
        addAttachments: function addAttachments(attachmentIds, comment) {
            var _this = this;

            var promises = [];

            attachmentIds.forEach(function (attId) {
                _this.store.findRecord('request-attachment', attId).then(function (att) {
                    att.set('request', _this);
                    att.set('user', _this.get('originator'));

                    if (comment) {
                        att.set('comment', comment);
                        att.set('user', comment.get('author'));
                    }

                    promises.push(att.save());
                });
            });

            return Ember.RSVP.all(promises);
        },

        location: function () {
            var name = this.get('building.name');

            if (this.get('subspace') && this.get('subspace.name')) {
                name += ', ' + this.get('subspace.name');
            }

            return name;
        }.property('building', 'subspace'),

        statusLabel: function () {
            return (this.get('status') || '').capitalize();
        }.property('status'),

        timeToResolution: function () {
            var open = (this.get('secondsOpen') || 0) * 1000;

            if (this.get('status') === 'open') {
                open += moment().diff(this.get('opened'));
            }

            var ttr = (0, _dateUtils.humanizeDuration)(open);

            if (this.get('status') === 'open') {
                ttr += ' and counting';
            }

            return ttr;
        }.property('secondsOpen', 'status', 'opened'),

        buildingId: function () {
            // for properties, since array properties go only one level deep (not building.id)
            return parseInt(this.get('building.id'));
        }.property('building'),

        // rollback doesn't work on belongsTo relationships
        // save original properties here, then restore in rollback
        startEdit: function startEdit() {
            var props = {};
            var self = this;
            this.eachRelationship(function (key, relationship) {
                if (relationship.kind === 'belongsTo') {
                    props[key] = self.get(key);
                }
            });
            this.set('savedRelationships', props);
        },

        destroyRecord: function destroyRecord() {
            // save tries to update record in uncommitted state if these are set
            this.set('createDate', null);
            this.set('afterClose', null);
            return this._super();
        },

        save: function save() {
            var _this2 = this;

            this.set('savedRelationships', {});
            if (this.get('createDate') || this.get('afterClose')) {
                // pending = request will be updated to open at a future date
                // template = request is a template for creating new requests
                var status = this.get('repeatRule') || this.get('afterClose') ? 'template' : 'pending';
                this.set('status', status);
                this.set('created', this.get('createDate') || moment().toDate());
                var req = this._super(); // Save to get request id
                return req.then(function () {
                    return _this2.saveSchedule();
                }).then(function () {
                    return req;
                });
            } else {
                return this._super();
            }
        },

        saveSchedule: function saveSchedule() {
            var schedule = this.get('schedule');
            if (!schedule) {
                schedule = this.store.createRecord('request-schedule', {
                    originator: this.get('originator'),
                    request: this
                });
            }
            // repeatRule or afterClose may be null, depending on schedule 'type'
            schedule.setProperties({
                active: true,
                afterClose: this.get('afterClose'),
                start: this.get('createDate'),
                repeatRule: this.get('repeatRule'),
                dueAfter: this.get('dueAfter'),
                previousClose: this.get('previousClose'),
                previousRelabel: this.get('previousRelabel'),
                previousLabel: this.get('previousLabel')
            });
            return schedule.save();
        },

        rollback: function rollback() {
            var ret = this._super();
            var self = this;
            var props = this.get('savedRelationships') || {};
            Object.keys(props).forEach(function (key) {
                self.set(key, props[key]);
            });
            return ret;
        },

        firstImage: function () {
            return this.get('images.firstObject');
        }.property('images.[]'),

        images: function () {
            return this.get('attachments').filter(function (att) {
                return att.get('isImage');
            });
        }.property('attachments.@each.isImage'),

        descriptionImages: function () {
            return this.get('images').filter(function (img) {
                return !img.get('comment.id') && !img.get('costLog.id');
            });
        }.property('images'),

        descriptionFiles: function () {
            return this.get('attachments').filter(function (att) {
                return !att.get('comment.id') && !att.get('costLog.id') && !att.get('isImage');
            });
        }.property('attachments', 'images'),

        hasContent: function () {
            return this.get('renderedDescription') || this.get('nonCommentImages.length') > 0 || this.get('nonCommentFiles.length') > 0;
        }.property('renderedDescription', 'nonCommentImages.length', 'nonCommentFiles.length'),

        removeFollower: function removeFollower(followerId) {
            var follower = this.get('followers').find(function (f) {
                return f.get('id') === followerId;
            });

            if (!follower) {
                return;
            }

            follower.destroyRecord();
        },

        addFollower: function addFollower(userId) {
            var _this3 = this;

            // see if this user is already following this request
            var follower = this.get('followers').find(function (f) {
                return f.get('user.id') === userId;
            });
            if (follower) {
                return;
            }
            var rf = this.store.createRecord('requestFollower', {
                request: this,
                user: this.store.peekRecord('user', userId)
            });
            rf.save().then(function () {
                _this3.get('followers').pushObject(rf);
            });
        },

        addFollowers: function addFollowers(userIds) {
            var _this4 = this;

            // use ints consistently;
            // Ember returns strings for ids but create occupants API call returns ints
            var followerIds = this.get('followers').map(function (f) {
                return parseInt(f.get('user.id'));
            });
            if (!userIds) {
                userIds = [];
            }
            var newUserIds = userIds.map(function (uid) {
                return parseInt(uid);
            });
            var promises = _.difference(newUserIds, followerIds).map(function (uid) {
                var record = _this4.store.createRecord('requestFollower', {
                    request: _this4,
                    user: _this4.store.peekRecord('user', uid)
                });
                return record.save();
            });
            return Ember.RSVP.all(promises);
        },

        totalUnreadComments: function () {
            var unread = this.get('unreadComments');
            var total = 0;

            for (var key in unread) {
                var count = unread[key];

                if (count) {
                    total = total + count;
                }
            }

            return total;
        }.property('unreadComments'),

        markChannelAsRead: function markChannelAsRead(channel) {
            var _this5 = this;

            var unread = this.get('unreadComments');
            if (!unread) {
                return;
            }
            var channelUnread = unread[channel.get('unreadKey')];

            if (channelUnread > 0) {
                var commentsToMark = this.get('comments').filter(function (comment) {
                    return comment.get('channel.id') === channel.get('id');
                }).map(function (comment) {
                    return parseInt(comment.get('id'));
                });

                if (commentsToMark.length > 0) {
                    return this.markCommentsAsRead(commentsToMark).then(function () {
                        // update the count locally
                        var newCount = channelUnread - commentsToMark.length;

                        // this can go below zero because the unread count doesn't
                        // contain user's own comments, or the request creation comment
                        if (newCount < 0) {
                            newCount = 0;
                        }

                        unread[channel.get('unreadKey')] = newCount;

                        _this5.set('unreadComments', unread);
                        _this5.notifyPropertyChange('unreadComments');
                    });
                }
            }
        },

        markCommentsAsRead: function markCommentsAsRead(commentIds) {
            if (commentIds.length > 0) {
                var accountId = localStorage.getItem('accountId');

                return this.get('api').ajax({
                    type: 'POST',
                    url: '/api/' + accountId + '/multiCommentRead',
                    data: JSON.stringify({
                        comments: commentIds
                    }),
                    dataType: 'json'
                });
            }
        },

        /*
            how can we specifically label the occupants that can view this request?
             1. If originator is occupant with no group, show originator name
            2. If originator is in occupant group, show group name
            3. Show generic label in all other cases, including when there are followers from other groups
        */
        occupantsLabel: function () {
            var originator = this.get('originator');
            var followerCount = this.get('occupantFollowers.length');

            // show name of only occupant follower if originator isn't an occupant
            if (!originator.get('isOccupant') && followerCount === 1) {
                var follower = this.get('occupantFollowers')[0];
                return follower.get('user.displayName');
            }

            // handle instances where there are more than one occupant group represented
            if (followerCount > 1 || originator.get('isOccupant') && followerCount > 0) {
                return 'Multiple Occupants';
            }

            // otherwise, show the occupant user's group name or user name
            if (originator.get('isOccupant')) {
                if (originator.get('occupantGroup')) {
                    return originator.get('occupantGroup.name');
                }

                return originator.get('displayName');
            }

            return 'Occupants';
        }.property('originator', 'occupantFollowers.[]'),

        occupantFollowers: function () {
            var originator = this.get('originator');
            var followers = this.get('followers').filter(function (follower) {
                return follower.get('user.id') !== originator.get('id');
            });

            if (originator.get('isOccupant')) {
                var originatorGroup = originator.get('occupantGroup');

                // if originator has a group, return followers who don't belong to the originator's group
                if (originatorGroup) {
                    return followers.filter(function (f) {
                        var user = f.get('user');

                        if (user.get('occupantGroup')) {
                            return user.get('isOccupant') && user.get('occupantGroup.id') !== originatorGroup.get('id');
                        }

                        return user.get('isOccupant');
                    });
                }
            }

            return followers.filter(function (follower) {
                return follower.get('user.isOccupant');
            });
        }.property('followers.[]', 'originator'),

        // this is a dumb computed property to help our dumb logic-less templates
        hasMultipleOccupantFollowers: function () {
            return this.get('occupantFollowers.length') > 1;
        }.property('occupantFollowers.length'),

        hasAnyOccupantAccess: function () {
            return this.get('originator.isOccupant') || this.get('occupantFollowers.length') > 0;
        }.property('originator', 'occupantFollowers.length'),

        isOpen: Ember.computed.equal('status', 'open'),
        isClosed: Ember.computed.equal('status', 'closed'),
        isPending: Ember.computed.equal('status', 'pending'),
        isTemplate: Ember.computed.equal('status', 'template'),

        isScheduled: function () {
            return this.get('status') === 'pending' || this.get('status') === 'template';
        }.property('status'),

        canRemoveDueDate: function () {
            return this.get('dueDate') && !this.get('isScheduled');
        }.property('dueDate', 'isScheduled'),

        closeDate: function () {
            var events = this.get('events').filterBy('eventType', 'close').sortBy('eventDate').reverseObjects();

            if (events.length > 0) {
                return events[0].get('eventDate');
            }

            return null;
        }.property('events.[]'),

        savedLogs: function () {
            return this.get('timeLogs').filter(function (log) {
                return !!log.get('endTime');
            }).sort(function (log1, log2) {
                return log2.get('endTime') - log1.get('endTime');
            }); // Most recent first
        }.property('timeLogs.@each.endTime'),

        trackedHoursAndMinutes: function trackedHoursAndMinutes(logs) {
            var hours = 0;
            var minutes = 0;

            logs.forEach(function (log) {
                hours += log.get('hours') || 0;
                minutes += log.get('minutes') || 0;
            });

            // Add minutes >= 60 to hours instead
            hours += Math.floor(minutes / 60);
            minutes = Math.floor(minutes % 60);

            return { hours: hours, minutes: minutes };
        },

        timeLoggedTotalString: function () {
            var _trackedHoursAndMinut = this.trackedHoursAndMinutes(this.get('savedLogs')),
                hours = _trackedHoursAndMinut.hours,
                minutes = _trackedHoursAndMinut.minutes;

            return (0, _time.timerString)(hours, minutes, null, { zeroIfEmpty: false });
        }.property('savedLogs.@each.hours', 'savedLogs.@each.minutes'),

        timeLoggedBillableString: function () {
            var _trackedHoursAndMinut2 = this.trackedHoursAndMinutes(this.get('savedLogs').filter(function (log) {
                return log.get('billable');
            })),
                hours = _trackedHoursAndMinut2.hours,
                minutes = _trackedHoursAndMinut2.minutes;

            return (0, _time.timerString)(hours, minutes, null, { zeroIfEmpty: false });
        }.property('savedLogs.@each.hours', 'savedLogs.@each.minutes'),

        timeLoggedNonBillableString: function () {
            var _trackedHoursAndMinut3 = this.trackedHoursAndMinutes(this.get('savedLogs').filter(function (log) {
                return !log.get('billable');
            })),
                hours = _trackedHoursAndMinut3.hours,
                minutes = _trackedHoursAndMinut3.minutes;

            return (0, _time.timerString)(hours, minutes, null, { zeroIfEmpty: false });
        }.property('savedLogs.@each.hours', 'savedLogs.@each.minutes'),

        costTotal: function () {
            return this.get('costLogs').reduce(function (acc, value) {
                return acc + value.get('value');
            }, 0);
        }.property('costLogs.@each.value', 'costLogs.@each.billable'),

        costBillableTotal: function () {
            return this.get('costLogs').filterBy('billable').reduce(function (acc, value) {
                return acc + value.get('value');
            }, 0);
        }.property('costTotal'),

        costNonBillableTotal: function () {
            return this.get('costLogs').filterBy('billable', false).reduce(function (acc, value) {
                return acc + value.get('value');
            }, 0);
        }.property('costTotal')
    });
});