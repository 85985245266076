define('huyang-common/components/dynamic-component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        propertiesToSend: [],

        unpackProperties: function () {
            if (this.get('dynamic')) {
                this.setProperties(this.get('propertiesToUnpack'));
            }
        }.on('init'),

        notifyOfChanges: function notifyOfChanges() {
            var _this = this;

            var properties = {};

            this.get('propertiesToSend').forEach(function (prop) {
                properties[prop] = _this.get(prop);
            });

            if (this.get('dynamic')) {
                this.sendAction('onDataUpdate', this.get('actionName'), properties);
            } else {
                this.sendAction('dataChanged', properties);
            }
        }
    });
});