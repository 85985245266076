define('huyang-common/controllers/login', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        session: Ember.inject.service(),

        authenticator: 'authenticator:custom',
        lookupFailed: false,

        isMobileUserAgent: function () {
            return navigator.userAgent.match(/Mobile|Android|BlackBerry/);
        }.property(),

        linkMethod: function () {
            return this.get('isMobileUserAgent') ? 'sms' : 'email';
        }.property('isMobileUserAgent'),

        linkByEmail: function () {
            return this.get('linkMethod') === 'email';
        }.property('linkMethod'),

        noMobile: function () {
            return !this.get('linkByEmail') && this.get('linkSent') && this.get('missingMobile');
        }.property('linkMethod', 'linkSent', 'missingMobile'),

        subdomain: function () {
            return window.location.host.split('.')[0];
        }.property('url'),

        // override in extending class
        getLookupUrl: function getLookupUrl() {
            return '/api/building/email_lookup';
        },

        lookupUser: function lookupUser(email, createUser) {
            return Ember.$.ajax({
                type: 'POST',
                url: this.getLookupUrl(),
                data: JSON.stringify({ email: email, create: createUser, method: this.get('linkMethod') }),
                dataType: 'json'
            });
        },

        sendLoginLink: function sendLoginLink(email, createUser) {
            var _this = this;

            this.set('showLoading', true);
            this.set('linkSent', false);
            this.set('lookupFailed', false);
            this.set('blocked', false);

            return this.lookupUser(email, createUser).then(function (response) {
                Ember.run(function () {
                    console.log('lookup success', response);
                    _this.set('showLoading', false);
                    _this.set('linkSent', true);
                    _this.set('status', response.status);
                    _this.set('isNew', response.new);
                    _this.set('missingMobile', response.missingMobile);
                });
            }).fail(function (response) {
                Ember.run(function () {
                    console.log('login failed', response);
                    _this.set('lookupFailed', true);
                    if (response.responseText.indexOf('blocked') >= 0) {
                        _this.set('blocked', true);
                    } else {
                        _this.set('badEmail', email);
                        _this.set('showLoading', false);
                        Ember.$('#lookupError').show();
                    }
                });
            });
        },

        actions: {
            sendLink: function sendLink() {
                var email = this.get('email') || '';

                if (!email.match(/.+@.+\..+/)) {
                    this.set('emailError', true);
                    return;
                }
                this.sendLoginLink(this.get('email'));
            },

            authenticate: function authenticate() {
                console.log('login controller authenticate');
                this.get('session').authenticate('authenticator:custom', {
                    identification: this.get('identification'),
                    password: this.get('password')
                });
            },

            clearError: function clearError() {
                this.set('lookupFailed', false);
            }
        },

        hasError: function () {
            return this.get('loginErrorMessage');
        }.property('loginErrorMessage')
    });
});