define('huyang-common/components/user-plus-email-picker', ['exports', 'huyang-common/templates/components/user-plus-email-picker'], function (exports, _userPlusEmailPicker) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        layout: _userPlusEmailPicker.default,
        selectId: 'userPlusEmailPicker',
        name: 'userPlusEmailPicker',
        itemTypeMap: {},
        // from http://emailregex.com/
        emailRegExp: new RegExp(/^[-a-z0-9~!$%^&*_=+}{\'?]+(\.[-a-z0-9~!$%^&*_=+}{\'?]+)*@([a-z0-9_][-a-z0-9_]*(\.[-a-z0-9_]+)*\.(aero|arpa|biz|com|coop|edu|gov|info|int|mil|museum|name|net|org|pro|travel|mobi|[a-z][a-z])|([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}))(:[0-9]{1,5})?$/i),
        multiple: true,

        session: Ember.inject.service(),

        config: function () {
            return {
                multiple: true,
                placeholder: 'Enter name or email',
                width: '100%',
                tags: this.get('addUsers')
            };
        }.property('addUsers'),

        /*
            add extra classes to selected items; need to do this every time it gets
            refreshed (created, add, remove)
        */
        setBorders: function () {
            var _this = this;

            var users = this.get('users');
            var itemMap = this.get('userMap') || {};
            var emailRegExp = this.get('emailRegExp');

            Ember.run.next(function () {
                Ember.$('li.select2-selection__choice').each(function (index, li) {
                    var val = Ember.$(li).attr('title');

                    if (!itemMap[val]) {
                        // in items list = user, x@y.com = email, else error
                        var extraClass = '';

                        if (emailRegExp.test(val)) {
                            if (Ember.isArray(users)) {
                                var match = users.find(function (user) {
                                    return user.get('email') === val;
                                });
                                if (match) {
                                    extraClass = match.hasAnyRole(_this.get('session.data.authenticated.accountId'), ['staff', 'staff-all']) ? 'staff' : 'user';
                                } else {
                                    extraClass = 'email';
                                }
                            } else {
                                extraClass = 'email';
                            }
                        } else {
                            extraClass = 'error';
                        }

                        itemMap[val] = extraClass;
                    }

                    Ember.$(li).addClass(itemMap[val]);
                });
            });
        }.observes('selected.[]'),

        items: function () {
            var _this2 = this;

            var selected = this.get('selected') || [];
            var userObjects = this.get('users') || [];

            var users = userObjects.filter(function (user) {
                return !user.get('isVendor');
            }).map(function (user) {
                var name = user.get('name');
                var email = user.get('email');

                return {
                    id: user.get('id'),
                    label: name || email,
                    selected: selected.includes(user.get('id')),
                    user: user,
                    classNames: user.hasAnyRole(_this2.get('session.data.authenticated.accountId'), ['staff', 'staff-all']) ? 'staff' : ''
                };
            });

            return users.sortBy('label');
        }.property('users', 'selected'),

        actions: {
            onSelect: function onSelect(values, typeOfAction) {
                var selected = this.get('selected');

                console.log(typeOfAction, values, selected);

                if (typeOfAction === 'select') {
                    if (Ember.isArray(values) && Ember.isArray(selected)) {
                        values.forEach(function (v) {
                            if (!selected.includes(v)) {
                                selected.pushObject(v);
                            }
                        });

                        this.setBorders();
                    }
                } else if (typeOfAction === 'unselect') {
                    selected.forEach(function (item) {
                        if (!values.includes(item)) {
                            selected.removeObject(item);
                        }
                    });
                }

                this.sendAction('action', this.get('name'), values);
            },
            onBuild: function onBuild() {
                this.setBorders();
            }
        }
    });
});