define('huyang-common/mixins/creates-followers', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Mixin.create({
        session: Ember.inject.service(),

        createFollowers: function createFollowers(followers, buildingId, requestId) {
            var _this = this;

            // `followers` is an array of userIds and/or email addresses

            var emails = [];
            var followerUids = [];

            if (followers && followers.length) {
                followers.forEach(function (follower) {
                    if (follower.indexOf('@') > 0) {
                        emails.push(follower);
                    } else {
                        followerUids.push(follower);
                    }
                });
            }

            var promise = new Ember.RSVP.Promise(function (resolve) {
                if (emails.length) {
                    _this.get('session.currentAccount').then(function (account) {
                        account.createOccupants(emails, buildingId, requestId).then(function (users) {
                            Ember.run(function () {
                                var userIds = [];
                                var store = _this.store;

                                if (!store || store.type === 'service') {
                                    store = _this.get('store');
                                }

                                if (users) {
                                    users.forEach(function (u) {
                                        if (store) {
                                            store.pushPayload(u);
                                        }

                                        userIds.push(u.user.id);
                                    });
                                }
                                resolve({
                                    followerIds: followerUids.concat(userIds),
                                    newUserIds: userIds
                                });
                            });
                        });
                    });
                } else {
                    // followerIds contains only existing userIds
                    // resolve immediately with list of userIds
                    resolve({
                        followerIds: followers
                    });
                }
            });

            // so tests can call when this is done
            this.set('createFollowersPromise', promise);
            return promise;
        }
    });
});