define('huyang-common/components/followers-form', ['exports', 'huyang-common/components/dynamic-component', 'huyang-common/templates/components/followers-form'], function (exports, _dynamicComponent, _followersForm) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var computed = Ember.computed,
        observer = Ember.observer;
    exports.default = _dynamicComponent.default.extend({
        layout: _followersForm.default,
        classNames: ['followers-form'],

        session: Ember.inject.service(),

        didReceiveAttrs: function didReceiveAttrs() {
            this._super.apply(this, arguments);
            this.filterUsers();
        },


        sortedUsers: computed(function () {
            return this.get('users').sortBy('displayName');
        }),

        filterUsers: observer('followerIds.length', function () {
            var _this = this;

            var selectedStaff = [];
            var selectedOccupants = [];
            var unselectedStaff = [];
            var unselectedOccupants = [];

            this.get('sortedUsers').forEach(function (user) {
                var isSelected = _this.get('followerIds').indexOf(user.get('id')) > -1;
                var isStaff = _this.isStaff(user);

                // Current user should already be selected as originator
                if (isSelected && isStaff) {
                    selectedStaff.pushObject(user);
                } else if (isSelected) {
                    selectedOccupants.pushObject(user);
                } else if (isStaff) {
                    unselectedStaff.pushObject(user);
                } else if (_this.isTenant(user)) {
                    unselectedOccupants.pushObject(user);
                }
            });

            this.set('selectedStaff', selectedStaff);
            this.set('selectedOccupants', selectedOccupants);
            this.set('unselectedStaff', unselectedStaff);
            this.set('unselectedOccupants', unselectedOccupants);
        }),

        hasAnyOccupants: computed('selectedOccupants.length', 'newOccupants.length', function () {
            return this.get('selectedOccupants.length') > 0 || this.get('newOccupants.length') > 0;
        }),

        newOccupants: computed('followerIds.length', function () {
            return this.get('followerIds').filter(function (id) {
                return isNaN(Number(id));
            });
        }),

        dropdownLabel: computed('selectedUsers.length', function () {
            var text = this.get('selectedUsers.length') > 0 ? 'another' : 'a';

            return '<i class="fa fa-plus-circle"></i> Add ' + text + ' follower';
        }),

        isStaff: function isStaff(user) {
            var accountId = this.get('session.data.authenticated.accountId');

            return user.hasAnyRole(accountId, ['staff', 'staff-all']);
        },
        isTenant: function isTenant(user) {
            var accountId = this.get('session.data.authenticated.accountId');

            return user.hasAnyRole(accountId, ['tenant']);
        },


        actions: {
            selectUser: function selectUser(user) {
                // This "communicates" with parent by mutating passed-in array...
                this.get('followerIds').pushObject(user.get('id'));
            },
            addOccupantEmail: function addOccupantEmail(email) {
                // mixin:creates-followers will know what to do with an email instead of an ID
                this.get('followerIds').pushObject(email);
            },
            deselectUser: function deselectUser(userId) {
                this.get('followerIds').removeObject(userId);
            }
        }
    });
});