define('huyang-common/models/request-event', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        account: _emberData.default.belongsTo('account'),
        request: _emberData.default.belongsTo('request'),
        template: _emberData.default.belongsTo('request'),
        user: _emberData.default.belongsTo('user'),
        assignee: _emberData.default.belongsTo('user'),
        vendor: _emberData.default.belongsTo('vendor'),
        eventDate: _emberData.default.attr('date'),
        eventType: _emberData.default.attr(),
        info: _emberData.default.attr()
    });
});