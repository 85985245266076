define('huyang-common/components/gallery-thumb', ['exports', 'huyang-common/templates/components/gallery-thumb', 'huyang-common/mixins/shows-gallery'], function (exports, _galleryThumb, _showsGallery) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_showsGallery.default, {
    classNames: ['gallery-thumb'],
    classNameBindings: ['single'],
    layout: _galleryThumb.default,

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.preloadImage(this.get('img'));
    }
  });
});