define('huyang-common/components/image-gallery', ['exports', 'huyang-common/templates/components/image-gallery', 'huyang-common/mixins/shows-gallery'], function (exports, _imageGallery, _showsGallery) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_showsGallery.default, {
    layout: _imageGallery.default,
    galleryOptions: {
      hideShare: true,
      hideToggleFullScreen: true
    },
    loadedGalleryImages: [],
    loadedGalleryUrls: [],

    // `totalImages` and `thumbs` are sent in if there are more images
    // in that need to go in the gallery beyond what are shown as thumbs
    // (cost log images, for instance). Figure out which images aren't
    // preloaded and added to the gallery via a thumb and get it in there.
    // This is done inside of an observer to make sure all of the non-thumb
    // images are loaded into the gallery after thumb images to maintain
    // some sense of an intuitive gallery image order.
    //
    loadedGalleryImagesObserver: Ember.observer('loadedGalleryImages.[]', function () {
      var _this = this;

      if (this.get('totalImages') && this.get('thumbs')) {
        var allThumbsAreLoaded = this.get('loadedGalleryImages.length') === this.get('thumbs.length');
        if (allThumbsAreLoaded) {
          var nonThumbGalleryImages = this.get('totalImages').filter(function (i) {
            return _this.get('thumbs').indexOf(i) < 0;
          });

          nonThumbGalleryImages.forEach(function (ntgi) {
            _this.preloadImage(ntgi);
          });
        }
      }
    })
  });
});