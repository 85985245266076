define('huyang-common/components/location-picker', ['exports', 'huyang-common/templates/components/location-picker'], function (exports, _locationPicker) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        layout: _locationPicker.default,
        classNames: ['location-picker'],

        init: function init() {
            this._super.apply(this, arguments);
            // sendAction to update huyang-form with hasAllAccess value for validation
            this.sendAction('accessUpdated', this.get('hasAllAccess'));
        },


        actions: {
            accessUpdated: function accessUpdated(hasAllAccess) {
                this.sendAction('accessUpdated', hasAllAccess);
            },
            locationsUpdated: function locationsUpdated(event, params, selectedValues) {
                this.sendAction('locationsUpdated', event, params, selectedValues);
            }
        }
    });
});