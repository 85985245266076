define('huyang-common/components/helpful-hint', ['exports', 'huyang-common/templates/components/helpful-hint', 'huyang-common/components/dynamic-component'], function (exports, _helpfulHint, _dynamicComponent) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _dynamicComponent.default.extend({
        layout: _helpfulHint.default,
        classNames: ['helpful-hint']
    });
});