define('huyang-common/mixins/shows-gallery', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Mixin.create({
        preloadImage: function preloadImage(img) {
            var _this = this;

            var url = img.get('imageUrl');

            // `loadedGalleryUrls is a separate array of just the URLs that have been
            // loaded (or set to load) to prevent race conditions and duplicate images
            //
            if (this.get('loadedGalleryUrls').indexOf(url) === -1) {
                var preloadImg = new Image();

                this.get('loadedGalleryUrls').push(url);

                /*
                    photoswipe requires images have explicitly defined dimensions
                    so we need to download them and then find the width & height.
                     browser caching should give us the ability to do this without using
                    extra network traffic
                */
                preloadImg.onload = function (i) {
                    _this.get('loadedGalleryImages').pushObject({
                        src: url,
                        w: i.target.width,
                        h: i.target.height
                    });
                    img.set('doneLoading', true);
                };

                // load the image from the internet
                preloadImg.src = url;
            }
        },


        actions: {
            imageClicked: function imageClicked(img) {
                var url = img.get('imageUrl');

                this.get('gallery').init();

                this.get('gallery').listen('beforeChange', function () {
                    return window.preventArrowNav = true;
                });
                this.get('gallery').listen('close', function () {
                    return window.preventArrowNav = false;
                });

                var items = this.get('gallery').items;

                var scrollTo = 0;

                if (items) {
                    items.forEach(function (item, idx) {
                        var parts = url.split('?');
                        var itemParts = item.src.split('?');

                        if (itemParts[0] === parts[0]) {
                            scrollTo = idx;
                        }
                    });
                }

                this.get('gallery').goTo(scrollTo);

                if (typeof this.sendAction === 'function') {
                    this.sendAction('imageClicked', img);
                }
            }
        }
    });
});