define('huyang-common/components/add-followers-form', ['exports', 'huyang-common/templates/components/add-followers-form'], function (exports, _addFollowersForm) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        layout: _addFollowersForm.default,
        classNames: ['add-followers-form'],
        fields: function () {
            return [{
                name: 'followerIds',
                label: 'Add new followers',
                placeholder: 'Choose people who should be notified about updates to this request',
                userPlusEmailPicker: true,
                selectId: 'followerSelect',
                action: 'userPlusEmailChanged',
                users: this.get('users'),
                hide: false,
                value: this.get('followerIds'),
                validations: {
                    follower: { users: this.get('users') }
                }
            }];
        }.property('users', 'followerIds'),

        showError: function () {
            return this.get('isInvalid') && this.get('clickedSave');
        }.property('isInvalid', 'clickedSave'),

        actions: {
            changesSaved: function changesSaved(fv, onComplete /*, onError*/) {
                this.sendAction('action', fv.followerIds, onComplete);
            },
            cancel: function cancel() {
                this.sendAction('cancel');
            },
            followersUpdated: function followersUpdated(values) {
                this.set('followerIds', values);
                setTimeout(function () {
                    return Ember.$('.add-followers-form .select2-search__field').focus();
                }, 100);
            }
        } });
});