define('huyang-common/serializers/cost-log', ['exports', 'huyang-common/serializers/rest-new'], function (exports, _restNew) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _restNew.default.extend({
        serialize: function serialize(snapshot, options) {
            var data = this._super(snapshot, options);
            // add attachment id if it's present (not part of the the cost log model)
            // for single-request update/attach operation; adapters/cost-log sends to with-attach endpoint
            if (snapshot.record.attachment && snapshot.record.attachment.length) {
                data.attachment = snapshot.record.attachment;
            }
            return data;
        }
    });
});