define('huyang-common/components/chosen-select', ['exports', 'huyang-common/templates/components/chosen-select'], function (exports, _chosenSelect) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        layout: _chosenSelect.default,
        classNames: ['chosen-component'],

        setup: function () {
            var self = this;
            var selectId = '#' + this.get('selectId');

            Ember.$(selectId).off('change');

            var options = this.get('options') || {};
            // ember view helper doesn't pass through data-placeholder
            if (this.get('placeholder')) {
                Ember.$(selectId).attr('data-placeholder', this.get('placeholder'));
            }
            Ember.$(selectId).chosen(options);
            Ember.$(selectId).on('change', function (evt, params) {
                var selectedValues = Ember.$(this).val();
                self.sendAction('action', evt, params, selectedValues);
                // in case select changes the list
                Ember.$(selectId).trigger('chosen:updated');
            });
        }.on('didInsertElement'),

        triggerUpdate: function () {
            var selectId = '#' + this.get('selectId');
            // wait for select to finish rendering
            Ember.run.next(function () {
                Ember.$(selectId).trigger('chosen:updated');
            });
        }.observes('updated'),

        updateDisabled: function () {
            var selectId = '#' + this.get('selectId');
            Ember.$(selectId).prop('disabled', this.get('disabled')).trigger('chosen:updated');
        }.observes('disabled')
    });
});