define('huyang-common/models/request-type', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        api: Ember.inject.service(),

        account: _emberData.default.belongsTo('account', { async: false }),
        label: _emberData.default.attr('string'),
        iconClass: _emberData.default.attr('string'),
        active: _emberData.default.attr('boolean'),
        isDefault: _emberData.default.attr('boolean'),
        requestTypeBuildings: _emberData.default.hasMany('request-type-building', { async: true }),

        cssClass: function () {
            return 'huyang-' + this.get('iconClass');
        }.property('iconClass'),

        cssId: function () {
            return 'requestType' + this.get('id');
        }.property('requestType'),

        backgroundStyle: function () {
            return new Ember.String.htmlSafe('background-image:url(' + this.get('iconUrl') + ')');
        }.property('iconUrl'),

        setBuildings: function setBuildings(buildingIds) {
            var _this = this;

            // ember data doesn't support combining multiple requests, so call set buildings API method directly
            return new Ember.RSVP.Promise(function (resolve, reject) {
                return _this.get('api').ajax({
                    type: 'PUT',
                    url: '/api/' + localStorage.getItem('accountId') + '/requestTypes/' + _this.get('id') + '/buildings',
                    data: JSON.stringify({ 'buildings': buildingIds }),
                    dataType: 'json'
                }).then(function (response) {
                    // push records
                    // without this, tests fail with
                    // Assertion Failed: You have turned on testing mode, which disabled the run-loop's autorun.
                    // You will need to wrap any code with asynchronous side-effects in a run
                    Ember.run(function () {
                        response.requestTypeBuildings.forEach(function (rec) {
                            var rtb = rec.requestTypeBuilding;
                            var record = {
                                id: rtb.id,
                                requestType: rtb.requestType,
                                building: rtb.building
                            };
                            _this.store.push('request-type-building', record);
                        });
                        // remove deleted records from store
                        response.deleted.forEach(function (id) {
                            var rec = _this.store.peekRecord('request-type-building', id);
                            if (rec) {
                                rec.deleteRecord();
                            }
                        });
                    });
                    resolve();
                }, function (xhr, status, error) {
                    console.error('error saving request type for buildings', error);
                    reject();
                });
            });
        }
    });
});