define('huyang-common/components/color-picker', ['exports', 'huyang-common/templates/components/color-picker'], function (exports, _colorPicker) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        layout: _colorPicker.default,
        classNames: ['color-picker'],

        actions: {
            radioChanged: function radioChanged(value) {
                this.sendAction('radioChanged', value);
            }
        }
    });
});