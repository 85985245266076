define('huyang-common/mixins/sorts-data', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Mixin.create({
        savedKey: function () {
            return sessionStorage.getItem(this.get('sortSaveKey'));
        }.property().volatile(),

        savedDir: function () {
            return sessionStorage.getItem(this.get('sortSaveKey') + 'Dir');
        }.property().volatile(),

        sortDirection: function () {
            var direction = this.get('userSortDirection') || this.get('savedDir');
            if (direction) {
                return direction;
            }
            return this.get('defaultSortDirection') || 'asc';
        }.property('userSortDirection', 'defaultSortDirection', 'savedDir'),

        sortColumn: function () {
            if (!this.get('hasMultiSort')) {
                var column = this.get('userSortColumn') || this.get('savedKey') || this.get('defaultSortKey');

                if (column) {
                    return column;
                }

                var columns = this.get('columns');

                return columns.length ? columns[0]['key'] : null;
            }

            return null;
        }.property('userSortColumn', 'savedKey', 'defaultSortKey', 'columns.[]', 'sortColumns.[]'),

        sortedData: function () {
            var _this = this;

            if (this.get('hasMultiSort')) {
                // multi sort data only comes from server at the moment
                return this.get('data');
            }

            var data = this.get('data').sortBy(this.get('sortColumn'));

            if (this.get('sortDirection') === 'desc') {
                data.reverse();
            }

            // Computed property wasn't hitting and binding
            // directly to sortedData upstream broke things, so alas.
            // This is a way to pass the current sorted data up,
            // specifically for use with the asset nav buttons.
            Ember.run.next(function () {
                _this.set('sortedDataAlias', data);
            });

            return data;
        }.property('data', 'data.[]', 'sortColumn', 'sortDirection'),

        columnObjects: function () {
            var _this2 = this;

            if (this.get('hasMultiSort')) {
                // is using multiple sort
                var sortColumns = this.get('sortColumns').map(function (sc) {
                    if (typeof sc === 'string') {
                        // there is currently an ember bug where the query params don't get serialized correctly
                        // after transitioning to other routes (the array of arrays gets converted to an array of strings)
                        return sc.split(',');
                    }

                    return sc;
                });

                return this.get('columns').map(function (col) {
                    var colObj = Ember.Object.create(col);
                    var sortCol = sortColumns.find(function (sc) {
                        return sc[0] === col.key;
                    });

                    if (sortCol) {
                        colObj.set('isSorted', true);
                        colObj.set('sortDirectionAsc', sortCol[1] === 'asc');
                    } else {
                        colObj.set('isSorted', false);
                    }

                    return colObj;
                });
            } else {
                // is using single column sort
                var sortCol = this.get('sortColumn');

                return this.get('columns').map(function (col) {
                    var colObj = Ember.Object.create(col);
                    var isSorted = sortCol === colObj.get('key');

                    colObj.set('isSorted', isSorted);

                    if (isSorted) {
                        colObj.set('sortDirectionAsc', _this2.get('sortDirectionAsc'));
                    }

                    return colObj;
                });
            }
        }.property('columns', 'sortColumn', 'sortDirectionAsc', 'hasMultiSort', 'sortColumns.[]'),

        sortDirectionAsc: function () {
            return this.get('sortDirection') === 'asc';
        }.property('sortDirection'),

        hasMultiSort: function () {
            return Ember.isArray(this.get('sortColumns'));
        }.property('sortColumns.[]'),

        showMultiSortTooltip: function () {
            return this.get('hasMultiSort') && this.get('sortColumns.length') > 0;
        }.property('hasMultiSort', 'sortColumns.length'),

        actions: {
            sortBy: function sortBy(key) {
                var dir = 'asc';

                if (key === this.get('sortColumn')) {
                    // flip the direction
                    dir = this.get('sortDirection') === 'asc' ? 'desc' : 'asc';
                    this.set('userSortDirection', dir);
                } else {
                    this.set('userSortDirection', 'asc');
                }

                this.set('userSortColumn', key);

                if (this.get('sortSaveKey')) {
                    sessionStorage.setItem(this.get('sortSaveKey'), key);
                    sessionStorage.setItem(this.get('sortSaveKey') + 'Dir', dir);
                }
            }
        }
    });
});