define('huyang-common/components/validated-input', ['exports', 'huyang-common/templates/components/validated-input'], function (exports, _validatedInput) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        layout: _validatedInput.default,

        hasErrors: function () {
            if (!this.get('errors.length')) {
                this.set('showError', false);
            }
        }.observes('errors.length'),

        onSubmit: function () {
            // show all errors on submit
            if (this.get('submitted') && this.get('errors.length')) {
                this.set('showError', true);
            }
        }.observes('submitted'),

        actions: {
            showErrors: function showErrors() {
                if (this.get('errors.length')) {
                    this.set('showError', true);
                }
            }
        },

        formGroupClass: Ember.computed('showError', 'errors', function () {
            var c = 'form-group';
            if (this.get('showError') && this.get('errors.length')) {
                c += ' has-error';
            }
            return c;
        })
    });
});