define('huyang-common/serializers/request', ['exports', 'huyang-common/serializers/rest-new'], function (exports, _restNew) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _restNew.default.extend({
        serialize: function serialize(snapshot, options) {
            var data = this._super(snapshot, options);
            // add comment body if it's present (not part of the the request model)
            // for single-request update/comment operation; adapters/request sends to comment endpoint
            if (snapshot.record.commentBody) {
                data.commentBody = snapshot.record.commentBody;
                data.commentChannelId = snapshot.record.commentChannelId;
            }
            // add attachment id if it's present (not part of the the request  model)
            // for single-request update/attach operation; adapters/request sends to attach endpoint
            if (snapshot.record.attachment && snapshot.record.attachment.length) {
                data.attachment = snapshot.record.attachment;
            }
            // add followerList id if it's present (not part of the the request model)
            // so API can add/create followers as needed before request is committed
            if (snapshot.record.followerUsers && snapshot.record.followerUsers.length) {
                data.followerUsers = snapshot.record.followerUsers;
            }
            // add newUsers list if present (not part of the the request model)
            // so API can send welcome to newly created followers with link to request
            if (snapshot.record.newUsers && snapshot.record.newUsers.length) {
                data.newUsers = snapshot.record.newUsers;
            }
            if (snapshot.record.checklist) {
                data.checklist = snapshot.record.get('checklist.id');
            }
            return data;
        },

        extractMeta: function extractMeta(store, typeClass, payload) {
            // TODO: I'm not sure if this is needed anymore
            // https://www.emberjs.com/blog/2015/06/18/ember-data-1-13-released.html
            return payload.meta;
        }
    });
});