define('huyang-common/services/api', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        //session: Ember.inject.service('session:custom'),

        getAuthCookie: function getAuthCookie() {
            var value = '; ' + document.cookie;
            var parts = value.split('; auth_token=');
            if (parts.length === 2) {
                return parts.pop().split(';').shift();
            }
        },

        getHost: function getHost() {
            var config = Ember.getOwner(this).resolveRegistration('config:environment');
            if (!config.API_DOMAIN) {
                return '';
            }
            var protocol = config.API_PROTOCOL || 'http://';
            var subdomain = window.location.hostname.split('.')[0];
            return '' + protocol + subdomain + '.' + config.API_DOMAIN;
        },

        getAuthorizationHeader: function getAuthorizationHeader() {
            // try session, then cookie
            var token = localStorage.getItem('token');
            if (!token) {
                token = this.getAuthCookie();
            }
            return token ? 'Bearer ' + token : null;
        },

        getHeaders: function getHeaders() {
            var auth = this.getAuthorizationHeader();
            return auth ? { Authorization: auth } : {};
        },

        /*
            params = { type: 'POST', url: '/api/xyz' }
            url is required
        */
        ajax: function ajax(params) {
            params.url = this.getHost() + params.url;
            var header = this.getAuthorizationHeader();
            if (header) {
                var headers = params.headers || {};
                headers.Authorization = header;
                params.headers = headers;
            }
            return Ember.$.ajax(params);
        },

        // for getting build.txt
        ajaxWithoutAuth: function ajaxWithoutAuth(params) {
            params.url = this.getHost() + params.url;
            return Ember.$.ajax(params);
        }
    });
});