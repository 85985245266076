define('huyang-common/models/request-comment', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        request: _emberData.default.belongsTo('request', { async: true }),
        created: _emberData.default.attr('date'),
        author: _emberData.default.belongsTo('user', { async: true }),
        source: _emberData.default.attr('string'), // email, SMS, web
        tenantVisible: _emberData.default.attr('boolean'),
        body: _emberData.default.attr('string'),
        renderedBody: _emberData.default.attr('string'),
        attachments: _emberData.default.hasMany('request-attachment', { async: true }),
        channel: _emberData.default.belongsTo('channel', { async: true }),

        images: function () {
            return this.get('attachments').filter(function (att) {
                return att.get('isImage');
            });
        }.property('attachments', 'attachments.[]'),

        nonImageFiles: function () {
            return this.get('attachments').filter(function (att) {
                return !att.get('isImage');
            });
        }.property('attachments', 'attachments.[]'),

        bodyWithBreaks: function () {
            var body = this.get('body') || '';
            return body.replace(/(\r|\n|\r\n)/gm, '  \n');
        }.property('body'),

        isCreationComment: function () {
            // comment was auto-posted when the request was created
            return this.get('source') === 'request';
        }.property('source'),

        isForwardedComment: function () {
            // comment was part of sending a request to a vendor
            return this.get('source') === 'vendor-request';
        }.property('source'),

        isFromEmail: function () {
            return this.get('source') === 'email';
        }.property('source'),

        belongsToUser: function () {
            var userId = parseInt(localStorage.getItem('userId'));
            var authorId = parseInt(this.get('author.id'));

            return authorId === userId;
        }.property('author', 'session.data.authenticated.userId'),

        truncatedForPrint: function () {
            if (this.get('body').length > 140) {
                return this.get('body').substring(0, 140) + '...';
            }

            return this.get('body');
        }.property('body')
    });
});