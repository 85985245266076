define('huyang-common/components/huyang-form-phone', ['exports', 'huyang-common/components/dynamic-component', 'huyang-common/templates/components/huyang-form-phone'], function (exports, _dynamicComponent, _huyangFormPhone) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _dynamicComponent.default.extend({
    layout: _huyangFormPhone.default,
    intlTelInput: Ember.inject.service(),

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.get('intlTelInput').initInput(this.$('#hfp-phone'));
    },


    /*
        make sure these are also observed below in "watch for changes."
        these are the properties that will get bubbled back up to parent components
        whenever they are updated.
    */
    propertiesToSend: ['phone'],

    watchForChanges: function () {
      // this.notifyOfChanges is part of DynamicComponent
      Ember.run.once(this, 'notifyOfChanges');
    }.observes('phone').on('init')
  });
});