define('huyang-common/components/date-time-picker', ['exports', 'huyang-common/templates/components/date-time-picker'], function (exports, _dateTimePicker) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        layout: _dateTimePicker.default,

        hoursButton: '.timepicker-hour[data-action="showHours"]',
        minutesButton: '.timepicker-minute[data-action="showMinutes"]',
        toggleEditButton: 'a[data-action="togglePicker"]',

        // set options.defaultDate to a moment to select it
        options: {},
        picker: null,

        pickerId: Ember.computed(function () {
            return '#' + this.elementId + 'Picker';
        }),

        setup: function () {
            var _this = this;

            var options = this.get('options') || {};

            if (this.get('timezone')) {
                options.timeZone = this.get('timezone');
            }
            if (options.defaultDate && this.get('timezone')) {
                options.defaultDate = moment.tz(options.defaultDate, this.get('timezone'));
            }
            // This prevents an error being thrown if the dueDate (defaultDate) is too far in the past
            if (options.minDate > options.defaultDate) {
                options.minDate = options.defaultDate;
            }
            var picker = Ember.$(this.get('pickerId')).datetimepicker(options);

            picker.on('dp.change', function (e) {
                // don't send action on clear
                if (!e.date) {
                    return;
                }

                var date = moment(e.date);

                // Use user timezone if present, otherwise use browser
                if (_this.get('timezone')) {
                    date.tz(_this.get('timezone'));
                }

                if (!_this.get('skipPickerAction')) {
                    _this.sendAction('action', date);
                }
            });

            if (this.get('timezoneMismatch')) {
                var abbrev = this.get('timezoneAbbreviation'),
                    timezone = '<p class="timezone-abbrev">Time displayed in ' + abbrev + '</p>';
                Ember.$('.timepicker-picker').append(timezone);
            }

            this.set('picker', picker);
            this.addHighlight();

            // Workaround for https://github.com/Eonasdan/bootstrap-datetimepicker/issues/1789,
            // where hour/minutes might erroneously stay enabled/disabled on date change
            //
            // Whenever hours or minutes buttons are clicked into, update their clickability
            // manually depeneding on minDate and new selected date. Also, when clicking into
            // hours/minutes buttons and then clicking "edit date" without selecting a new time,
            // update the hours/minutes buttons when clicking back into "edit time"

            if (options.minDate) {
                var $hoursButton = Ember.$(this.hoursButton);
                var $minutesButton = Ember.$(this.minutesButton);
                var $toggleEditButton = Ember.$(this.toggleEditButton);

                $hoursButton.on('click', function () {
                    return _this.updateHourButtons();
                });
                $minutesButton.on('click', function () {
                    return _this.updateMinuteButtons();
                });
                $toggleEditButton.on('click', function (e) {
                    // This button is for both "edit time" and "edit date", but the "glyphicon-..." class changes
                    if (e.target.className.indexOf('glyphicon-time') > -1) {
                        var $hoursSection = Ember.$('.timepicker-hours')[0];
                        var $minutesSection = Ember.$('.timepicker-minutes')[0];

                        // Only update hours/minutes in this case if one of the menus is already open
                        if ($hoursSection.style.display !== 'none') {
                            _this.updateHourButtons();
                        } else if ($minutesSection.style.display !== 'none') {
                            _this.updateMinuteButtons();
                        }
                    }
                });
            }
        }.on('didInsertElement'),

        teardown: function () {
            this.get('picker').off('dp.change');
            if (this.get('options.minDate')) {
                Ember.$(this.hoursButton).off('click');
                Ember.$(this.minutesButton).off('click');
                Ember.$(this.toggleEditButton).off('click');
            }
        }.on('willDestroyElement'),

        setDate: function () {
            if (!this.get('picker')) {
                return;
            }

            var pid = this.get('pickerId');

            if (this.get('selectedDate')) {
                // Verify that date is a moment before passing in, otherwise this will not set
                Ember.$(pid).data('DateTimePicker').date(moment(this.get('selectedDate')));
            } else {
                Ember.$(pid).data('DateTimePicker').date(null);
            }
        }.observes('selectedDate'),

        timezoneMismatch: function () {
            var tz = this.get('timezone');
            if (!tz) {
                return false;
            }
            var local = moment().format('YYYY-MM-DD HH:mm');
            var withTz = moment.tz(moment(), tz).format('YYYY-MM-DD HH:mm');
            return local !== withTz;
        }.property('timezone'),

        timezoneAbbreviation: function () {
            return moment.tz.zone(this.get('timezone')).abbr(moment().valueOf());
        }.property('timezone'),

        addHighlight: function () {
            var pid = this.get('pickerId');

            // remove all highlight classes
            Ember.$(pid + ' td.day').removeClass('highlight');
            if (this.get('highlightDate')) {
                // data-day = 08/26/2016
                var dt = moment(this.get('highlightDate')).format('MM/DD/YYYY');
                Ember.$(pid + ' td[data-day="' + dt + '"]').addClass('highlight');
            }
        }.observes('highlightDate'),

        updateHourButtons: function updateHourButtons() {
            var picker = this.get('picker');
            var minDate = moment(this.get('options').minDate);
            var date = moment(picker.data().date);

            var isPM = date.hour() > 11;
            var $hourButtons = Ember.$('td.hour');

            // minHour depends on what the minutes are currently set to
            var minHour = date.minute() >= minDate.minute() ? minDate.hour() : minDate.hour() + 1;

            for (var i = 0, l = $hourButtons.length; i < l; i++) {
                var $hour = $hourButtons[i];

                if (minDate.isBefore(date, 'day')) {
                    // Time doesn't matter if it's a later date than minDate
                    $hour.classList.remove('disabled');
                } else {
                    // Convert am/pm-based hour on button to 24-hour time
                    var hour = parseInt($hour.textContent);

                    if (isPM && hour !== 12) {
                        hour += 12;
                    } else if (!isPM && hour === 12) {
                        hour = 0;
                    }

                    if (hour >= minHour) {
                        $hour.classList.remove('disabled');
                    } else {
                        $hour.classList.add('disabled');
                    }
                }
            }
        },

        updateMinuteButtons: function updateMinuteButtons() {
            var picker = this.get('picker');
            var minDate = moment(this.get('options').minDate);
            var date = moment(picker.data().date);
            var $minuteButtons = Ember.$('td.minute');

            for (var i = 0, l = $minuteButtons.length; i < l; i++) {
                var $minute = $minuteButtons[i];

                if (minDate.isBefore(date, 'day')) {
                    // Doesn't matter what the minute is if it's a later date than minDate
                    $minute.classList.remove('disabled');
                } else {
                    // Clickable if minutes are greater, or if it's a later hour
                    var minute = parseInt($minute.textContent);

                    if (minute >= minDate.minute() || date.hour() > minDate.hour()) {
                        $minute.classList.remove('disabled');
                    } else {
                        $minute.classList.add('disabled');
                    }
                }
            }
        }
    });
});