define('huyang-common/components/request-comments', ['exports', 'huyang-common/templates/components/request-comments'], function (exports, _requestComments) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        layout: _requestComments.default,
        showVisibilitySelect: false,
        linkProfiles: true,
        tenantVisible: false,
        isLoading: false,

        actions: {
            changeVisibility: function changeVisibility(comment) {
                console.log('change ', comment);
            },
            imageClicked: function imageClicked(img) {
                this.sendAction('imageClicked', img);
            },
            addComment: function addComment(info, attachments, callback) {
                this.sendAction('addComment', info, attachments, callback);
            },
            deleteComment: function deleteComment(comment) {
                this.sendAction('deleteComment', comment);
            }
        },

        setup: function () {
            // init bootstrap tooltips
            if (typeof Ember.$().tooltip === 'function') {
                this.$('[data-toggle="tooltip"]').tooltip();
            }
        }.on('didInsertElement'),

        sortedComments: function () {
            var _this = this;

            var comments = this.get('request.comments');

            if (this.get('hideCreationComment')) {
                comments = comments.filter(function (c) {
                    return !c.get('isCreationComment');
                });
            }

            if (this.get('hideForwardedComment')) {
                comments = comments.filter(function (c) {
                    return !c.get('isForwardedComment');
                });
            }

            if (this.get('channel')) {
                comments = comments.filter(function (comment) {
                    return comment.get('channel.id') === _this.get('channel.id');
                });
            }

            if (this.get('checklistItem')) {
                comments = this.get('checklistItem.comments');
            }

            return comments.sortBy('created');
        }.property('request.comments.@each.created', 'channel', 'hideCreationComment', 'checklistItem')
    });
});