define('huyang-common/components/thumbnail-grid', ['exports', 'huyang-common/templates/components/thumbnail-grid', 'huyang-common/templates/components/thumbnail-grid/one', 'huyang-common/templates/components/thumbnail-grid/two', 'huyang-common/templates/components/thumbnail-grid/three', 'huyang-common/templates/components/thumbnail-grid/four'], function (exports, _thumbnailGrid, _one, _two, _three, _four) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        classNames: ['thumbnail-grid'],

        actions: {
            imageClicked: function imageClicked(img) {
                this.sendAction('imageClicked', img);
            }
        },

        layout: function () {
            var len = this.get('images.length');

            if (len === 1) {
                return _one.default;
            } else if (len === 2) {
                return _two.default;
            } else if (len === 3) {
                return _three.default;
            } else if (len > 3) {
                return _four.default;
            }

            return _thumbnailGrid.default;
        }.property('images.length'),

        first: function () {
            if (this.get('images.length') >= 1) {
                return this.get('images')[0];
            }

            return undefined;
        }.property('images.[]'),

        remainingImages: function () {
            var len = this.get('images.length');

            if (len > 1) {
                return this.get('images').slice(1, 4);
            }

            return [];
        }.property('images.[]'),

        triggerRerender: function () {
            this.rerender();
        }.observes('images.[]')
    });
});