define('huyang-common/components/request-card', ['exports', 'huyang-common/templates/components/request-card'], function (exports, _requestCard) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        layout: _requestCard.default,
        classNames: ['request-card'],
        classNameBindings: ['request.status'],

        hideImage: false,

        click: function click() {
            this.get('rowClicked')(this.get('request.id'));
        },
        rowClicked: function rowClicked() /* requestId */{},


        showImage: Ember.computed('request.firstImage', function () {
            return this.get('request.firstImage') && !this.get('hideImage');
        })
    });
});