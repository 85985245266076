define('huyang-common/validators/subdomain', ['exports', 'ember-validations/validators/base'], function (exports, _base) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _base.default.extend({
        call: function call() {
            var valid = /^([a-zA-Z]|\d|\-)+$/i;
            var subdomain = this.model.get(this.property);
            if (_.isUndefined(subdomain)) {
                // not set yet; nothing to do
                return;
            }
            if (this.options.current && this.options.current === subdomain) {
                // ok to keep current value
                return;
            }

            if (subdomain && (!valid.test(subdomain) || subdomain.charAt(0) === '-' || subdomain.charAt(subdomain.length - 1) === '-')) {
                this.errors.pushObject('can only contain letters, numbers, and dashes.');
                return;
            }

            var exists = this.get('api').ajax({
                url: '/api/subdomain_exists',
                async: false,
                data: { subdomain: subdomain }
            }).responseText;
            if (exists === 'true') {
                this.errors.pushObject(subdomain + ' is already in use');
            }
        }
    });
});