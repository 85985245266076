define('huyang-common/helpers/saved-count', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.savedCount = savedCount;


  // Simple helper to return the number of saved records in a collection.
  // It's designed for a fairly specific use case atm:
  // asset sidebar items should not include new-but-not-saved records in their count.
  //
  // Params:
  //  [0] collection
  //  [1] property
  // Usage:
  //  {{saved-count category "assets"}}
  function savedCount(params /*, hash*/) {
    return params[0].get('' + params[1]).filterBy('isNew', false).get('length');
  }

  exports.default = Ember.Helper.helper(savedCount);
});