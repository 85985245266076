define('huyang-common/models/search-result', ['exports', 'ember-data', 'huyang-common/mixins/displays-priority', 'huyang-common/utils/time'], function (exports, _emberData, _displaysPriority, _time) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend(_displaysPriority.default, {
        asset: _emberData.default.attr(),
        building: _emberData.default.belongsTo('building', { async: true }),
        buildingName: _emberData.default.attr('string'),
        subspace: _emberData.default.belongsTo('subspace', { async: true }),
        subspaceName: _emberData.default.attr('string'),
        title: _emberData.default.attr('string'), // request only
        body: _emberData.default.attr('string'),
        requestType: _emberData.default.belongsTo('requestType', { async: true }), // request only
        requestLabel: _emberData.default.belongsTo('requestLabel', { async: true }), // request only
        schedule: _emberData.default.belongsTo('requestSchedule', { async: true }), // request only
        created: _emberData.default.attr('date'),
        updated: _emberData.default.attr('date'),
        dueDate: _emberData.default.attr('date'),
        // {title|body|body_comment: [ 'some <em>text</em>'' ] }
        highlight: _emberData.default.attr(),
        status: _emberData.default.attr('string'),
        workOrder: _emberData.default.attr('number'),
        priority: _emberData.default.attr('number'),
        originator: _emberData.default.belongsTo('user', { async: true }),
        owner: _emberData.default.belongsTo('user', { async: true }),
        description: Ember.computed.alias('body'),
        vendorResponse: _emberData.default.attr('string'),
        timeLoggedBillable: _emberData.default.attr('number'),
        timeLoggedNonBillable: _emberData.default.attr('number'),
        timeLoggedTotal: _emberData.default.attr('number'),
        costTotal: _emberData.default.attr('number'),
        costBillableTotal: _emberData.default.attr('number'),
        costNonBillableTotal: _emberData.default.attr('number'),
        latestComment: _emberData.default.attr('string'),

        firstParagraph: function () {
            var body = this.get('body') || '';
            if (body.length < 300) {
                return body;
            }
            // first space after 300 characters
            var pos = body.indexOf(' ', 300);
            if (pos > 0 && pos < body.length) {
                return body.substring(0, pos) + ' ...';
            } else {
                return body;
            }
        }.property('body'),

        createdOrUpdated: function () {
            return this.get('updated') ? this.get('updated') : this.get('created');
        }.property('created', 'updated'),

        titleHighlighted: function () {
            if (this.get('highlight.title')) {
                return Ember.String.htmlSafe(this.get('highlight.title')[0]);
            }
            return this.get('title');
        }.property('highlight'),

        bodyHighlighted: function () {
            if (this.get('highlight.body') && this.get('highlight.body').length > 0) {
                return Ember.String.htmlSafe(this.get('highlight.body').join('<br>'));
            }
            return this.get('firstParagraph');
        }.property('highlight.body'),

        commentsHighlighted: function () {
            if (this.get('highlight.comment_body') && this.get('highlight.comment_body').length > 0) {
                return Ember.String.htmlSafe(this.get('highlight.comment_body').join('<br>'));
            }
            return null;
        }.property('highlight.comment_body'),

        statusCssClass: function () {
            if (this.get('status') === 'open') {
                return 'fa fa-circle-o';
            }
            if (this.get('status') === 'closed') {
                return 'status fa fa-check-circle-o';
            }
        }.property('status'),

        location: function () {
            var name = this.get('buildingName');

            if (this.get('subspaceName')) {
                name += ', ' + this.get('subspaceName');
            }

            return name;
        }.property('buildingName', 'subspaceName'),

        durationToHoursMinutes: function durationToHoursMinutes(dur) {
            var hours = Math.floor(dur.asHours());
            var minutes = Math.round(dur.subtract(hours, 'hours').asMinutes());
            return (0, _time.timerString)(hours, minutes, null, { zeroIfEmpty: false });
        },

        timeLoggedBillableString: function () {
            return this.durationToHoursMinutes(moment.duration(this.get('timeLoggedBillable') || 0, 'seconds'));
        }.property('timeLoggedBillable'),

        timeLoggedNonBillableString: function () {
            return this.durationToHoursMinutes(moment.duration(this.get('timeLoggedNonBillable') || 0, 'seconds'));
        }.property('timeLoggedNonBillable'),

        timeLoggedTotalString: function () {
            return this.durationToHoursMinutes(moment.duration(this.get('timeLoggedTotal') || 0, 'seconds'));
        }.property('timeLoggedTotal')
    });
});