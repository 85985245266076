define('huyang-common/mixins/validates-email', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Mixin.create({
        emailValidations: {
            presence: { message: 'Email must be present' },
            format: {
                with: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+'"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                allowBlank: false,
                message: 'Email must be valid'
            }
        },
        isEmail: function isEmail(str) {
            return this.get('emailValidations.format.with').test(str);
        }
    });
});