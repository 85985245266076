define('huyang-common/validators/time-log', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.validateHours = validateHours;
    exports.validateEndTime = validateEndTime;
    var get = Ember.get,
        isEmpty = Ember.isEmpty;


    // The 'current value' of a changeset is either what it's been changed to or,
    // if unchanged, the value from the original content object
    function currentValue(prop, changes, content) {
        if (changes && prop in changes) {
            return changes[prop];
        }

        return get(content, prop);
    }

    // Coming from inputs the number will be a string, so !"0" == false
    function isEmptyOrZero(val) {
        return isEmpty(val) || Number(val) === 0;
    }

    function validateHours() {
        return function (key, hours, oldHours, changes, content) {
            var minutes = currentValue('minutes', changes, content);

            // They cannot both be empty OR both zero
            if (isEmptyOrZero(hours) && isEmptyOrZero(minutes)) {
                return 'Either minutes or hours must be provided';
            }

            return true;
        };
    }

    function validateEndTime() {
        return function (key, endTime, oldEndTime, changes, content) {
            if (!endTime) {
                return 'End time must be present';
            }

            // End time should not be in the future
            if (endTime > new Date()) {
                return 'End time cannot be in the future';
            }

            // End time should not be over month prior to request.created
            var earliest = moment(get(content, 'request.created')).subtract(1, 'month').toDate();

            if (endTime < earliest) {
                return 'End time must be close to or later than request creation';
            }

            return true;
        };
    }

    exports.default = { hours: validateHours, endTime: validateEndTime };
});