define('huyang-common/mixins/formats-list-strings', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Mixin.create({
        prepareListString: function prepareListString(objects, paths) {
            var str = '';
            var len = objects.get('length');

            if (len > 0) {
                var stringPath = void 0;

                if (Ember.isArray(paths)) {
                    // fall back between properties (name & email usually)
                    paths.forEach(function (path) {
                        if (!stringPath && objects.get('firstObject.' + path)) {
                            stringPath = path;
                        }
                    });
                } else {
                    // just use the property
                    stringPath = paths;
                }

                str = objects.get('firstObject.' + stringPath);

                if (len === 2) {
                    str += ' & ' + objects.objectAt(1).get(stringPath);
                } else if (len > 2) {
                    str += ' (+ ' + (len - 1) + ' more)';
                }
            }

            return str;
        }
    });
});