define('huyang-common/mixins/repeats', ['exports', 'huyang-common/utils/rrule-utils', 'huyang-common/utils/date-utils'], function (exports, _rruleUtils, _dateUtils) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    var _slicedToArray = function () {
        function sliceIterator(arr, i) {
            var _arr = [];
            var _n = true;
            var _d = false;
            var _e = undefined;

            try {
                for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
                    _arr.push(_s.value);

                    if (i && _arr.length === i) break;
                }
            } catch (err) {
                _d = true;
                _e = err;
            } finally {
                try {
                    if (!_n && _i["return"]) _i["return"]();
                } finally {
                    if (_d) throw _e;
                }
            }

            return _arr;
        }

        return function (arr, i) {
            if (Array.isArray(arr)) {
                return arr;
            } else if (Symbol.iterator in Object(arr)) {
                return sliceIterator(arr, i);
            } else {
                throw new TypeError("Invalid attempt to destructure non-iterable instance");
            }
        };
    }();

    exports.default = Ember.Mixin.create({
        session: Ember.inject.service(),

        units: ['day', 'week', 'month', 'year'],

        setup: function () {
            // Use schedule to independently set repeat rule and after close options,
            // otherwise use sensible defaults.. Both repeat rule and after close options
            // must be set (for UI), regardless of which the schedule uses (if present)

            var defaultDate = void 0;
            var now = moment();
            var minDate = moment(now);
            var timezone = this.get('session.currentUser.timezone');

            if (timezone) {
                minDate.tz(timezone);
            }
            // Default minimum date to closest hour from now
            minDate.add(now.minute() < 30 ? 1 : 2, 'hour').startOf('hour');

            if (this.get('schedule')) {
                // For existing schedules, advance start date to next create date.
                // If nextCreate is in past (for paused templates), set minDate to same
                defaultDate = moment(this.get('schedule.nextCreate'));
                if (defaultDate < now) {
                    minDate = defaultDate.clone();
                }
            } else {
                // If schedule is not present, default to closest hour
                defaultDate = minDate.clone();
                defaultDate.set('hour', 8).startOf('hour');
                if (defaultDate < minDate) {
                    defaultDate.add(1, 'day');
                }
            }

            this.set('defaultDate', defaultDate);
            this.set('minDate', minDate);

            var repeatRule = this.get('schedule.repeatRule');

            if (repeatRule) {
                if (this.get('schedule.active')) {
                    // Use nextCreate date
                    this.set('startDate', moment(this.get('schedule.nextCreate')));
                } else {
                    // Reset start date to now, because nextCreate might not
                    // be valid for paused templates
                    this.set('startDate', moment());
                }

                // Parse string manually rather than create RRule object, since it's easier to work with
                // and doesn't rely on custom date conversion (eg 2016-09-25T12:00:00.000Z to 20160925T120000Z)
                var rule = (0, _rruleUtils.rruleToObj)(repeatRule);

                this.set('frequency', rule.freq);
                this.set('interval', Number(rule.interval || 1));

                if (rule.freq === 'monthly' && rule.bymonthday) {
                    this.set('monthlyRepeat', 'date');
                } else if (rule.freq === 'monthly') {
                    this.set('monthlyRepeat', 'weekday');
                } else if (rule.freq === 'weekly' && rule.byday) {
                    // Select weekdays if the `byday` string (eg 'th,fr') includes abbreviated name
                    this.get('weekdays').forEach(function (day) {
                        var abbrev = day.get('name').slice(0, 2).toLowerCase();
                        day.set('selected', rule.byday.indexOf(abbrev) > -1);
                    });
                }

                if (rule.freq !== 'monthly') {
                    this.set('monthlyRepeat', 'date');
                }
            } else {
                // Default calendar date repeat to monthly by day of month
                this.set('startDate', defaultDate);
                this.set('frequency', 'monthly');
                this.set('interval', 1);
                this.set('monthlyRepeat', 'date');
            }

            var afterClose = this.get('schedule.afterClose');

            if (afterClose) {
                // e.g. "1 months"
                var _afterClose$split = afterClose.split(' '),
                    _afterClose$split2 = _slicedToArray(_afterClose$split, 2),
                    interval = _afterClose$split2[0],
                    unit = _afterClose$split2[1];

                this.set('closeInterval', Number(interval));
                this.set('closeUnit', unit);
            } else {
                this.set('closeInterval', 1);
                this.set('closeUnit', 'months');
            }

            var dueAfter = this.get('schedule.dueAfter');

            if (dueAfter) {
                // e.g. "1 weeks"
                var _dueAfter$split = dueAfter.split(' '),
                    _dueAfter$split2 = _slicedToArray(_dueAfter$split, 2),
                    _interval = _dueAfter$split2[0],
                    _unit = _dueAfter$split2[1];

                this.set('dueAfterInterval', _interval);
                this.set('dueAfterUnit', _unit);
                this.set('setDueAfter', true);
            } else {
                this.set('dueAfterInterval', 1);
                this.set('dueAfterUnit', 'weeks');
                if (!this.get('schedule') && this.get('isProgram')) {
                    this.set('setDueAfter', true);
                }
            }

            this.set('previousClose', this.get('schedule.previousClose'));
            this.set('previousRelabel', this.get('schedule.previousRelabel'));
            this.set('previousLabel', this.get('schedule.previousLabel'));

            this.set('previewDateCount', 3);

            // Repeat by either date or after closed per schedule if present
            if (this.get('program')) {
                this.set('allowBalancing', this.get('program.allowBalancing'));
                if (this.get('allowBalancing')) {
                    this.set('repeatBy', 'tikkit');
                    this.set('managedRepeatBy', this.get('program.balancingSchedule'));
                } else {
                    this.set('repeatBy', 'date');
                }
            } else if (this.get('schedule')) {
                this.set('repeatBy', this.get('schedule.afterClose') ? 'closed' : 'date');
            } else {
                this.set('repeatBy', 'date');
            }

            if (this.get('program.balancingSchedule')) {
                this.set('managedRepeatBy', this.get('program.balancingSchedule'));
            } else {
                this.set('managedRepeatBy', 'month');
            }

            this.sendUpdate();
        }.on('init'),

        postRender: function () {
            this.sendAction('rendered');
        }.on('didInsertElement'),

        repeatAfterClose: Ember.computed.equal('repeatBy', 'closed'),
        repeatByDate: Ember.computed.equal('repeatBy', 'date'),
        repeatTikkitManage: Ember.computed.equal('repeatBy', 'tikkit'),

        showExtraFooterOptions: Ember.computed.or('repeatByDate', 'repeatTikkitManage'),

        isWeekly: Ember.computed.equal('frequency', 'weekly'),
        isMonthly: Ember.computed.equal('frequency', 'monthly'),

        isMonthlyByWeekday: function () {
            return this.get('isMonthly') && this.get('monthlyRepeat') === 'weekday';
        }.property('isMonthly', 'monthlyRepeat'),

        isMonthlyByDate: function () {
            return this.get('isMonthly') && this.get('monthlyRepeat') === 'date';
        }.property('isMonthly', 'monthlyRepeat'),

        startDayLabel: function () {
            return this.get('startDate').format('dddd');
        }.property('startDate'),

        datePickerOptions: function () {
            return {
                inline: true,
                sideBySide: false,
                minDate: this.get('minDate'),
                defaultDate: this.get('defaultDate'),
                stepping: 5
            };
        }.property(),

        dueAfter: function () {
            if (!this.get('setDueAfter')) {
                return;
            }
            return this.get('dueAfterInterval') + ' ' + this.get('dueAfterUnit');
        }.property('setDueAfter', 'dueAfterInterval', 'dueAfterUnit'),

        dueAfterUnits: function () {
            var _this = this;

            return this.get('units').map(function (u) {
                return _this.pluralize(u, _this.get('dueAfterInterval'));
            });
        }.property('dueAfterInterval'),

        dueAfterUnitLabel: function () {
            // De-pluralize for single interval
            var unit = this.get('dueAfterUnit');
            if (+this.get('dueAfterInterval') === 1 && unit.slice(-1) === 's') {
                unit = unit.slice(0, -1);
            }
            return unit;
        }.property('dueAfterUnit', 'dueAfterInterval'),

        closeUnits: function () {
            var _this2 = this;

            return this.get('units').map(function (u) {
                return _this2.pluralize(u, _this2.get('closeInterval'));
            });
        }.property('closeInterval'),

        closeUnitLabel: function () {
            // De-pluralize for single interval
            var unit = this.get('closeUnit');
            if (this.get('closeInterval') === 1 && unit.slice(-1) === 's') {
                unit = unit.slice(0, -1);
            }
            return unit;
        }.property('closeUnit', 'closeInterval'),

        frequencies: [{ id: 'daily', label: 'day(s)' }, { id: 'weekly', label: 'week(s)' }, { id: 'monthly', label: 'month(s)' }, { id: 'yearly', label: 'year(s)' }], // Store as -ly for RRule

        intervals: function () {
            var arr = [];
            for (var i = 1; i < 31; i++) {
                arr.push(i);
            }
            return arr;
        }.property(),

        intervalLabel: function () {
            var labels = {
                daily: 'day',
                weekly: 'week',
                monthly: 'month',
                yearly: 'year'
            };
            return this.pluralize(labels[this.get('frequency')], this.get('interval'));
        }.property('frequency', 'interval'),

        pluralize: function pluralize(str, num) {
            var s = Number(num) === 1 ? '' : 's';
            return str + s;
        },

        sendUpdate: function () {
            if (this.get('repeatAfterClose')) {
                var interval = this.get('closeInterval') + ' ' + this.get('closeUnit');
                this.sendAction('afterClose', {
                    afterClose: interval,
                    createDate: this.get('startDate').toDate(),
                    dueAfter: this.get('dueAfter')
                });
            } else if (this.get('repeatByDate') || this.get('repeatTikkitManage')) {
                // Only send label if "relabel previous..." is checked
                var label = this.get('previousRelabel') ? this.get('previousLabel') : null;
                this.sendAction('byDate', {
                    repeatRule: this.get('rrule').toString(),
                    createDate: this.get('startDate').toDate(),
                    dueAfter: this.get('dueAfter'),
                    previousClose: this.get('previousClose'),
                    previousRelabel: this.get('previousRelabel'),
                    previousLabel: label,
                    allowBalancing: this.get('repeatTikkitManage'),
                    balancingSchedule: this.get('managedRepeatBy')
                });
            }
            // Observes radio buttons and some checkboxes, since not all can fire change actions
        }.observes('setDueAfter', 'previousClose', 'previousRelabel', 'repeatBy', 'managedRepeatBy'),

        weekdays: function () {
            var _this3 = this;

            if (!this.get('isWeekly')) {
                return [];
            }

            return ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'].map(function (day) {
                var selected = _this3.get('startDayLabel') === day;
                return Ember.Object.create({
                    name: day,
                    selected: selected
                });
            });
        }.property('isWeekly'),

        selectedWeekdays: function () {
            return this.get('weekdays').filter(function (day) {
                return day.get('selected');
            });
        }.property('weekdays.@each.selected'),

        defaultLabel: function () {
            if (this.get('schedule.previousRelabel')) {
                return this.get('schedule.previousLabel');
            }
            return this.get('requestLabels').find(function (l) {
                return l.get('isDefaultClosed');
            });
        }.property('schedule.previous_label'),

        previewDateOptions: [3, 6, 9],

        previewDates: function () {
            var rrule = this.get('rrule');
            if (!rrule) {
                return;
            }

            // Use a different, temporary RRule object to preview dates,
            // since it relies on have an explicit `dtstart` property that
            // cannot be stored on the real RRule object (since it will embed
            // DTSTART in a way not supported by Python dateutil / ICal rrule)
            //
            // Create it based on the real RRule's original options, but a new
            // options object has to be created, since `new RRule` will mutate
            // the object that is passed in

            var originalOptions = rrule.origOptions,
                opts = {};
            for (var opt in originalOptions) {
                opts[opt] = originalOptions[opt];
            }

            if (this.get('previewStartDate')) {
                opts.dtstart = this.get('previewStartDate').toDate();
            } else {
                opts.dtstart = this.get('startDate').toDate();
            }

            var temp = new RRule(opts);

            // Only return a small number of dates
            var dateOuterLimit = moment().add(5, 'year').toDate();
            var dates = temp.between(new Date(), dateOuterLimit);
            dates = dates.slice(0, this.get('previewDateCount')).map(function (date) {
                return moment(date).format('ddd, MMM D, YYYY');
            });
            return dates;
        }.property('rrule', 'previewDateCount', 'startDate', 'previewStartDate'),

        calculateFromEnd: function () {
            var day = this.get('startDate').date();
            return day > this.get('daysInSelectedMonth') - 3;
        }.property('startDate'),

        daysInSelectedMonth: function () {
            return (0, _dateUtils.calculateDaysInMonth)(this.get('startDate'));
        }.property('startDate'),

        dayOfMonth: function () {
            if (!this.get('startDate')) {
                return;
            }

            var day = this.get('startDate').date();
            if (this.get('calculateFromEnd')) {
                // Calculate position from end of month, based on number of days in selected month
                // Eg. -1 for 31st, -2 for 30th, etc for months with 31 days
                return day - this.get('daysInSelectedMonth') - 1;
            } else {
                return day;
            }
        }.property('startDate'),

        dayOfMonthLabel: function () {
            if (this.get('dayOfMonthFromEnd')) {
                return this.get('dayOfMonthFromEnd') + ' day';
            } else {
                return '' + (0, _dateUtils.toOrdinal)(this.get('dayOfMonth'));
            }
        }.property('dayOfMonth'),

        dayOfMonthFromEnd: function () {
            var day = this.get('dayOfMonth');
            if (!day || day > -1) {
                return;
            }

            var toLast = '';
            if (day === -2) {
                toLast = 'second to ';
            } else if (day === -3) {
                toLast = 'third to ';
            }
            return toLast + 'last';
        }.property('dayOfMonth'),

        weekdayOfMonth: function () {
            if (!this.get('startDate')) {
                return;
            }
            return this.get('startDate').format('dddd');
        }.property('startDate'),

        weekdayOfMonthLabel: function () {
            var day = this.get('weekdayOfMonth');
            if (this.get('isLastWeekdayOfMonth')) {
                return 'last ' + day;
            } else {
                return (0, _dateUtils.toOrdinal)(this.get('bySetPos')) + ' ' + day;
            }
        }.property('weekdayOfMonth', 'isLastWeekdayOfMonth'),

        isLastWeekdayOfMonth: function () {
            return this.get('bySetPos') === -1;
        }.property('bySetPos'),

        bySetPos: function () {
            if (!this.get('startDate')) {
                return;
            }
            var nthOccurrence = parseInt((this.get('startDate').date() - 1) / 7 + 1);
            return nthOccurrence === 5 ? -1 : nthOccurrence; // 3rd monday of month, etc
        }.property('startDate'),

        byWeekday: function () {
            if (!this.get('startDate')) {
                return;
            }
            return this.get('startDate').format('dd').toUpperCase(); // TH, SU, etc
        }.property('isMonthly', 'startDate'),

        monthlyRepeatLabel: function () {
            if (this.get('isMonthlyByDate')) {
                return this.get('dayOfMonthLabel');
            } else if (this.get('isMonthlyByWeekday')) {
                return this.get('weekdayOfMonthLabel');
            }
        }.property('monthlyRepeat', 'dayOfMonth', 'bySetPos'),

        rrule: function () {
            if (!this.get('frequency')) {
                return;
            } // Only freq is required for RRule

            var freq = RRule[this.get('frequency').toUpperCase()]; // Get RRule.MONTHLY, etc

            var options = {
                wkst: RRule.SU, // Start the week on Sunday
                interval: this.get('interval'),
                freq: freq
            };

            if (this.get('isMonthlyByDate')) {
                options.bymonthday = this.get('dayOfMonth');
            } else if (this.get('isMonthlyByWeekday')) {
                options.bysetpos = this.get('bySetPos');
                options.byweekday = RRule[this.get('byWeekday')]; // Get RRule.FR, etc
            } else if (this.get('isWeekly')) {
                var days = this.get('selectedWeekdays');
                if (days.length) {
                    // Convert selected weekdays to RRule objects
                    days = days.map(function (d) {
                        var abbrev = d.get('name').slice(0, 2).toUpperCase();
                        return RRule[abbrev];
                    });
                    options.byweekday = days;
                } else {
                    // They deselected all days, default to repeating from start date
                    var day = this.get('startDate').format('dd').toUpperCase();
                    options.byweekday = RRule[day];
                }
            }
            return new RRule(options);
        }.property('startDate', 'frequency', 'interval', 'monthlyRepeat', 'dayOfMonth', 'bySetPos', 'byWeekday', 'selectedWeekdays'),

        actions: {
            setProperty: function setProperty(name, value) {
                this.set(name, value);
                this.sendUpdate();
            },

            setLabel: function setLabel(label) {
                this.set('previousLabel', label);
                this.sendUpdate();
            },

            setUnit: function setUnit(prop, unit) {
                // Always store as pluralized for relativedelta library
                if (unit.slice(-1) !== 's') {
                    unit = unit + 's';
                }
                this.set(prop, unit);
                this.sendUpdate();
            },

            setStartDate: function setStartDate(date) {
                this.set('startDate', date);
                this.sendUpdate();
            },

            setPreviewStartDate: function setPreviewStartDate(date) {
                this.set('previewStartDate', date);
            },

            toggleDay: function toggleDay(day) {
                var weekday = this.get('weekdays').find(function (weekday) {
                    return weekday.get('name') === day;
                });
                weekday.set('selected', !weekday.get('selected'));
                this.sendUpdate();
            }
        }
    });
});