define('huyang-common/mixins/header-navigation-controller', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Mixin.create({
        hasContext: Ember.computed.and('requestIds', 'previousRoute'),

        requestIdsArray: Ember.computed('requestIds', function ria() {
            return JSON.parse(this.get('requestIds'));
        }),

        currentIndex: function () {
            var modelIdString = this.get('modelIdString');
            return this.get('requestIdsArray').indexOf(this.get(modelIdString));
        }.property('model.pmProgram.id', 'requestIdsArray'),

        nextIndex: function () {
            return this.get('currentIndex') + 1;
        }.property('currentIndex'),

        previousIndex: function () {
            return this.get('currentIndex') - 1;
        }.property('currentIndex'),

        loadNextId: function loadNextId() {
            var _this = this;

            if (!this.get('atEndOfPage')) {
                return Ember.RSVP.resolve(this.get('requestIdsArray')[this.get('nextIndex')]);
            }
            if (this.get('currentPage') >= this.get('totalPages')) {
                return Ember.RSVP.resolve(null); // no more pages
            }
            // fetch next page and set nextId
            var params = {
                filter: this.get('filter'),
                per_page: this.get('perPage'),
                page: this.get('currentPage') + 1,
                sortColumn: this.get('sortColumn'),
                sortDirection: this.get('sortDirection'),
                buildingId: this.get('buildingId')
            };

            return this.store.query(this.get('modelString'), params).then(function (requests) {
                // add new page of ids to requestIdsArray
                requests.forEach(function (req) {
                    _this.get('requestIdsArray').push(req.get('id'));
                });
                // update current page
                _this.set('currentPage', _this.get('currentPage') + 1);
                return Ember.RSVP.resolve(requests.toArray()[0].get('id'));
            });
        },

        previousId: function () {
            return this.get('requestIdsArray')[this.get('previousIndex')];
        }.property('previousIndex', 'requestIdsArray'),

        hasPrevious: function () {
            return this.get('hasContext') && this.get('currentIndex') > 0;
        }.property('currentIndex', 'hasContext'),

        atEndOfPage: function () {
            return this.get('currentIndex') === this.get('requestIdsArray.length') - 1;
        }.property('currentIndex', 'requestIdsArray.length'),

        hasNext: function () {
            if (!this.get('hasContext')) {
                return false;
            }
            if (this.get('currentIndex') < this.get('requestIdsArray.length') - 1) {
                return true;
            }
            if (this.get('currentPage') < this.get('totalPages')) {
                return true;
            }
        }.property('currentIndex', 'requestIdsArray.length', 'hasContext', 'totalPages', 'currentPage'),

        context: function () {
            return {
                fromId: this.get('request.id'),
                previousRoute: this.get('previousRoute'),
                previousModelId: this.get('previousModelId'),
                requestIds: this.get('requestIdsArray'),
                currentPage: this.get('currentPage'),
                totalPages: this.get('totalPages'),
                filter: this.get('filter'),
                perPage: this.get('perPage'),
                sortColumn: this.get('sortColumn'),
                sortDirection: this.get('sortDirection'),
                buildingId: this.get('buildingId')
            };
        }.property('fromId', 'previousRoute', 'previousModelId', 'requestIdsArray', 'currentPage', 'totalPages', 'filter', 'perPage', 'sortColumn', 'sortDirection', 'buildingId'),

        actions: {
            goToPreviousRoute: function goToPreviousRoute() {
                var _this2 = this;

                var paramNames = this.get('paramNames');
                var params = {};
                if (paramNames) {
                    paramNames.split(',').forEach(function (param) {
                        var val = _this2.get(param);

                        if (param === 'sortColumns') val = JSON.parse(val);
                        params[param] = val;
                    });
                }
                if (this.get('previousModelId')) {
                    this.transitionToRoute(this.get('previousRoute'), this.get('previousModelId'), { queryParams: params });
                } else {
                    if (this.get('context')) {
                        var ctx = this.get('context');
                        Object.keys(ctx).forEach(function (key) {
                            params[key] = ctx[key];
                        });
                    }
                    this.transitionToRoute(this.get('previousRoute'), { queryParams: params });
                }
            },
            goToNextRequest: function goToNextRequest() {
                var _this3 = this;

                if (this.get('hasNext')) {
                    this.loadNextId().then(function (nextId) {
                        _this3.transitionToRoute(_this3.get('routeString'), nextId, { queryParams: _this3.get('context') });
                    });
                }
            },
            goToPreviousRequest: function goToPreviousRequest() {
                if (this.get('hasPrevious')) {
                    this.transitionToRoute(this.get('routeString'), this.get('previousId'), { queryParams: this.get('context') });
                }
            }
        }
    });
});