define('huyang-common/models/channel', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        name: _emberData.default.attr('string'),
        account: _emberData.default.belongsTo('account', { async: false }),
        comments: _emberData.default.hasMany('request-comment', { async: false }),

        displayName: function () {
            var name = this.get('name');

            if (name === 'staff') {
                return this.get('account.name');
            } else if (name === 'occupant') {
                return 'Occupants';
            }

            return name;
        }.property('name'),

        isStaff: function () {
            return this.get('name') === 'staff';
        }.property('name'),

        isOccupant: function () {
            return this.get('name') === 'occupant';
        }.property('name'),

        unreadKey: function () {
            return this.get('name');
        }.property('name')
    });
});