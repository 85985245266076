define('huyang-common/mixins/unsaved-changes', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Mixin.create({
        propertyState: {},

        trackUnsaved: function trackUnsaved(names) {
            var self = this;
            var propertyState = {};
            names.forEach(function (name) {
                propertyState[name] = { saved: true, initial: self.get(name) };
                self.addObserver(name, self, self.updated);
            });
            this.set('propertyState', propertyState);
            this.set('saved', true);
        },

        updated: function updated(sender, key) {
            var state = this.get('propertyState.' + key);
            state.saved = state.initial === this.get(key);
            var changed = _.values(this.get('propertyState')).find(function (s) {
                return !s.saved;
            });
            this.set('saved', _.isUndefined(changed));
        }
    });
});