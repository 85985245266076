define('huyang-common/adapters/request', ['exports', 'ember-data', 'huyang-common/adapters/application'], function (exports, _emberData, _application) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _application.default.extend({
        urlForUpdateRecord: function urlForUpdateRecord(id, modelName, snapshot) {
            // use comment endpoint to combine update and comment into a single request
            var url = this._super(id, modelName, snapshot);
            if (snapshot.record.commentBody) {
                url += '/comment';
            }
            // can't do both!
            if (snapshot.record.attachment && snapshot.record.attachment.length) {
                url += '/attach';
            }
            return url;
        },

        urlForCreateRecord: function urlForCreateRecord(modelName, snapshot) {
            // use attach endpoint to combine create and attach into a single request
            var url = this._super(modelName, snapshot);
            if (snapshot.record.attachment && snapshot.record.attachment.length) {
                url += '/attach';
            }
            return url;
        },

        handleResponse: function handleResponse(status, headers, payload) {
            if (status === 400 && payload.message) {
                return new _emberData.default.InvalidError([{ detail: payload.message }]);
            }

            return this._super.apply(this, arguments);
        }
    });
});