define('huyang-common/components/request-attachments', ['exports', 'huyang-common/templates/components/request-attachments'], function (exports, _requestAttachments) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        layout: _requestAttachments.default,

        actions: {
            imageClicked: function imageClicked(img) {
                this.sendAction('imageClicked', img);
            }
        },

        images: function () {
            if (this.get('request.attachments')) {
                return this.get('request.attachments').filter(function (att) {
                    return att.get('isImage');
                });
            }

            return [];
        }.property('request.attachments', 'request.attachments.[]'),

        nonImageAttachments: function () {
            if (this.get('request.attachments')) {
                return this.get('request.attachments').filter(function (att) {
                    return !att.get('isImage');
                });
            }

            return [];
        }.property('request.attachments', 'request.attachments.[]')
    });
});