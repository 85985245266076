define('huyang-common/components/vendor-trade-select', ['exports', 'huyang-common/components/dynamic-component', 'huyang-common/templates/components/vendor-trade-select'], function (exports, _dynamicComponent, _vendorTradeSelect) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _dynamicComponent.default.extend({
        layout: _vendorTradeSelect.default,
        classNames: ['vendor-trade-select'],
        selectId: 'tradeSelect',

        store: Ember.inject.service(),

        propertiesToSend: ['trade'],

        chosenOptions: {
            width: '100%'
        },

        watchForChanges: function () {
            // this.notifyOfChanges is part of DynamicComponent
            Ember.run.once(this, 'notifyOfChanges');
        }.observes('trade').on('init'),

        tradesSorted: function () {
            return this.get('trades').sortBy('label');
        }.property('trades.[]', 'trades.@each.id'),

        actions: {
            saveTrade: function saveTrade() {
                var _this = this;

                var store = this.get('store');
                var accountId = localStorage.getItem('accountId');

                store.findRecord('account', accountId).then(function (account) {
                    var trade = store.createRecord('trade', {
                        label: _this.get('newTradeName'),
                        account: account
                    });

                    trade.save().then(function () {
                        _this.set('showTradeForm', false);
                        _this.set('newTradeName', '');

                        _this.set('trade', trade.get('id'));

                        // this is needed because the thing won't update!!!
                        _this.$('#' + _this.get('selectId')).trigger('chosen:updated');
                        _this.sendAction('selected', { trade: trade.get('id') });
                    });
                });
            },
            showTradeForm: function showTradeForm() {
                var _this2 = this;

                this.set('showTradeForm', true);
                setTimeout(function () {
                    return _this2.$('input').focus();
                }, 100);
            },
            hideTradeForm: function hideTradeForm() {
                this.set('showTradeForm', false);
            },
            selectTrade: function selectTrade(evt, params) {
                if (evt && params) {
                    this.set('trade', params.selected);
                    this.sendAction('selected', { trade: params.selected });
                }
            }
        }
    });
});