define('huyang-common/services/session', ['exports', 'ember-simple-auth/services/session'], function (exports, _session) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _session.default.extend({
        store: Ember.inject.service(),

        currentAccount: function () {
            // TODO: see if values are set in secure; they don't seem to be
            var accountId = localStorage.getItem('accountId');
            if (accountId && this.get('isAuthenticated')) {
                return this.get('store').find('account', parseInt(accountId));
            }
        }.property('data.authenticated.accountId', 'isAuthenticated'),

        currentUser: function () {
            var userId = localStorage.getItem('userId');
            if (userId && this.get('isAuthenticated')) {
                return this.get('store').find('user', parseInt(userId));
            }
        }.property('data.authenticated.userId', 'isAuthenticated'),

        currentBuilding: function () {
            var buildingId = localStorage.getItem('buildingId');
            if (buildingId && this.get('isAuthenticated')) {
                // synchronous; should be in the store already
                return this.get('store').getById('building', parseInt(buildingId));
            }
        }.property('data.authenticated.buildingId', 'isAuthenticated')
    });
});