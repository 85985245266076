define('huyang-common/validations/cost-log', ['exports', 'ember-changeset-validations/validators/index'], function (exports, _index) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = {
        account: (0, _index.validatePresence)(true),
        request: (0, _index.validatePresence)(true),
        category: (0, _index.validatePresence)(true),
        description: (0, _index.validatePresence)(true),
        value: (0, _index.validateNumber)({ allowBlank: false, gte: 0 })
    };
});