define('huyang-common/mixins/modal-helper', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Mixin.create({
        openModal: function openModal(modalId) {
            // one modal open at a time
            this.closeModal();
            this.set('activeModalId', modalId);
            Ember.$('#' + modalId).modal();
        },

        closeModal: function closeModal() {
            if (this.get('activeModalId')) {
                Ember.$('#' + this.get('activeModalId')).modal('hide');
            }

            this.set('activeModalId', null);
        },

        setIsOpened: function setIsOpened(modalId) {
            this.set('activeModalId', modalId);
        }
    });
});