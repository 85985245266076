define('huyang-common/mixins/formats-phone', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Mixin.create({
        intlTelInput: Ember.inject.service(),

        // The UI input and db want this (+18885551212). It's mainly kept around to
        // format the older values in the db that are formatted like 888-555-1212.
        formatPhone: function formatPhone(phone) {
            if (!phone) {
                return '';
            }

            var hasCountryCode = phone.charAt(0) === '+' ? true : false;
            if (hasCountryCode) return phone;

            // remove everything but numbers
            var number = phone.replace(/[^\d]+/g, '');
            if (number.length < 10) {
                // shouldn't happen
                return number;
            }

            // If number has no country code, assume US
            return '+1' + phone;
        },

        // For display in tables, etc.: +1 (888) 555-1212
        formatPhoneDisplay: function formatPhoneDisplay(phone) {
            return this.get('intlTelInput').formatRawNumber(this.get('formatPhone')(phone));
        }
    });
});