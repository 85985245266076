define('huyang-common/components/mobile-request-list', ['exports', 'huyang-common/templates/components/mobile-request-list', 'huyang-common/mixins/sorts-data'], function (exports, _mobileRequestList, _sortsData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_sortsData.default, {
        layout: _mobileRequestList.default,
        showCustomize: true,

        showLocation: function () {
            return this.get('showSubspaceOnly') || this.get('hasMultipleLocations');
        }.property('showSubspaceOnly', 'hasMultipleLocations'),

        // data is sorted on the server side
        sortedData: function () {
            return this.get('data');
        }.property('data'),

        actions: {
            rowClicked: function rowClicked(id) {
                if (this.get('requestRouteName')) {
                    this.transitionToRoute(this.get('requestRouteName'), id);
                }

                this.sendAction('rowClicked', id);
            },
            infinityLoad: function infinityLoad() {
                this.sendAction('infinityLoad');
            },
            sortBy: function sortBy(key) {
                this._super(key);
                this.sendAction('sortBy', this.get('userSortColumn'), this.get('userSortDirection'));
            },
            customize: function customize() {
                this.sendAction('customize');
            }
        }
    });
});