define('huyang-common/components/expandable-list', ['exports', 'huyang-common/templates/components/expandable-list'], function (exports, _expandableList) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        layout: _expandableList.default,
        defaultShow: 5,

        showItems: function () {
            var all = this.get('items');
            if (!all) {
                return [];
            }
            return this.get('showAll') ? all : all.slice(0, this.get('defaultShow'));
        }.property('defaultShow', 'showAll', 'items'),

        showingAll: function () {
            return this.get('items.length') === this.get('showItems.length');
        }.property('items.length', 'showItems.length'),

        hasMore: function () {
            return this.get('items.length') > this.get('defaultShow');
        }.property('defaultShow', 'items.length'),

        actions: {
            showAll: function showAll() {
                this.set('showAll', true);
            },
            showSome: function showSome() {
                this.set('showAll', false);
            }
        }
    });
});