define('huyang-common/components/vendor-autocomplete-input', ['exports', 'huyang-common/templates/components/vendor-autocomplete-input', 'huyang-common/mixins/validates-email'], function (exports, _vendorAutocompleteInput, _validatesEmail) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_validatesEmail.default, {
        layout: _vendorAutocompleteInput.default,
        classNames: ['vendor-autocomplete-input'],
        query: '',
        suggestedVendorLimit: 4,
        showMenu: false,
        searchResults: [],

        vendorSort: ['serviceRequests.length:desc', 'displayName'],
        suggestedVendorsSorted: Ember.computed.sort('unusedVendors', 'vendorSort'),

        unusedVendors: function () {
            var request = this.get('request.id');

            return this.get('vendors').filter(function (v) {
                return !v.get('serviceRequests').findBy('request.id', request);
            });
        }.property('vendors.[]', 'request.id'),

        suggestedVendors: function () {
            var limit = this.get('suggestedVendorLimit');

            var vendors = this.get('suggestedVendorsSorted').filter(function (v) {
                return v.get('serviceRequests.length') > 0;
            });

            return vendors.slice(0, limit);
        }.property('suggestedVendorsSorted.[]', 'suggestedVendorLimit'),

        otherVendors: function () {
            var vendors = this.get('unusedVendors');
            var suggested = this.get('suggestedVendors');

            // return vendors not suggested, sorted A-Z
            return vendors.filter(function (v) {
                var isSuggested = suggested.findBy('id', v.get('id'));

                return !isSuggested;
            }).sortBy('displayName');
        }.property('unusedVendors.[]', 'suggestedVendors.[]'),

        // is the query a valid email address?
        queryIsEmail: function () {
            return this.isEmail(this.get('query'));
        }.property('query'),

        placeholder: function () {
            if (!this.get('recipient')) {
                if (this.get('vendors.length') > 0) {
                    return 'Vendor name or email address...';
                } else {
                    return 'Vendor email address...';
                }
            }

            return '';
        }.property('recipient'),

        showRecipient: function () {
            return this.get('recipient') && !this.get('query') && !this.get('hideRecipient');
        }.property('recipient', 'query', 'hideRecipient'),

        performSearch: function () {
            // debouncing search to protect responsiveness of input with many vendors
            Ember.run.debounce(this, 'search', 100);
        }.observes('query'),

        search: function search() {
            var _this = this;

            var query = this.get('query');
            if (query) {
                query = query.toLowerCase();
                var vendors = this.get('unusedVendors');
                var results = [];

                vendors.forEach(function (vendor) {
                    var vendorName = vendor.get('name');
                    var vendorEmail = vendor.get('primaryUser.email').toLowerCase();

                    if (_this.nameOrEmailMatches(vendorName, vendorEmail, query)) {
                        results.push({
                            label: _this.highlightKeyword(vendor.get('contactString'), query),
                            user: vendor.get('primaryUser')
                        });
                    }

                    // check vendor technicians for matches
                    vendor.get('technicians').forEach(function (tech) {
                        var u = tech.get('user');

                        if (_this.nameOrEmailMatches(u.get('name'), u.get('email'), query)) {
                            results.push({
                                label: _this.highlightKeyword(u.get('contactString'), query),
                                user: u
                            });
                        }
                    });
                });

                this.set('searchResults', results);
            }
        },

        nameOrEmailMatches: function nameOrEmailMatches(name, email, query) {
            email = email.toLowerCase();
            query = query.toLowerCase();

            if (name) {
                name = name.toLowerCase();

                if (name.indexOf(query) > -1) {
                    return true;
                }
            }

            if (email.indexOf(query) > -1) {
                return true;
            }

            return false;
        },

        highlightKeyword: function highlightKeyword(str, keyword) {
            var regex = new RegExp('(' + keyword + ')', 'i');
            return str.replace(regex, '<span class="result-highlight">$1</span>');
        },

        selectPrevious: function selectPrevious() {
            this.selectUser(-1);
        },

        selectNext: function selectNext() {
            this.selectUser(1);
        },

        selectUser: function selectUser(delta) {
            var users = this.getVisibleUsers();

            if (users.length === 0 && this.get('queryIsEmail')) {
                this.set('selectAddNewUser', true);
            } else if (users.length > 0) {
                var selectedIndex = -1;

                users.forEach(function (user, i) {
                    if (user.get('selected')) {
                        selectedIndex = i;
                    }
                });

                selectedIndex = selectedIndex + delta;

                if (selectedIndex < 0) {
                    // if you go past the beginning, go to the end
                    selectedIndex = users.length - 1;
                } else if (selectedIndex > users.length - 1) {
                    // go back to beginning
                    selectedIndex = 0;
                }

                if (users[selectedIndex]) {
                    this.resetSelectedUsers();
                    users[selectedIndex].set('selected', true);
                }
            }
        },

        getVisibleUsers: function getVisibleUsers() {
            // see what is visible
            var users = [];

            if (this.get('query')) {
                // return search results
                if (this.get('searchResults.length') > 0) {
                    users = this.get('searchResults').map(function (r) {
                        return r.user;
                    });
                }
            } else {
                // return suggested vendors
                users = this.get('suggestedVendors').map(function (v) {
                    return v.get('primaryUser');
                });

                var otherUsers = this.get('otherVendors').map(function (v) {
                    return v.get('primaryUser');
                });
                users.pushObjects(otherUsers);
            }

            return users;
        },

        resetSelectedUsers: function () {
            this.set('selectAddNewUser', false);

            this.get('vendors').forEach(function (v) {
                v.set('primaryUser.selected', false);
                v.get('technicians').forEach(function (t) {
                    return t.set('user.selected', false);
                });
            });
        }.observes('query'),

        setRecipient: function setRecipient(user) {
            if (typeof user === 'undefined') {
                user = this.getVisibleUsers().findBy('selected');
            }

            if (typeof user !== 'undefined') {
                if (typeof user === 'string') {
                    this.set('recipient', { email: user });
                } else {
                    this.set('recipient', user);
                }

                this.set('query', '');
                this.resetSelectedUsers();
            }
        },

        recipientChanged: function () {
            this.sendAction('action', this.get('recipient'));
        }.observes('recipient'),

        setup: function () {
            var _this2 = this;

            var UP_KEY = 38;
            var DOWN_KEY = 40;
            var ENTER_KEY = 13;
            var BACKSPACE_KEY = 8;

            this.$('.autocomplete-input').on('keydown', function (e) {
                var key = e.keyCode;

                if (key === UP_KEY || key === DOWN_KEY || key === ENTER_KEY) {
                    e.preventDefault();
                }

                if (key === UP_KEY) {
                    _this2.selectPrevious();
                } else if (key === DOWN_KEY) {
                    _this2.selectNext();
                } else if (key === ENTER_KEY) {
                    _this2.setRecipient();
                    _this2.set('showMenu', false);
                } else if (key === BACKSPACE_KEY && !_this2.get('query')) {
                    _this2.send('removeRecipient');
                }
            });

            // focus the input shortly after this is rendered
            Ember.run.later(function () {
                return _this2.$('.autocomplete-input').focus();
            }, 600);
        }.on('didInsertElement'),

        cleanup: function () {
            this.$('.autocomplete-input').off('keydown');
        }.on('willDestroyElement'),

        actions: {
            setRecipient: function setRecipient(user) {
                this.setRecipient(user);
                this.set('showMenu', false);
            },
            removeRecipient: function removeRecipient() {
                this.setRecipient(null);
                this.sendAction('createUserWithEmail', false);
                this.$('.autocomplete-input').focus();
            },
            didFocus: function didFocus() {
                if (this.get('unusedVendors.length') > 0) {
                    this.set('showMenu', true);
                }
            },
            didBlur: function didBlur() {
                var existingVendor = this.get('vendors').findBy('primaryUser.email', this.get('query'));

                if (this.get('queryIsEmail') && !this.get('recipient') && !existingVendor) {
                    this.send('addNewUser');
                } else if (existingVendor) {
                    this.setRecipient(existingVendor.get('primaryUser'));
                }

                Ember.run.later(this, 'set', 'showMenu', false, 200);
            },
            addNewUser: function addNewUser() {
                this.sendAction('createUserWithEmail', this.get('query'));
                this.setRecipient(this.get('query'));
            }
        }
    });
});