define('huyang-common/components/user-avatar', ['exports', 'huyang-common/templates/components/user-avatar'], function (exports, _userAvatar) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        classNames: ['user-avatar'],
        classNameBindings: ['size'],
        layout: _userAvatar.default,
        link: true,

        profileUrl: function () {
            return '/profiles/' + this.get('user.id');
        }.property('user.id'),

        bgImageStyle: function () {
            var url = this.get('user.photoUrl') + '?fit=crop&crop=faces&h=512&w=512';

            return new Ember.String.htmlSafe('background-image: url(' + url + ')');
        }.property('user.photoUrl')
    });
});